import { ChatPanel } from '@/entities/chat/ui/ChatPanel'
import { DocumentPanel } from '@/entities/panel/ui/DocumentPanel'
import { cn } from '@/shared/utils/common'
import { AnimatePresence, motion } from 'framer-motion'
import {
  Panel,
  PanelGroup,
  PanelResizeHandle,
} from 'react-resizable-panels'

import { useDocumentPrevew } from '../../hooks/useDocumentPreview'

export const DocumentPreviewContainer = () => {
  const {
    documents,
    isChatOpen,
    isAnyPanelOpen,
    isOpenDocumentsPanel,
    isVisibleSecondDocument,
    isVisibleThreePanel,
    panelRef,
    isDragging,
    toggleDragging,
    toggleResizePanel,
  } = useDocumentPrevew()

  if (!documents.length) {
    return null
  }

  return (
    <>
      <AnimatePresence>
        {isOpenDocumentsPanel && (
          <motion.div
            key="document-preview"
            style={{
              position: 'fixed',
              bottom: 0,
              left: 0,
              width: '100%',
              overflow: 'hidden',
              transformOrigin: 'bottom',
              zIndex: 40,
            }}
            // -- Enter animation --
            initial={{ height: 0 }}
            animate={{ height: '100%' }}
            // -- Exit animation --
            exit={{ height: 0 }}
            transition={{ duration: 0.3, ease: 'easeInOut' }}
          >
            <PanelGroup
              ref={panelRef}
              className={cn(
                `mx-auto flex min-h-full w-full max-w-[1440px] items-stretch
                justify-center gap-3 bg-transparent`,
                'pl-[100px] pr-[55px] pt-[72px]'
              )}
              direction="horizontal"
            >
              <Panel
                className={cn(
                  'rounded-t-[12px] bg-white shadow-shadow2'
                )}
                defaultSize={50}
                minSize={35}
                onResize={toggleResizePanel}
              >
                <DocumentPanel
                  isDragging={isDragging}
                  index={0}
                  documentData={documents[0]}
                  isVisibleThreePanel={isVisibleThreePanel}
                />
              </Panel>

              {isAnyPanelOpen && (
                <PanelResizeHandle
                  className={cn(
                    `h-[19px] w-[3px] self-center rounded-sm bg-additional-blue-4
                    opacity-[0.38]`
                  )}
                  onDragging={(isDrag) => toggleDragging(isDrag)}
                />
              )}

              <Panel
                className={cn(
                  'max-w-[auto]',
                  'rounded-t-[12px] shadow-shadow2 transition-[all] ease-in-out',
                  isAnyPanelOpen
                    ? 'max-w-[100svw] opacity-100'
                    : 'max-w-[0px] opacity-0',
                  !isDragging &&
                    (isAnyPanelOpen
                      ? 'duration-700'
                      : 'duration-[800ms]'),
                  !isDragging &&
                    (documents.length === 2
                      ? 'duration-[400ms]'
                      : 'duration-700'),
                  isVisibleSecondDocument
                    ? 'opacity-100'
                    : 'opacity-0',
                  !isAnyPanelOpen && 'opacity-0'
                )}
                onResize={toggleResizePanel}
                defaultSize={50}
                minSize={35}
              >
                {documents.length === 2 ? (
                  <DocumentPanel
                    isDragging={isDragging}
                    index={1}
                    documentData={documents[1]}
                    isVisibleThreePanel={isVisibleThreePanel}
                  />
                ) : (
                  <ChatPanel
                    isVisibleSecondPanel
                    isDragging={isDragging}
                  />
                )}
              </Panel>
            </PanelGroup>
          </motion.div>
        )}
      </AnimatePresence>

      <PanelGroup
        direction="horizontal"
        className={cn(
          'fixed bottom-0 right-0 z-[60] bg-transparent',
          `flex max-h-[calc(100svh-90px)] w-screen transform items-stretch
          justify-center gap-2 px-9 pt-0`,
          'origin-right transition-[all] duration-300 ease-in-out',
          isChatOpen && documents.length === 2
            ? 'scale-x-100 opacity-100'
            : 'scale-x-0 opacity-0'
        )}
        autoSaveId="example"
      >
        <Panel defaultSize={55} />
        <PanelResizeHandle
          className="color-[additional-blue-4] z-[100] h-[19px] w-[3px] translate-x-[24px]
            self-center rounded-sm bg-additional-blue-4 opacity-[0.38]"
        />
        <Panel
          className={cn(
            `relative flex w-[40svw] shrink-0 flex-col items-center
            rounded-t-[12px] bg-white px-2 pb-2 shadow-shadow2`
          )}
          defaultSize={45}
          minSize={30}
        >
          <ChatPanel isDragging={isDragging} />
        </Panel>
      </PanelGroup>
    </>
  )
}
