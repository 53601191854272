import {
  DialogDocumentVersionSelect,
  Version,
} from '@/entities/document'
import IconRowDark from '@/shared/assets/icons/icon_row_dark.svg?react'
import { IconButton, Typography } from '@/shared/ui'
import { cn } from '@/shared/utils/common'
import { FC } from 'react'

import { useDocumentOpenVersionsSelectAction } from '../../hooks/useDocumentOpenVersionsSelectAction'

export interface VersionsProps {
  versions?: Version[]
  isMobile?: boolean
}

export const ActionDocumentOpenVersionsSelect: FC<VersionsProps> = ({
  versions,
  isMobile,
}) => {
  const {
    isOpenVersionSelect,
    toggleCloseVersionsSelect,
    toggleOpenVersionsSelect,
    handleRedirect,
  } = useDocumentOpenVersionsSelectAction()

  if (isMobile) {
    return (
      <div
        onClick={() => handleRedirect()}
        className="flex h-16 w-full cursor-pointer items-center gap-3 border-b
          border-b-stroke-light-gray-1"
      >
        <IconRowDark className="h-6 w-6" />
        <Typography variant="label">Сравнение редакций</Typography>
      </div>
    )
  }

  return (
    <>
      <DialogDocumentVersionSelect
        isOpen={isOpenVersionSelect}
        toggleClose={toggleCloseVersionsSelect}
        versions={versions || []}
      />
      <IconButton
        className={cn(
          'p-1 hover:bg-base-100',
          isOpenVersionSelect && 'bg-base-100',
          'disabled:'
        )}
        onClick={toggleOpenVersionsSelect}
        disabled={!versions?.length}
      >
        <IconRowDark width="100%" height="100%" />
      </IconButton>
    </>
  )
}
