import { IFileChunk } from '@/entities/chat'
import { set } from 'date-fns'
import { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'

export const useDocumentChunks = (chunks: IFileChunk[]) => {
  const [isOpen, setIsOpen] = useState(true)
  const [selectedChunk, setSelectedChunk] = useState<
    IFileChunk | undefined
  >(undefined)
  const [searchParams, setSearchParams] = useSearchParams()

  const chunksLength = chunks.length
  const currentChunkIndex = selectedChunk
    ? chunks.indexOf(selectedChunk)
    : -1

  useEffect(() => {
    if (!chunks || chunks.length === 0) return

    const chunkParam = searchParams.get('chunkIndex')
    const chunkIndex = chunkParam ? Number(chunkParam) : -1

    // Only set if selectedChunk is not yet set
    if (!selectedChunk) {
      if (
        !isNaN(chunkIndex) &&
        chunkIndex >= 0 &&
        chunkIndex < chunks.length
      ) {
        setSelectedChunk(chunks[chunkIndex])
      } else {
        setSelectedChunk(chunks[0])
        setSearchParams((prev) => {
          const newParams = new URLSearchParams(prev)
          newParams.set('chunkIndex', '0')
          return newParams
        })
      }
    }
  }, [chunks])

  // Cleanup on unmount — remove chunkIndex param
  useEffect(() => {
    return () => {
      setSearchParams((prev) => {
        const newParams = new URLSearchParams(prev)
        newParams.delete('chunkIndex')
        return newParams
      })
    }
  }, [])

  const updateChunk = (index: number) => {
    setSelectedChunk(chunks[index])
    setSearchParams((prev) => {
      const newParams = new URLSearchParams(prev)
      newParams.set('chunkIndex', index.toString())
      return newParams
    })
  }

  const handleNextChunk = () => {
    if (
      currentChunkIndex >= 0 &&
      currentChunkIndex + 1 < chunks.length
    ) {
      updateChunk(currentChunkIndex + 1)
    }
  }

  const handlePrevChunk = () => {
    if (currentChunkIndex > 0) {
      updateChunk(currentChunkIndex - 1)
    }
  }
  const handleClose = () => {
    setIsOpen(false)
  }

  return {
    isOpen,
    handleClose,
    chunksLength,
    selectedChunk,
    currentChunkIndex: currentChunkIndex + 1,
    handleNextChunk,
    handlePrevChunk,
  }
}
