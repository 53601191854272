// src/AppRoutes.tsx
import { AssistantLayout } from '@/layout/AssistantLayout'
import { CourtBaseLayout } from '@/layout/CourtBaseLayout'
import { DocumentLayout } from '@/layout/DocumentLayout'
import { DocumentsBaseLayout } from '@/layout/DocumentsBaseLayout'
import { PlatformLayout } from '@/layout/PlatformLayout'
import { ChatByIdPage } from '@/pages/ChatByIdPage'
import { ChatFavoritesPage } from '@/pages/ChatFavoritesPage'
import { CourtBaseByIdPage } from '@/pages/CourtBaseByIdPage'
import { CourtBasePage } from '@/pages/CourtBasePage'
import { DocumentByIdPage } from '@/pages/DocumentByIdPage'
import { DocumentByIdVersionsPage } from '@/pages/DocumentByIdVersionsPage'
import { DocumentsBasePage } from '@/pages/DocumentsBasePage'
import { DocumentsFavoritePage } from '@/pages/DocumentsFavoritePage'
import { HomePage } from '@/pages/HomePage'
import { PageNotFound } from '@/pages/PageNotFound'
import { ProfilePage } from '@/pages/ProfilePage'
import { PasswordRecoveryPage } from '@/pages/ResetPasswordPage'
import { SettingsPage } from '@/pages/SettingsPage'
import { SigninPage } from '@/pages/SigninPage'
import { SignupPage } from '@/pages/SignupPage'
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider,
} from 'react-router-dom'

import { authLoader } from './loaders/authLoader'
import { authRedirectLoader } from './loaders/authRedirectLoader'
import { userLoader } from './loaders/userLoader'

export const AppRoutes = () => {
  const router = createBrowserRouter(
    createRoutesFromElements(
      <>
        <Route
          path="/"
          loader={authLoader}
          element={<PlatformLayout />}
        >
          <Route element={<AssistantLayout />}>
            <Route index element={<HomePage />} loader={userLoader} />
            <Route
              path="chats/:chat_id"
              element={<ChatByIdPage />}
              loader={userLoader}
            />
            <Route
              path="chats/favorites"
              element={<ChatFavoritesPage />}
              loader={userLoader}
            />
          </Route>

          <Route path="/documents">
            <Route element={<DocumentsBaseLayout />}>
              <Route index element={<DocumentsBasePage />} />
              <Route
                path="favorite"
                element={<DocumentsFavoritePage />}
              />
            </Route>

            <Route path=":id" element={<DocumentLayout />}>
              <Route index element={<DocumentByIdPage />} />
              <Route
                path="versions"
                element={<DocumentByIdVersionsPage />}
              />
            </Route>
          </Route>

          <Route path="/court-base" element={<CourtBaseLayout />}>
            <Route index element={<CourtBasePage />} />
            <Route path=":id" element={<CourtBaseByIdPage />} />
          </Route>
        </Route>

        <Route path="*" element={<PageNotFound />} />

        {/* Other top-level routes */}

        <Route path="/settings" element={<SettingsPage />} />
        <Route path="/profile" element={<ProfilePage />} />
        <Route
          path="/signin"
          element={<SigninPage />}
          loader={authRedirectLoader}
        />
        <Route
          path="/signup"
          element={<SignupPage />}
          loader={authRedirectLoader}
        />
        <Route
          path="/password-recovery/:token"
          element={<PasswordRecoveryPage />}
        />
      </>
    )
  )

  // Use RouterProvider to provide the router to the app
  return <RouterProvider router={router} />
}
