import { IChatAiFile } from '@/entities/chat'
import { useAppDispatch, useTypedSelector } from '@/shared/store'
import {
  addDocument,
  closeChatPanel,
  toggleFirstDocumentPanel,
  toggleThrirdDocumentsPanel,
} from '@/shared/store/slices/documents/documentPreviewSlice'
import { useSidebar } from '@/widgets/Sidebar'
import { useEffect, useRef } from 'react'

export const useChatAiFile = (file: IChatAiFile) => {
  const dispatch = useAppDispatch()
  const { documents } = useTypedSelector(
    (state) => state.documentPreview
  )
  const { isSidebarOpen, closeSidebar } = useSidebar()
  const timeoutRef = useRef<number | null>(null)

  useEffect(() => {
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current)
      }
    }
  }, [])

  const handleOpenDocument = () => {
    if (isSidebarOpen) {
      closeSidebar()
    }

    if (documents.length === 1) {
      dispatch(toggleFirstDocumentPanel(true))

      timeoutRef.current = window.setTimeout(() => {
        dispatch(
          addDocument({
            id: file.id,
            name: file.name,
            chunks: file.chunks,
            status: file.status,
            locale: file.locale,
            requisites: file.requisites,
          })
        )
      }, 300)
    } else if (documents.length === 2) {
      dispatch(toggleThrirdDocumentsPanel(true))
      dispatch(
        addDocument({
          id: file.id,
          name: file.name,
          chunks: file.chunks,
          status: file.status,
          locale: file.locale,
          requisites: file.requisites,
        })
      )
    } else {
      dispatch(
        addDocument({
          id: file.id,
          name: file.name,
          chunks: file.chunks,
          status: file.status,
          locale: file.locale,
          requisites: file.requisites,
        })
      )
    }

    dispatch(closeChatPanel())
  }

  return {
    handleOpenDocument,
    isSidebarOpen,
    documents,
    closeSidebar,
  }
}
