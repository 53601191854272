import IconContentDark from '@/shared/assets/icons/icon_content_dark.svg?react'
import { IconButton, Typography } from '@/shared/ui'
import { FC } from 'react'

import { useDocumentChapters } from '../../hooks/useDocumentChapters'

export interface ChaptersProps {
  isMobile?: boolean
}

export const ActionDocumentChapters: FC<ChaptersProps> = ({
  isMobile,
}) => {
  const {
    chaptersData,
    chaptersError,
    isLoadingChapters,
    isFetchingChapters,
    isDocumentChaptersOpen,
    toggleDocumentChaptersOpen,
    toggleDocumentChaptersClose,
  } = useDocumentChapters()

  if (isMobile) {
    return (
      <div
        className="flex h-16 w-full cursor-pointer items-center gap-3 border-b
          border-b-stroke-light-gray-1"
        onClick={toggleDocumentChaptersOpen}
      >
        <IconContentDark className="h-6 w-6" />
        <Typography variant="label">Содержание</Typography>
      </div>
    )
  }

  return (
    <>
      <IconButton
        className="p-1 hover:bg-base-100"
        disabled={
          isFetchingChapters ||
          isLoadingChapters ||
          !chaptersData ||
          chaptersData.length === 0 ||
          !!chaptersError
        }
        onClick={
          !isDocumentChaptersOpen
            ? toggleDocumentChaptersOpen
            : toggleDocumentChaptersClose
        }
      >
        <IconContentDark width="100%" height="100%" />
      </IconButton>
    </>
  )
}
