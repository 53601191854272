import { Typography } from '@/shared/ui'

export const CaseCard = ({ item }: { item: any }) => {
  return (
    <div className="flex w-full flex-col items-start justify-start">
      <div className="flex min-h-8 w-full gap-2">
        <Typography variant={'body'} className="font-medium">
          Гражданское дело № {item.id}
        </Typography>
        STATUS
      </div>

      <div className="flex min-h-9 w-full gap-2 border-b border-stroke-light-gray-1 py-2">
        <Typography
          variant={'body'}
          className="w-24 text-sm font-medium text-gray-700"
        >
          Требования:
        </Typography>
        <Typography
          variant={'body'}
          className="w-full text-sm text-gray-700"
        >
          Lorem ipsum dolor sit amet consectetur adipisicing elit.
          Molestiae facilis aliquid natus facere ut placeat, iste
          saepe dolor itaque reprehenderit exercitationem laudantium
          ab vel provident, recusandae illo, quas ratione velit!
        </Typography>
      </div>

      <div className="flex min-h-9 w-full gap-2 border-b border-stroke-light-gray-1 py-2">
        <Typography
          variant={'body'}
          className="w-24 text-sm font-medium text-gray-700"
        >
          Ответчики:
        </Typography>
        <Typography
          variant={'body'}
          className="w-full text-sm text-gray-700"
        >
          Перцев Василий Андреевич
        </Typography>
      </div>

      <div className="flex min-h-9 w-full gap-2 border-b border-stroke-light-gray-1 py-2">
        <Typography
          variant={'body'}
          className="w-24 text-sm font-medium text-gray-700"
        >
          Суд:
        </Typography>
        <Typography
          variant={'body'}
          className="w-full text-sm text-gray-700"
        >
          Алматинский городской суд (Апелляционная инстанция)
        </Typography>
      </div>

      <div className="flex min-h-9 w-full gap-2 border-b border-stroke-light-gray-1 py-2">
        <Typography
          variant={'body'}
          className="w-24 text-sm font-medium text-gray-700"
        >
          Судьи:
        </Typography>
        <Typography
          variant={'body'}
          className="w-full text-sm text-gray-700"
        >
          Баратбеков Т.К.
        </Typography>
      </div>

      <div className="flex min-h-9 w-full gap-2 py-2">
        <Typography
          variant={'body'}
          className="w-24 text-sm font-medium text-gray-700"
        >
          Дата:
        </Typography>
        <Typography
          variant={'body'}
          className="w-full text-sm text-gray-700"
        >
          12.12.2024
        </Typography>
      </div>
    </div>
  )
}
