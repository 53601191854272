import {
  DocumentContentsProvider,
  DocumentVersionsMenuMobile,
} from '@/features/document'
import useMediaQuery from '@/shared/hooks/useMediaQuery'
import { useTypedSelector } from '@/shared/store'
import { WidgetDocumentVersionsContents } from '@/widgets/DocumentByIdPage/WidgetDocumentVersionsContents'
import { WidgetDocumentVersionsContentsMobile } from '@/widgets/DocumentByIdPageMobile/WidgetDocumentVersionsContentsMobile'
import { useEffect, useMemo } from 'react'
import { useSearchParams } from 'react-router-dom'

export const DocumentByIdVersionsPage = () => {
  const [searchParams, setSearchParams] = useSearchParams()
  const { versions, allVersions } = useTypedSelector(
    (state) => state.documents
  )
  const isMobile = useMediaQuery('(max-width: 960px)')

  const ids = useMemo(() => {
    const versionIds = searchParams.get('ids')?.split(',') || []
    return [
      Number(versionIds[0]) ?? versions[0]?.id,
      Number(versionIds[1]) ?? versions[1]?.id,
    ]
  }, [searchParams, versions])

  useEffect(() => {
    if (allVersions?.length) {
      if (!ids[0] && !ids[1]) {
        setSearchParams((params) => {
          params.set(
            'ids',
            String(allVersions[allVersions.length - 1].id)
          )
          params.set(
            'selected_version_id',
            String(allVersions[allVersions.length - 1].id)
          )
          return params
        })
      }
    }
  }, [allVersions, ids])

  if (isMobile) {
    return (
      <DocumentContentsProvider>
        <div className="min-w-80">
          <WidgetDocumentVersionsContentsMobile />
        </div>
      </DocumentContentsProvider>
    )
  }

  return (
    <DocumentContentsProvider>
      <div
        className="relative flex h-[calc(100svh-96px)] w-full items-stretch justify-start
          gap-8 px-[70px] xs:px-0 md:px-0"
      >
        <WidgetDocumentVersionsContents />
      </div>
    </DocumentContentsProvider>
  )
}
