import IconExport from '@/shared/assets/icons/icon_export.svg?react'
import { MenubarItem, Typography } from '@/shared/ui'
import { useToast } from '@/shared/ui/Toast/useToast'
import { use } from 'i18next'

import { useDocumentOpenInNewTab } from '../../hooks/useDocumentOpenInNewTab'

interface MenubarOpenDocumentInNewTabProps {
  fileId: number
}

export const MenubarOpenDocumentInNewTab = ({
  fileId,
}: MenubarOpenDocumentInNewTabProps) => {
  const handleOpenDocumentInNewTab = useDocumentOpenInNewTab()

  return (
    <MenubarItem
      asChild
      onClick={() => handleOpenDocumentInNewTab(fileId)}
      className="gap-2 rounded-lg p-2 hover:cursor-pointer hover:bg-base-100"
    >
      <div
        className="flex items-center justify-start gap-2 rounded-lg p-2
          hover:cursor-pointer hover:bg-base-100"
      >
        <IconExport className="h-5 w-5" />

        <Typography
          className="font-normal text-black"
          variant="label"
        >
          В новом окне
        </Typography>
      </div>
    </MenubarItem>
  )
}
