import { useDocumentChapters } from '@/features/document/hooks/useDocumentChapters'
import { Chapters } from '@/features/document/ui/Chapters'
import IconClose from '@/shared/assets/icons/icon_close.svg?react'
import { Typography } from '@/shared/ui'
import {
  Drawer,
  DrawerClose,
  DrawerContent,
  DrawerDescription,
  DrawerHeader,
  DrawerTitle,
} from '@/shared/ui'

export const ChaptersMobile = () => {
  const { isDocumentChaptersOpen, toggleDocumentChaptersClose } =
    useDocumentChapters()

  return (
    <Drawer
      direction="bottom"
      open={isDocumentChaptersOpen}
      onClose={toggleDocumentChaptersClose}
    >
      <DrawerContent className="z-[70] h-[100svh] overflow-y-hidden">
        <DrawerClose className="absolute right-3 top-3">
          <IconClose className="h-6 w-6" />
        </DrawerClose>
        <DrawerHeader className="justify-start border-b border-b-stroke-light-gray-1 p-0">
          <DrawerTitle className="h-[50px]">
            <Typography variant={'heading4'}>Содержание</Typography>
          </DrawerTitle>
        </DrawerHeader>
        <DrawerDescription className="mt-4">
          <Chapters />
        </DrawerDescription>
      </DrawerContent>
    </Drawer>
  )
}
