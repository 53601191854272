import IconHome from '@/shared/assets/icons/icon_home.svg?react'
import LogoSvg from '@/shared/assets/images/logo.svg?react'
import { Button, Typography } from '@/shared/ui'
import { useNavigate } from 'react-router-dom'

export const PageNotFound = () => {
  const navigate = useNavigate()

  return (
    <div
      className="bg-[url(http://localhost:3000/temp/images/404.png)] bg-[length:auto]
        bg-right-top bg-no-repeat"
    >
      <div
        className="fixed top-0 z-[80] flex h-[60px] w-[100svw] items-center
          justify-between px-8"
      >
        <LogoSvg onClick={() => navigate('/')} />
      </div>
      <div className="flex h-[100vh] flex-col items-center justify-center gap-8">
        {/* <img
          src={`http://localhost:3000/temp/images/404.png`}
          className="h-1/2 rounded-3xl shadow-2xl"
        /> */}
        <div className="flex w-[683px] flex-col items-center gap-8">
          <Typography className="text-center text-[128px] leading-[93.75%]">
            404
          </Typography>
          <Typography className="self-stretch text-center text-[16px] leading-[24px]">
            Вы попали в 404 измерение, где обитают все
            недействительные документы.
            <br />
            Здесь искать нечего...
          </Typography>
        </div>
        <Button onClick={() => navigate('/')} leftIcon={<IconHome />}>
          Вернуться на главную
        </Button>
      </div>
    </div>
  )
}
