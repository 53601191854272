import { documentStatusBadgeVariants } from '@/entities/document/consts'
import {
  Actions,
  DocumentLanguages,
  EDocumentStatus,
} from '@/entities/document/types'
import { DocumentPanelSkeleton } from '@/entities/panel/ui/DocumentPanelSkeleton'
import { RawHtmlMainRenderer } from '@/entities/panel/ui/RawHtmlMainRenderer'
import { useGetDocumentByIdQuery } from '@/shared/api/documents'
import IconChevronLeft from '@/shared/assets/icons/icon_chevron_left_dark.svg?react'
import IconSearch from '@/shared/assets/icons/icon_search_dark.svg?react'
import useMediaQuery from '@/shared/hooks/useMediaQuery'
import { useNavigateBack } from '@/shared/hooks/useNavigateBack'
import {
  Drawer,
  DrawerClose,
  DrawerContent,
  DrawerDescription,
  DrawerHeader,
  DrawerTitle,
  DrawerTrigger,
  IconButton,
  LazySvg,
  Typography,
} from '@/shared/ui'
import { ActionsMenu } from '@/widgets/Document/ActionsMenu'
import { useParams, useSearchParams } from 'react-router-dom'

export const DocumentContentsMobile = () => {
  const { id: documentId } = useParams()
  const [searchParams] = useSearchParams()
  const languageFromUrl = searchParams.get('language')
  const handleGoBack = useNavigateBack('/documents')

  const language: DocumentLanguages =
    (languageFromUrl as DocumentLanguages) || 'ru'
  const isMobile = useMediaQuery('(max-width: 960px)')
  const { data, error, isLoading, isFetching } =
    useGetDocumentByIdQuery({
      id: Number(documentId),
      language,
    })

  if (isLoading || isFetching) {
    return (
      <div
        className="relative mx-auto flex h-[100vh] w-full max-w-[1130px] flex-col
          items-stretch justify-start pb-8"
      >
        <DocumentPanelSkeleton />
      </div>
    )
  }

  if (error) {
    return <Typography>Error...</Typography>
  }

  return (
    <div
      className="relative mx-auto flex h-[calc(100svh-96px)] w-screen flex-col
        items-stretch justify-start gap-6 pt-3 xs:px-0 md:px-0"
    >
      <div className="ml-1 mr-3 flex justify-between">
        <IconButton className="m-2 h-9 w-9" onClick={handleGoBack}>
          <LazySvg
            name="icon_chevron_left_dark"
            width={'56px'}
            height={'56px'}
          />
        </IconButton>

        <div className="flex items-center gap-4">
          <IconButton disabled className="m-2 h-6 w-6">
            <LazySvg
              name="icon_search_dark"
              width={'56px'}
              height={'56px'}
            />
          </IconButton>

          {data && data.document.content && (
            <ActionsMenu
              isMobile
              actions={[
                Actions.Magnify,
                Actions.Info,
                Actions.Chapters,
                Actions.Bookmark,
                Actions.Versions,
                Actions.LanguageMenu,
                Actions.Download,
              ]}
              documentId={Number(documentId)}
              wrapper={(children) => (
                <Drawer direction="bottom">
                  <DrawerTrigger>
                    <LazySvg name="icon_more_dots_dark" />
                  </DrawerTrigger>
                  <DrawerContent className="z-[70] h-[100svh] overflow-y-hidden">
                    <DrawerClose className="absolute right-3 top-3">
                      <LazySvg
                        name="icon_close"
                        className="h-6 w-6"
                      />
                    </DrawerClose>
                    <DrawerHeader className="justify-start p-0">
                      <DrawerTitle>
                        <Typography variant={'heading4'}>
                          Действия
                        </Typography>
                      </DrawerTitle>
                    </DrawerHeader>
                    <DrawerDescription className="mt-8 flex flex-col items-stretch justify-start gap-8">
                      <div className="flex flex-col items-start justify-start gap-3">
                        {children}
                      </div>
                    </DrawerDescription>
                  </DrawerContent>
                </Drawer>
              )}
            />
          )}
        </div>
      </div>
      <div className="flex w-full flex-col items-stretch justify-start gap-8">
        {data && data.document.content ? (
          <div
            className="relative rounded-[16px] border border-base-100 bg-white pb-[88px] pt-0
              shadow-shadow-modal-light"
            id="document-id-title"
          >
            <div className="px-[40px] xs:px-5 xs:pt-8">
              {/* Document Status and requisite*/}
              <div className="title cls-container mb-5">
                <div className="text cls-text mb-2">
                  Статус документа:{' '}
                  {
                    documentStatusBadgeVariants[
                      data?.document?.status.code as EDocumentStatus
                    ].label
                  }
                </div>
                <div className="text cls-text">
                  {data?.document?.requisites ?? null}
                </div>
              </div>
              {/* Document contents */}
              <RawHtmlMainRenderer
                rawHtml={data?.document.content}
                index={0}
              />
            </div>
          </div>
        ) : (
          <DocumentPanelSkeleton />
        )}
      </div>
    </div>
  )
}
