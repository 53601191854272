import { useDocumentContents } from '@/features/document/hooks/useDocumentContent'
import { useTypedSelector } from '@/shared/store'
import { useEffect, useRef, useState } from 'react'
import {
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom'

interface UseRawHtmlMainRendererProps {
  index: number
  isVersions?: boolean
}

export const useRawHtmlMainRenderer = ({
  index,
  isVersions,
}: UseRawHtmlMainRendererProps) => {
  // Reference to the raw HTML container
  const rawRef = useRef<HTMLDivElement>(null)

  // Retrieve the citations map from the store
  const citationParagraphs = useTypedSelector(
    (state) => state.documents.citationsMap
  )

  // Get document ID from the URL parameters
  const { id: documentId } = useParams()
  const documentIdNum = Number(documentId)

  // Get search params and navigate function from react-router
  const [searchParams, setSearchParams] = useSearchParams()
  const navigate = useNavigate()

  // Get citation paragraphs for the current document
  const documentCitationParagraphs = citationParagraphs[documentIdNum]

  // Retrieve anchor and highlighted paragraphs from URL search params
  const anchorId = searchParams.get('anchorId')
  const highlightParagraphs = searchParams.get('highlight_paragraphs')

  // Use highlighted paragraphs from URL if available; otherwise use the document's citations
  const updatedCitationParagraphs = highlightParagraphs
    ? highlightParagraphs.split(',')
    : documentCitationParagraphs

  // State for table width adjustments
  const [tableWidth, setTableWidth] = useState(0)

  // Retrieve document content settings from hook
  const {
    isShownChunks,
    isShowFirstVersionChunks,
    isShowSecondVersionChunks,
  } = useDocumentContents()

  // Configure chunk visibility based on index
  const chunksConfig = {
    0: isShownChunks,
    1: isShowFirstVersionChunks,
    2: isShowSecondVersionChunks,
  }
  const isShowChunks =
    chunksConfig[index as keyof typeof chunksConfig]

  // Effect: Highlight citation paragraphs in the rendered HTML (unless in versions mode)
  useEffect(() => {
    if (isVersions) return
    if (!rawRef.current || !updatedCitationParagraphs) return

    updatedCitationParagraphs.forEach((docIndex) => {
      const targetDoc = rawRef.current!.querySelector(
        `div[data-docindex="${docIndex}"]`
      )
      if (targetDoc instanceof HTMLElement) {
        targetDoc.classList.add('highlight-div')
      }
    })
  }, [rawRef.current, updatedCitationParagraphs])

  // Effect: Adjust table width for elements with class "table-wrapper"
  useEffect(() => {
    if (!rawRef.current) return

    const tableElements =
      rawRef.current.querySelectorAll('.table-wrapper')
    if (tableElements.length) {
      const { width } = rawRef.current.getBoundingClientRect()
      setTableWidth(Number(width.toFixed(0)))
      tableElements.forEach((element) => {
        if (element instanceof HTMLElement) {
          element.style.width = `${tableWidth - 30}px`
        }
      })
    }
  }, [rawRef.current])

  // Effect: Toggle chunk visibility based on isShowChunks flag
  useEffect(() => {
    if (!rawRef.current) return

    const allChunks =
      rawRef.current.querySelectorAll('.note.cls-text ')
    allChunks.forEach((chunk) => {
      if (chunk instanceof HTMLElement) {
        chunk.style.transform = isShowChunks
          ? 'scaleY(0)'
          : 'scaleY(1)'
        chunk.style.height = isShowChunks ? '0' : 'auto'
      }
    })
  }, [isShowChunks])

  // Effect: Scroll to the element specified by the "anchorId" search param and remove it from the URL
  useEffect(() => {
    if (!anchorId || !rawRef.current) return

    const targetDiv = rawRef.current.querySelector(
      `div[data-docindex="${anchorId}"]`
    ) as HTMLElement | null

    if (targetDiv) {
      targetDiv.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      })
      // Remove the anchorId param and update the URL
      searchParams.delete('anchorId')
      navigate({
        pathname: location.pathname,
        search: searchParams.toString(),
      })
    }
  }, [anchorId])

  // Note: The following effect is commented out, but can be used to update searchParams with document citations.
  /*
  useEffect(() => {
    if (documentCitationParagraphs) {
      searchParams.set(
        'highlight_paragraphs',
        documentCitationParagraphs.join(',')
      );
      setSearchParams(searchParams);
    }
  }, [documentCitationParagraphs]);
  */

  return {
    rawRef,
  }
}
