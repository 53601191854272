import IconSmallcapsDarkMedium from '@/shared/assets/icons/icon_smallcaps_dark_medium.svg?react'
import { IconButton } from '@/shared/ui'
import { FC } from 'react'

export interface ToLowerCaseProps {}

export const ActionDocumentToLowerCase: FC<ToLowerCaseProps> = () => {
  //   TODO: implement to lower case logic
  return (
    <IconButton className="p-1 hover:bg-base-100" disabled>
      <IconSmallcapsDarkMedium width="100%" height="100%" />
    </IconButton>
  )
}
