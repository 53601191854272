import { DocumentChapter } from '@/entities/document'
import IconChevronRight from '@/shared/assets/icons/icon_chevron_right.svg?react'
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
  Typography,
} from '@/shared/ui'
import { cn } from '@/shared/utils/common'

interface ChapterItemProps {
  chapter: DocumentChapter
  selectedAnchorId?: string | null
  handleDocumentChapterClick: (chapterId: number) => void
}

interface AccordionChapterProps extends ChapterItemProps {}

const AccordionChapter = ({
  chapter,
  selectedAnchorId,
  handleDocumentChapterClick,
}: AccordionChapterProps) => {
  return (
    <Accordion
      type="single"
      collapsible
      orientation="vertical"
      key={`sub-accordion-${chapter.paragraph_id}-${chapter.level}`}
    >
      <AccordionItem
        key={`${chapter.paragraph_id}-${chapter.level}`}
        value={String(chapter.paragraph_id)}
        className="flex min-h-0 flex-col border-b border-b-stroke-light-gray-1
          data-[state=open]:border-none"
      >
        <AccordionTrigger
          onClick={() =>
            handleDocumentChapterClick(chapter.paragraph_id)
          }
          className="flex w-full flex-row items-center justify-between px-4 py-3
            data-[state=open]:border-b"
          style={{
            paddingLeft:
              chapter.level > 1
                ? `${8 + chapter.level * 8}px`
                : '16px',
          }}
          iconSvg={
            chapter?.children && chapter.children.length > 0 ? (
              <IconChevronRight className="min-h-5 min-w-5" />
            ) : (
              <div className="h-5 w-5" />
            )
          }
          iconPosition="left"
        >
          {chapter.title}
        </AccordionTrigger>
        <AccordionContent className="flex max-h-full flex-col items-stretch justify-start p-0">
          {chapter.children?.map((subChapter) => (
            <ChaptersItem
              key={subChapter.paragraph_id}
              chapter={subChapter}
              selectedAnchorId={selectedAnchorId}
              handleDocumentChapterClick={handleDocumentChapterClick}
            />
          ))}
        </AccordionContent>
      </AccordionItem>
    </Accordion>
  )
}

interface AccordionItemChapterProps extends ChapterItemProps {}

const AccordionItemChapter = ({
  chapter,
  selectedAnchorId,
  handleDocumentChapterClick,
}: AccordionItemChapterProps) => {
  const isSelected =
    Number(chapter.paragraph_id) === Number(selectedAnchorId)

  return (
    <div
      key={`${chapter.paragraph_id}-${chapter.level}`}
      onClick={() => handleDocumentChapterClick(chapter.paragraph_id)}
      className={cn(
        `flex items-stretch justify-start gap-2 border-b
        border-b-stroke-light-gray-1 p-2 pl-4`,
        'hover:bg-bg-light-white-2',
        isSelected && 'bg-bg-light-white-2'
      )}
      style={{
        paddingLeft:
          chapter.level > 1 ? `${chapter.level * 8}px` : '16px',
      }}
    >
      <Typography className="min-w-[16px] text-center text-[14px] text-[#67707E]">
        •
      </Typography>
      <Typography
        variant="label"
        className="min-w-0 whitespace-normal break-words font-medium"
      >
        {chapter.title}
      </Typography>
    </div>
  )
}

export const ChaptersItem = ({
  chapter,
  selectedAnchorId,
  handleDocumentChapterClick,
}: ChapterItemProps) => {
  const hasChildren = chapter.children && chapter.children.length > 0

  if (hasChildren || chapter.level === 1) {
    return (
      <AccordionChapter
        chapter={chapter}
        selectedAnchorId={selectedAnchorId}
        handleDocumentChapterClick={handleDocumentChapterClick}
      />
    )
  }

  return (
    <AccordionItemChapter
      chapter={chapter}
      selectedAnchorId={selectedAnchorId}
      handleDocumentChapterClick={handleDocumentChapterClick}
    />
  )
}
