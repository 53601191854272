import IconCodexGray from '@/shared/assets/icons/icon_codex_gray.svg?react'
import { IconButton } from '@/shared/ui'

export const ChatResponseLoader = () => {
  return (
    <div className="group flex items-stretch justify-start gap-4 self-stretch rounded-lg">
      <div className="max-w-8">
        <IconButton
          className="flex items-center justify-center rounded-full border-[1px]
            border-base-200 bg-white p-[6px]"
        >
          <IconCodexGray className="h-[22px] w-[22px]" />
        </IconButton>
      </div>

      <div
        className="loader flex basis-16 items-center justify-center rounded-[111px]
          bg-bg-light-gray-2"
      >
        <span className="dot"></span>
        <span className="dot"></span>
        <span className="dot"></span>
      </div>
    </div>
  )
}
