import { useState } from 'react'

export const useChatDropDown = () => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false)

  const handleDropdownOpenChange = (isOpen: boolean) => {
    setIsDropdownOpen(isOpen)
  }

  return {
    isDropdownOpen,
    handleDropdownOpenChange,
    setIsDropdownOpen,
  }
}
