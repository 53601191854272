import { Actions, DocumentLanguages } from '@/entities/document/types'
import { useGetDocumentByIdQuery } from '@/shared/api/documents'
import { IconButton } from '@/shared/ui/IconButton'
import { LazySvg } from '@/shared/ui/LazySvg'
import { ActionsMenu } from '@/widgets/Document/ActionsMenu'
import { motion } from 'framer-motion'
import { useState } from 'react'
import { useSearchParams } from 'react-router-dom'

interface DocumentActionsMenuProps {
  documentId: string
}

export const DocumentActionsMenu = ({
  documentId,
}: DocumentActionsMenuProps) => {
  const [isOpen, setIsOpen] = useState(false)
  const toggleOpen = (e: any) => {
    e.preventDefault()
    setIsOpen(!isOpen)
  }

  return (
    <ActionsMenu
      actions={[
        Actions.Magnify,
        Actions.Chunks,
        Actions.LanguageMenu,
        Actions.ToLowerCase,
        Actions.Versions,
        Actions.Info,
        Actions.Bookmark,
        Actions.Download,
      ]}
      documentId={Number(documentId)}
      wrapper={(children) => (
        <div className="flex flex-col items-center justify-start gap-3">
          <IconButton
            className="h-10 w-10 rounded-full border border-stroke-light-gray-1
              bg-bg-light-white-1 p-[10px] shadow-button"
            onClick={(e) => toggleOpen(e)}
            aria-expanded={isOpen}
          >
            <LazySvg
              name="icon_burger_gray"
              className="h-5 w-5 [&_path]:stroke-[#25272C]"
            />
          </IconButton>

          <motion.div
            className="!box-border flex w-12 flex-col items-center justify-start gap-4
              overflow-hidden rounded-[16px] border-[1px] border-base-100
              bg-bg-light-white-1 p-2 shadow-sidebarS"
            initial={{ opacity: 0, height: 0 }}
            animate={
              isOpen
                ? { opacity: 1, height: 'auto' }
                : { opacity: 0, height: 0 }
            }
            transition={{
              height: { duration: 0.3, ease: 'easeInOut' },
              opacity: { duration: 0.2 },
            }}
          >
            {children}
          </motion.div>
        </div>
      )}
      itemWrapper={(children) => (
        <div className="h-8 w-8">{children}</div>
      )}
    />
  )
}
