import IconBucketBlack from '@/shared/assets/icons/icon_bucket_black.svg?react'
import { useAppDispatch } from '@/shared/store'
import { openDeleteDialog } from '@/shared/store/slices/chat/chatSlice'
import { DropdownMenuItem, Typography } from '@/shared/ui'

type Props = {
  chatId: string
}

export const DropdownMenuDeleteChat = ({ chatId }: Props) => {
  const dispatch = useAppDispatch()
  return (
    <>
      <DropdownMenuItem
        className="flex items-center justify-start gap-2 rounded-lg p-2
          hover:cursor-pointer hover:bg-slate-100"
        inset={true}
        onClick={(e) => {
          e.stopPropagation()
          dispatch(openDeleteDialog({ chatId }))
        }}
      >
        <IconBucketBlack className="h-5 w-5" />
        <Typography
          className="whitespace-nowrap font-normal"
          variant={'label'}
        >
          Удалить
        </Typography>
      </DropdownMenuItem>
    </>
  )
}
