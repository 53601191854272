import store from '@/shared/store'
import { logout } from '@/shared/store/slices/auth/userSlice'
import Cookies from 'js-cookie'
import { redirect } from 'react-router-dom'

export const authLoader = async ({
  request,
}: {
  request: Request
}) => {
  const token = Cookies.get('access_token')

  if (!token) {
    store.dispatch(logout())

    const currentPath = new URL(request.url).pathname
    if (currentPath !== '/signin' && currentPath !== '/signup') {
      return redirect('/signin')
    }
  }

  return null
}
