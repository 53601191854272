// DocumentLayout.tsx
import useMediaQuery from '@/shared/hooks/useMediaQuery'
import { useAppDispatch } from '@/shared/store'
import { setDocumentsReturnUrl } from '@/shared/store/slices/documents/documentsSlice'
import { cn } from '@/shared/utils/common'
import { useEffect } from 'react'
import { Outlet, useLocation } from 'react-router-dom'

export const DocumentLayout = () => {
  const isMobile = useMediaQuery('(max-width: 960px)')
  const location = useLocation()
  const dispatch = useAppDispatch()

  useEffect(() => {
    const currentUrl = location.pathname + location.search
    dispatch(setDocumentsReturnUrl({ url: currentUrl }))
  }, [location, dispatch])

  if (isMobile)
    return (
      <div
        // id="documents_container"
        className={cn(
          `flex flex-col items-stretch justify-start overflow-y-auto
          overflow-x-hidden px-0 pb-[88px] pt-4`
        )}
      >
        <Outlet context={{ isMobile: isMobile }} />
      </div>
    )

  return (
    <>
      {/* Documents */}
      <div
        className={cn(
          `relative flex w-full flex-1 items-stretch justify-start gap-3
          overflow-hidden bg-transparent px-6 pt-[72px]`
        )}
      >
        {/* Empty div for controlling the animations */}
        <div
          className={cn(
            'flex-none transition-all duration-300 ease-in-out'
          )}
        />
        {/* Contents */}
        <div
          className={cn(
            'relative min-w-0 flex-1 overflow-auto rounded-t-lg bg-transparent pt-0'
          )}
        >
          <Outlet context={{ isMobile: isMobile }} />
        </div>
      </div>
    </>
  )
}
