import { useChangeVersions, Version } from '@/entities/document'
import IconCheck from '@/shared/assets/icons/icon_check_dark.svg?react'
import { useTypedSelector } from '@/shared/store'
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  Typography,
} from '@/shared/ui'
import { cn } from '@/shared/utils/common'
import { FC } from 'react'
import { useSearchParams } from 'react-router-dom'

enum VersionSelectPosition {
  Left = 0,
  Right = 1,
}

interface VersionSelectProps {
  position: VersionSelectPosition
  isMobile?: boolean
}

export const VersionSelect: FC<VersionSelectProps> = ({
  position,
  isMobile,
}) => {
  const [searchParams] = useSearchParams()

  const { updateVersion } = useChangeVersions()
  const { versions, allVersions } = useTypedSelector(
    (state) => state.documents
  )
  const versionIds = searchParams.get('ids')?.split(',') || []
  const ids = [
    Number(versionIds[0]) ?? versions[0]?.id,
    Number(versionIds[1]) ?? versions[1]?.id,
  ]

  const myVersion = allVersions.find(
    (version) => version.id === ids[position]
  )
  const notMyVersion = allVersions.find(
    (version) => version.id === ids[Number(!position)]
  )

  const onValueChange = (value: string) => {
    updateVersion(position, +value)
  }

  return (
    <Select onValueChange={onValueChange}>
      <SelectTrigger
        className={`${isMobile ? 'h-9' : 'h-7'} w-full rounded-lg border-[1px] px-3 py-1
          ring-transparent focus:ring-0
          data-[state=open]:border-additional-blue-2
          data-[state=open]:outline-[0] data-[state=open]:ring-0`}
      >
        {myVersion?.published_at ? (
          <Typography variant={'label'}>
            {isMobile || 'Редакция от '}
            {myVersion?.published_at}
          </Typography>
        ) : (
          'Выберите версию'
        )}
      </SelectTrigger>
      <SelectContent className="z-[100] max-h-[164px] rounded-lg">
        {allVersions
          .filter((ver) => ver.id !== notMyVersion?.id)
          .map((ver) => {
            const isSelected = ver.id === myVersion?.id
            return (
              <SelectItem
                value={ver.id.toString()}
                className={cn(isSelected && 'bg-base-100')}
                key={ver.id}
              >
                <div className="flex w-full items-center justify-between pr-[3px]">
                  <Typography variant={'label'}>
                    {ver.published_at}
                  </Typography>
                  {isSelected && <IconCheck />}
                </div>
              </SelectItem>
            )
          })}
      </SelectContent>
    </Select>
  )
}
