import { DocumentLanguages } from '@/entities/document'
import {
  useGetDocumentByIdQuery,
  usePostToggleFavoriteDocumentMutation,
} from '@/shared/api/documents'
import { useToast } from '@/shared/ui/Toast/useToast'
import { set } from 'date-fns'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useSearchParams } from 'react-router-dom'

export const useToogleFavouriteDocument = (documentId: number) => {
  const [searchParams] = useSearchParams()
  const [isFavorite, setIsFavorite] = useState(false)

  const language = useMemo(() => {
    const fromUrl = searchParams.get('language')?.toLowerCase()
    return (fromUrl as DocumentLanguages) || 'ru'
  }, [searchParams])

  const { data } = useGetDocumentByIdQuery({
    id: documentId,
    language,
  })

  const [toggleFavorite, { isLoading, isSuccess, isError }] =
    usePostToggleFavoriteDocumentMutation()
  const { toast } = useToast()

  useEffect(() => {
    if (data?.document) {
      setIsFavorite(data.document.is_favorite)
    }
  }, [data])

  const handleToggleFavorite = useCallback(async () => {
    try {
      setIsFavorite((prev) => !prev)
      await toggleFavorite({ document_id: documentId }).unwrap()
    } catch {
      setIsFavorite((prev) => !prev)
      toast({
        title: 'Ошибка',
        variant: 'error',
        description:
          'Не удалось добавить в избранное. Попробуйте позднее',
      })
    }
  }, [documentId, toggleFavorite, toast])

  const [isBookmarkHovered, setIsBookmarkHovered] = useState(false)

  const handleMouseEnter = useCallback(
    () => setIsBookmarkHovered(true),
    []
  )
  const handleMouseLeave = useCallback(
    () => setIsBookmarkHovered(false),
    []
  )
  const handleClick = useCallback(
    () => setIsBookmarkHovered(false),
    []
  )

  return {
    isBookmarkHovered,
    handleMouseEnter,
    handleMouseLeave,
    handleToggleFavorite,
    isLoading,
    isSuccess,
    handleClick,
    isError,
    isFavorite,
  }
}
