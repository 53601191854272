import IconGrid from '@/shared/assets/icons/icon_grid.svg?react'
import { IconButton, Typography } from '@/shared/ui'

interface ChaptersHeaderProps {
  onClose?: () => void
  chaptersCloseDisabled?: boolean
}

export const ChaptersHeader = ({
  onClose,
  chaptersCloseDisabled = false,
}: ChaptersHeaderProps) => (
  <div
    className="mb-1 flex w-full items-center justify-between rounded-lg border-b
      border-b-stroke-light-gray-1 bg-bg-light-white-2 p-2"
  >
    <Typography
      variant="subtitle"
      className="font-semibold text-text-light-black-4"
    >
      Содержание
    </Typography>
    <IconButton onClick={onClose} disabled={chaptersCloseDisabled}>
      <IconGrid className="h-[18px] w-[18px] [&_path]:stroke-[#67707E]" />
    </IconButton>
  </div>
)
