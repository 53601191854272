import { SubtypeData } from '@/features/filters/FilterAccordion/types'
import IconChevronRight from '@/shared/assets/icons/icon_chevron_right.svg?react'
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from '@/shared/ui/Accordion'
import { Checkbox } from '@/shared/ui/Checkbox'
import { Typography } from '@/shared/ui/Typography'
import { cn } from '@/shared/utils/common'
import React from 'react'

interface FilterAccordionProps {
  subtypes: SubtypeData[]
  level?: number
  parentId: number
  isFiltered: boolean
  selectedIndices: number[]
  handleCheckboxChange: (id: number) => void
}

export const FilterAccordion: React.FC<FilterAccordionProps> = ({
  parentId,
  level = 0,
  subtypes,
  isFiltered,
  selectedIndices,
  handleCheckboxChange,
}) => {
  let filteredSubtypes: SubtypeData[]

  if (isFiltered) {
    const parentIds = new Set(
      subtypes.map((subtype) => subtype.parentId)
    )

    filteredSubtypes = subtypes.filter(
      (subtype) => !parentIds.has(subtype.id)
    )
  } else {
    filteredSubtypes = subtypes.filter(
      (subtype) => subtype.parentId === parentId
    )
  }

  return (
    <Accordion
      type="single"
      collapsible
      orientation="vertical"
      key={`sub-accordion`}
      className={cn(
        level === 0 &&
          parentId !== -1 &&
          'border-l border-l-[#E3E5E8]'
      )}
    >
      {filteredSubtypes.map((subtype) => {
        const hasChildren = subtypes.some((org) => {
          return org.parentId === subtype.id
        })

        return (
          <AccordionItem value={subtype.id.toString()}>
            <div
              className={cn(
                'flex cursor-pointer items-center justify-start gap-3 self-stretch py-3',
                '!m-0 pr-2 md:py-[10px]',
                level === 0 ? 'pl-4' : 'pl-2'
              )}
              style={{ marginLeft: `${level * 16}px` }}
            >
              <Checkbox
                variant={'secondary'}
                id={`checkbox-${subtype.id}`}
                className="shrink"
                size={'small'}
                checked={selectedIndices.includes(subtype.id)}
                onCheckedChange={() =>
                  handleCheckboxChange(subtype.id)
                }
              />
              <AccordionTrigger
                className="p-0"
                iconSvg={
                  hasChildren ? (
                    <IconChevronRight className="max-h-6 max-w-6" />
                  ) : null
                }
              >
                <span className="flex h-full items-center justify-start text-start">
                  <Typography
                    variant={'body'}
                    className="inline text-sm"
                  >
                    {subtype.name}{' '}
                    <Typography
                      as="span"
                      variant={'subtitle'}
                      className="text-text-light-black-3"
                    >
                      ({subtype.document_amount})
                    </Typography>
                  </Typography>
                </span>
              </AccordionTrigger>
            </div>
            {hasChildren && (
              <AccordionContent className="ml-3 flex flex-col gap-3 border-l border-l-[#E3E5E8]">
                <FilterAccordion
                  subtypes={subtypes}
                  level={level + 1}
                  isFiltered={false}
                  parentId={subtype.id}
                  selectedIndices={selectedIndices}
                  handleCheckboxChange={handleCheckboxChange}
                />
              </AccordionContent>
            )}
          </AccordionItem>
        )
      })}
    </Accordion>
  )
}
