import { yupResolver } from '@hookform/resolvers/yup'
import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useSearchParams } from 'react-router-dom'
import * as yup from 'yup'

const yupSchema = yup
  .object()
  .shape({
    radio: yup.string(),
    check1: yup.boolean(),
    check2: yup.boolean(),
    check3: yup.boolean(),
  })
  .shape({
    test1: yup.string(),
    test2: yup.string(),
    test3: yup.string(),

    select: yup.string(),
    select3: yup.string(),
    select4: yup.string(),
    caseResult: yup.string(),
  })
  .required()

export const useSearchForm = () => {
  const [searchParams] = useSearchParams()
  const [isAdvanced, setIsAdvanced] = useState(false)
  const [isValid, setIsValid] = useState(false)

  const { control, handleSubmit, reset, watch } = useForm({
    defaultValues: {
      radio: '',
      check1: false,
      check2: false,
      check3: false,
      select3: '',
      select4: '',
      test1: '',
      test2: '',
      test3: '',
      select: '',
      caseResult: '',
    },
    resolver: yupResolver(yupSchema),
  })

  useEffect(() => {
    if (watch()) {
      setIsValid(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watch()])

  const onSubmit = (data: any) => {
    console.log(data)
  }
  const onReset = () => {
    reset()
  }

  const searchMode = searchParams.get('search_mode') ?? 'IN_TITLE'

  return {
    control,
    handleSubmit,
    isValid,
    onSubmit,
    onReset,

    searchMode,

    isAdvanced,
    setIsAdvanced,
  }
}
