import { DocumentLanguages } from '@/entities/document/types'
import { DocumentPanelSkeleton } from '@/entities/panel/ui/DocumentPanelSkeleton'
import { RawHtmlMainRenderer } from '@/entities/panel/ui/RawHtmlMainRenderer'
import { useGetDocumentByIdQuery } from '@/shared/api/documents'
import { LoadCover } from '@/shared/ui2/LoadCover'
import { Typography } from '@/shared/ui/Typography'
import { useParams, useSearchParams } from 'react-router-dom'

export const DocumentContents = () => {
  const { id: documentId } = useParams()
  const [searchParams] = useSearchParams()
  const languageFromUrl = searchParams.get('language')
  const language: DocumentLanguages =
    (languageFromUrl?.toLowerCase() as DocumentLanguages) || 'ru'

  const { data, error, isLoading, isFetching } =
    useGetDocumentByIdQuery({
      id: Number(documentId),
      language,
    })

  return (
    <LoadCover
      isLoad={isLoading || isFetching}
      skeleton={<DocumentPanelSkeleton />}
      isError={Boolean(error)}
      errorComponent={<Typography>Error...</Typography>}
    >
      <div
        className="flex h-[calc(100svh-96px)] flex-col items-stretch justify-start gap-8
          overflow-y-scroll rounded-lg border border-gray-200 p-8"
      >
        <RawHtmlMainRenderer
          rawHtml={data?.document.content ?? ''}
          index={0}
        />
      </div>
    </LoadCover>
  )
}
