import { CustomFilterInput, ECategoryType } from '@/entities/filter'
import { Subtypes } from '@/entities/filter/types'
import { useSubtypesFilter } from '@/features/filters/FilterAccordion/hooks/useSubtypesFilter'
import { Button } from '@/shared/ui'
import { Checkbox } from '@/shared/ui/Checkbox'
import { Typography } from '@/shared/ui/Typography'

import { FilterAccordion } from '../FilterAccordion'

export const FilterMenuDesktop = ({
  categoryType,
  searchParam,
}: Subtypes) => {
  const {
    subtypes,
    isFiltered,
    allSelected,
    setSubtypes,
    setTotalSubtypesCount,
    selectedIndices,
    setIsFiltered,
    handleReset,
    handleSelectAll,
    handleCheckboxChange,
  } = useSubtypesFilter({ categoryType, searchParam })

  return (
    <>
      <div className="relative flex flex-col items-center justify-between bg-white px-2">
        <CustomFilterInput
          setIsFiltered={setIsFiltered}
          setTotalCount={setTotalSubtypesCount}
          setFilterItems={setSubtypes}
          categoryType={categoryType}
        />
        <div className="flex justify-start gap-[6px] self-stretch border-b py-2 pl-2">
          <Typography
            className="whitespace-nowrap"
            variant={'subtitle'}
          >
            Выбрано фильтров: {selectedIndices.length}
          </Typography>
          <Button
            variant={'ghost'}
            disabled={selectedIndices.length === 0}
            className="font-inter rounded-none px-3 py-0 text-center text-[12px] font-normal
              leading-[16px] text-[#2E3238] underline underline-offset-auto
              disabled:bg-transparent disabled:hover:text-[#2E3238]"
            onClick={handleReset}
          >
            Очистить
          </Button>
        </div>
      </div>

      <div
        className="relative flex flex-col overflow-auto bg-white"
        style={{ height: 'calc(100vh - 414px)' }}
      >
        <div className="flex items-center justify-start gap-2 py-[10px] pl-4 pr-2">
          <Checkbox
            variant={'secondary'}
            size={'small'}
            checked={allSelected}
            onCheckedChange={handleSelectAll}
            id="select-all-checkbox"
          />
          <Typography variant={'subtitle'} className="ml-1 text-sm">
            Все
          </Typography>
        </div>

        <FilterAccordion
          parentId={-1}
          level={0}
          subtypes={subtypes}
          isFiltered={isFiltered}
          selectedIndices={selectedIndices}
          handleCheckboxChange={handleCheckboxChange}
        />
      </div>
    </>
  )
}
