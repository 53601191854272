import { usePatchRenameChatByIdMutation } from '@/shared/api/chat'
import IconCheck from '@/shared/assets/icons/icon_check_dark.svg?react'
import { useAppDispatch, useTypedSelector } from '@/shared/store'
import {
  closeRenameChat,
  openRenameChat,
  setRenameTitle,
} from '@/shared/store/slices/chat/chatSlice'
import { useToast } from '@/shared/ui/Toast/useToast'
import { useEffect, useRef, useState } from 'react'

export const useChatRename = (
  chatId: string,
  initialTitle: string
) => {
  const dispatch = useAppDispatch()
  const { toast } = useToast()
  const { chatsData } = useTypedSelector((state) => state.chats)
  // Ensure we have a fallback object so that destructuring doesn’t fail.
  const chatState = chatsData[chatId] || {}
  const isBeingRenamed = chatState.isBeingRenamed || false
  const newTitle = chatState.newTitle ?? initialTitle

  const [renameChat] = usePatchRenameChatByIdMutation()
  const inputRef = useRef<HTMLInputElement>(null)

  useEffect(() => {
    if (isBeingRenamed) {
      setTimeout(() => {
        inputRef.current?.focus()
      }, 0)
    }
  }, [isBeingRenamed])

  const openRenaming = () => {
    dispatch(openRenameChat({ chatId, initialTitle }))
  }

  const updateNewTitle = (title: string) => {
    dispatch(setRenameTitle({ chatId, newTitle: title }))
  }

  const closeRenaming = () => {
    dispatch(closeRenameChat({ chatId }))
  }

  const handleRenameChat = async () => {
    if (newTitle.trim() === initialTitle) {
      closeRenaming()
      return
    }
    try {
      await renameChat({
        id: chatId,
        title: newTitle,
      }).unwrap()
      toast({
        variant: 'success',
        title: 'Чат успешно переименован',
      })
    } catch (error: any) {
      console.error('Failed to rename chat:', error)
      toast({
        variant: 'error',
        title: 'Ошибка переименования чата',
        description: error?.data?.message ?? 'Что-то пошло не так',
      })
    } finally {
      closeRenaming()
    }
  }

  return {
    isBeingRenamed,
    newTitle,
    openRenaming,
    updateNewTitle,
    closeRenaming,
    handleRenameChat,
    inputRef,
  }
}
