import { useDeleteChat } from '@/features/chat/models/useDeleteChat'
import IconBucketBlack from '@/shared/assets/icons/icon_bucket_black.svg?react'
import { useAppDispatch, useTypedSelector } from '@/shared/store'
import { closeDeleteDialog } from '@/shared/store/slices/chat/chatSlice'
import {
  Button,
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  Spinner,
  Typography,
} from '@/shared/ui'

export const ChatDeleteDialogue = () => {
  const { handleDelete, isDeleting } = useDeleteChat()

  const { isDeleteChatDialogOpen, chatIdToDelete } = useTypedSelector(
    (state) => state.chats
  )
  const dispatch = useAppDispatch()

  return (
    <Dialog modal open={isDeleteChatDialogOpen}>
      <DialogContent
        onClose={() => dispatch(closeDeleteDialog())}
        className="z-[80] overflow-hidden rounded-3xl bg-white lg:max-w-[556px]"
      >
        <DialogHeader>
          <DialogTitle className="text-start">
            <Typography
              variant="heading5"
              className="text-lg font-bold md:text-[24px]"
            >
              Вы собираетесь удалить диалог
            </Typography>
          </DialogTitle>
        </DialogHeader>

        <DialogDescription className="text-sm">
          <Typography>
            Диалог, который вы выбрали, удалится навсегда без
            возможности возврата. Подтвердите действие.
          </Typography>
        </DialogDescription>

        <DialogFooter>
          <Button
            className="w-1/2"
            disabled={isDeleting}
            onClick={() => dispatch(closeDeleteDialog())}
          >
            <Typography className="text-sm font-medium">
              Отменить
            </Typography>
          </Button>
          <Button
            className="flex w-1/2 justify-center gap-2 border-[#B5BBC4]"
            variant="outlined"
            disabled={isDeleting}
            onClick={() => handleDelete(chatIdToDelete ?? '')}
          >
            {isDeleting ? (
              <Spinner size={'small'} variant="secondary" />
            ) : (
              <IconBucketBlack className="h-5 w-5" />
            )}
            <Typography className="text-sm font-medium text-[#25272C]">
              Удалить
            </Typography>
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  )
}
