import {
  FavoriteChatMessagesMobile,
  useFavoriteMessages,
} from '@/entities/chat'
import IconArrowScroll from '@/shared/assets/icons/icon_arrow_scroll.svg?react'
import { ButtonScrollToAnchor } from '@/shared/ui/ButtonScrollToAnchor'
import { ErrorNotification } from '@/shared/ui/ErrorNotification'
import { cn } from '@/shared/utils/common'
import { useSidebar } from '@/widgets/Sidebar'

import { ChatContainerSkeleton } from '../ChatContainer/ChatContainerSkeleton'

export const FavoriteChatContainerMobile = () => {
  const {
    error,
    messages,
    isLoading,
    isFetching,
    bottomRef,
    isBottomVisible,
    scrollContainerRef,
  } = useFavoriteMessages()

  const { isSidebarOpen } = useSidebar()

  if (!messages) return null

  if (!isFetching && !isLoading) {
    return (
      <>
        {error && (
          <ErrorNotification
            offset={28}
            position="bottom"
            className="shadow-shadow7"
            actionLabel="Перезагрузить"
            message="Не удалось загрузить избранные диалоги"
            onActionClick={() => window.location.reload()}
            description="Попробуйте перезагрузить страницу"
          />
        )}
        <div
          id="chat_messages_container"
          className={cn(
            `relative flex flex-1 grow flex-col items-stretch justify-start gap-8
            px-3 pb-[100px]`,
            'md:max-w-[728px]'
          )}
        >
          <FavoriteChatMessagesMobile messages={messages} />
          <div ref={bottomRef} />
        </div>
        {!isBottomVisible && (
          <ButtonScrollToAnchor
            className="fixed bottom-[104px] left-1/2 -translate-x-1/2 rounded-full border
              border-base-200 bg-white p-1"
            style={{
              boxShadow:
                '0px 18px 5px 0px rgba(0, 0, 0, 0.00), 0px 12px 5px 0px rgba(0, 0, 0, 0.00), 0px 7px 4px 0px rgba(0, 0, 0, 0.02), 0px 3px 3px 0px rgba(0, 0, 0, 0.03), 0px 1px 2px 0px rgba(0, 0, 0, 0.03)',
            }}
            targetRef={bottomRef}
          >
            <IconArrowScroll />
          </ButtonScrollToAnchor>
        )}
      </>
    )
  }
  return (
    <ChatContainerSkeleton
      isSidebarOpen={isSidebarOpen}
      isMobile={true}
    />
  )
}
