import IconOpenDark from '@/shared/assets/icons/icon_open_dark.svg?react'
import IconOpen from '@/shared/assets/icons/icon_open_mobile.svg?react'
import { MenubarItem, Typography } from '@/shared/ui'
import { IconButton } from '@/shared/ui'
import { cn } from '@/shared/utils/common'
import { FC } from 'react'

export interface OpenInNewTabProps {
  documentId?: number
  withLabel?: boolean
  isMobile?: boolean
}

export const ActionDocumentOpenInNewTab: FC<OpenInNewTabProps> = ({
  documentId,
  isMobile = false,
  withLabel = false,
}) => {
  // Local click handler to open new tab
  const handleOpenInNewTabClick = () => {
    if (documentId) {
      window.open(`/documents/${documentId}`, '_blank')
    } else {
      console.warn('No document ID provided.')
    }
  }

  if (isMobile) {
    return (
      <div
        onClick={handleOpenInNewTabClick}
        className="flex h-16 w-full cursor-pointer items-center justify-start gap-3
          border-b border-b-stroke-light-gray-1"
      >
        <IconOpen className="min-h-6 min-w-6" />
        <Typography
          variant="label"
          className="text-text-light-black-8"
        >
          В новой вкладке
        </Typography>
      </div>
    )
  }

  if (!withLabel) {
    return (
      <IconButton
        className="p-1 hover:bg-base-100"
        onClick={handleOpenInNewTabClick}
      >
        <IconOpenDark width="100%" height="100%" />
      </IconButton>
    )
  }

  return (
    <MenubarItem
      onClick={handleOpenInNewTabClick}
      inset={true}
      className="p-0"
    >
      <div
        className={cn(
          `flex w-full items-center gap-3 rounded-lg p-2 hover:cursor-pointer
          hover:bg-slate-100`
        )}
      >
        <IconOpenDark className="h-5 w-5" />
        <Typography
          className="text-nowrap font-normal"
          variant="label"
        >
          Открыть в новой вкладке
        </Typography>
      </div>
    </MenubarItem>
  )
}
