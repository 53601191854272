import { LazySvg } from '@/shared/ui/LazySvg'
import {
  // Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
} from '@/shared/ui/Select'
import { Typography } from '@/shared/ui/Typography'
import { CheckIcon } from '@radix-ui/react-icons'
import * as Select from '@radix-ui/react-select'
import { ComponentType, ReactNode, useEffect } from 'react'
import {
  Control,
  Controller,
  FieldErrors,
  FieldValues,
  Path,
  UseFormResetField,
} from 'react-hook-form'

type ControlType<T extends FieldValues> = Control<T>

export interface HookFormInputSelectProps<
  T extends FieldValues,
  E extends Record<string, any>,
> {
  control: ControlType<T>
  name: Path<T>
  errors?: FieldErrors<any>
  resetField?: UseFormResetField<any>

  placeholder?: string
  options: E[]
  getValue?: (option: E) => string
  getLabel?: (option: E) => ReactNode
}

const HookFormInputSelect = <
  T extends FieldValues,
  E extends Record<string, any>,
>({
  control,
  name,
  errors,
  resetField,

  placeholder,
  options,
  getValue,
  getLabel,
}: HookFormInputSelectProps<T, E>) => {
  useEffect(() => {
    return () => {
      resetField?.(name)
    }
  }, [name, resetField])

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { value, onChange } }) => (
        <Select.Root
          onValueChange={(value) => onChange(value)}
          value={value}
        >
          <SelectTrigger
            className="h-9 ring-transparent focus:ring-0
              data-[state=open]:border-additional-blue-2
              data-[state=open]:outline-[0] data-[state=open]:ring-0"
          >
            {value || (
              <Typography className="text-sm text-base-400">
                {placeholder}
              </Typography>
            )}
          </SelectTrigger>

          <SelectContent>
            {options.map((option) => (
              <Select.Item
                key={JSON.stringify(option)}
                value={getValue?.(option) || String(option?.value)}
                className={`flex cursor-pointer items-center gap-2 rounded-md p-2
                hover:bg-gray-200`}
              >
                <Select.ItemText>
                  {getLabel?.(option) || option?.label}
                </Select.ItemText>
              </Select.Item>
            ))}
          </SelectContent>
        </Select.Root>
      )}
    />
  )
}

export default HookFormInputSelect
