// src/widgets/Headers/PlatformHeader.tsx
import IconAvatarGray from '@/shared/assets/icons/icon_avatar_gray.svg?react'
import IconFolderGray from '@/shared/assets/icons/icon_folder_gray.svg?react'
import IconQuestionGray from '@/shared/assets/icons/icon_question_gray.svg?react'
import LogoSvg from '@/shared/assets/images/logo.svg?react'
import { cn } from '@/shared/utils/common'

import { ProfileNavigationMenu } from '../../Profile/ProfileNavigationMenu'
import { SegmentedNavbar, useSegmented } from '../SegmentedNavbar'
import { initialSegmentedItems } from '../SegmentedNavbar/consts'

export const PlatformHeader = () => {
  const {
    value,
    segmentedItems,
    setSegmentedItemDisabled,
    handleSegmentedItemChange,
  } = useSegmented(initialSegmentedItems)

  return (
    <div
      className="fixed top-0 z-[80] flex h-[60px] w-[100svw] items-center
        justify-between bg-custom-light px-8"
      style={{ backdropFilter: 'blur(6px)' }}
    >
      <LogoSvg />
      <div className="flex h-10 shrink items-center justify-center gap-4">
        <SegmentedNavbar
          items={segmentedItems}
          value={value}
          handleChange={handleSegmentedItemChange}
        />
      </div>
      <div className={cn('flex items-start gap-8')}>
        <IconQuestionGray
          className="transform rounded-lg p-0 transition-transform duration-200 ease-in-out
            hover:scale-[1.1] hover:cursor-pointer active:scale-[0.95]"
          height={24}
          width={24}
        />
        <IconFolderGray
          className="transform rounded-lg p-0 transition-transform duration-200 ease-in-out
            hover:scale-[1.1] hover:cursor-pointer active:scale-[0.95]"
          height={24}
          width={24}
        />
        <ProfileNavigationMenu>
          <IconAvatarGray
            className="transform rounded-lg p-0 transition-transform duration-200 ease-in-out
              hover:scale-[1.1] hover:cursor-pointer active:scale-[0.95]"
            height={24}
            width={24}
          />
        </ProfileNavigationMenu>
      </div>
    </div>
  )
}
