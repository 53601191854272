/* eslint-disable react-hooks/exhaustive-deps */
import { Version } from '@/entities/document'
import { DocumentLanguages } from '@/entities/document/types'
import { useDidUpdateEffect } from '@/shared/utils/useDidUpdateEffect'
import { useEffect } from 'react'
import { useParams, useSearchParams } from 'react-router-dom'

import { useGetDocumentByIdQuery } from '../../../shared/api/documents'
import {
  useAppDispatch,
  useTypedSelector,
} from '../../../shared/store'
import {
  setDocumentsAllVersions,
  setDocumentsVersions,
} from '../../../shared/store/slices/documents/documentsSlice'

export const useCompareTwoVersions = () => {
  const dispatch = useAppDispatch()
  const [searchParams] = useSearchParams()
  const { id: documentId } = useParams()
  const { versions, allVersions } = useTypedSelector(
    (state) => state.documents
  )

  const language: DocumentLanguages =
    (searchParams.get('language') as DocumentLanguages) || 'ru'
  const versionIds = searchParams.get('ids')?.split(',') || []
  const firstVersionId = Number(versionIds[0]) ?? versions[0]?.id
  const secondVersionId = Number(versionIds[1]) ?? versions[1]?.id

  const { data, isLoading, isFetching } = useGetDocumentByIdQuery({
    id: Number(documentId),
    language,
  })

  const {
    data: firstVerData,
    error: firstVerError,
    isLoading: isFirstVerLoading,
    isFetching: isFirstVerFetching,
    refetch: refetchFirstVer,
  } = useGetDocumentByIdQuery(
    { id: firstVersionId, language: language },
    { skip: !firstVersionId }
  )

  const {
    data: secondVerData,
    error: secondVerError,
    isLoading: isSecondVerLoading,
    isFetching: isSecondVerFetching,
    refetch: refetchSecondVer,
  } = useGetDocumentByIdQuery(
    { id: secondVersionId, language: language },
    { skip: !secondVersionId }
  )

  useDidUpdateEffect(() => {
    if (firstVersionId) {
      refetchFirstVer()
    }
  }, [firstVersionId])

  useDidUpdateEffect(() => {
    if (secondVersionId) {
      refetchSecondVer()
    }
  }, [secondVersionId])

  useEffect(() => {
    if (data) {
      dispatch(setDocumentsAllVersions(data.document.versions))
    }
  }, [data, dispatch, isLoading])

  useEffect(() => {
    if (
      versions &&
      versions.length &&
      +versionIds[0] !== 0 &&
      +versionIds[1] !== 0
    ) {
      const firstVersions = allVersions.find(
        (v) => v.id === +versionIds[0]
      )
      const secondVersions = allVersions.find(
        (v) => v.id === +versionIds[1]
      )
      if (firstVersions && secondVersions) {
        const updatedVersions: [Version, Version] = [
          firstVersions,
          secondVersions,
        ]
        dispatch(setDocumentsVersions(updatedVersions))
      }
    }
  }, [allVersions, dispatch])

  return {
    firstVerError,
    secondVerError,
    data,
    firstVerData,
    isFirstVerLoading,
    isFirstVerFetching,
    secondVerData,
    isSecondVerLoading,
    isSecondVerFetching,
    isLoading,
    isFetching,
  }
}
