import {
  DocumentVersionsMenuSkeleton,
  DocumentViewer,
  useCompareTwoVersions,
} from '@/entities/document'
import { DocumentPanelSkeleton } from '@/entities/panel/ui/DocumentPanelSkeleton'
import { Typography } from '@/shared/ui'
import { LoadCover } from '@/shared/ui2/LoadCover'
import { VersionsMenu } from '@/widgets/Document/VersionMenu'

export const WidgetDocumentVersionsContents = () => {
  const {
    firstVerError,
    secondVerError,
    data,
    firstVerData,
    isFirstVerLoading,
    isFirstVerFetching,
    secondVerData,
    isSecondVerLoading,
    isSecondVerFetching,
    isLoading,
    isFetching,
  } = useCompareTwoVersions()

  if (firstVerError || secondVerError) {
    return <Typography>Error...</Typography>
  }

  return (
    <div
      className="relative mx-auto flex h-[calc(100svh-96px)] w-full max-w-[1480px]
        flex-col items-stretch justify-start gap-3 xs:px-0 md:px-0"
    >
      <LoadCover
        isLoad={isLoading || isFetching}
        skeleton={<DocumentVersionsMenuSkeleton />}
      >
        <VersionsMenu label={data?.document?.name} />
      </LoadCover>

      <div className="flex gap-3">
        <div className="w-1/2">
          <LoadCover
            isLoad={isFirstVerLoading || isFirstVerFetching}
            skeleton={
              <div
                className="h-[calc(100svh-170px)] w-full rounded-t-[12px] border border-base-100
                  px-16 pt-8 shadow-shadow-modal-light"
              >
                <DocumentPanelSkeleton />
              </div>
            }
          >
            <DocumentViewer data={firstVerData?.document} index={0} />
          </LoadCover>
        </div>

        <div className="w-1/2">
          <LoadCover
            isLoad={isSecondVerLoading || isSecondVerFetching}
            skeleton={
              <div
                className="h-[calc(100svh-170px)] w-full rounded-t-[12px] border border-base-100
                  px-16 pt-8 shadow-shadow-modal-light"
              >
                <DocumentPanelSkeleton />
              </div>
            }
          >
            <DocumentViewer
              data={secondVerData?.document}
              index={1}
            />
          </LoadCover>
        </div>
      </div>
    </div>
  )
}
