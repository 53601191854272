import { CategoriesPage, Category } from '@/entities/filter/types'
import { FilterMenuDesktop } from '@/features/filters'
import IconChevronRight from '@/shared/assets/icons/icon_chevron_right.svg?react'
import IconExitLeft from '@/shared/assets/icons/icon_exit_left.svg?react'
import IconFilterDark from '@/shared/assets/icons/icon_filter_dark.svg?react'
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
  IconButton,
  Typography,
} from '@/shared/ui'
import { cn } from '@/shared/utils/common'
import { AnimatePresence, motion } from 'framer-motion'

interface WidgetCategoriesProps {
  pageTitle: CategoriesPage
  handleMenuToggle: () => void
  isMenuOpen: boolean
  categoriesData: Category[]
}

export const WidgetCategories = ({
  pageTitle,
  categoriesData,
  handleMenuToggle,
  isMenuOpen,
}: WidgetCategoriesProps) => {
  return (
    <motion.div
      className={cn(
        'sticky top-0 z-50 flex max-h-full w-full flex-col',
        'items-stretch justify-start self-stretch pr-0'
      )}
      animate={{
        width: isMenuOpen ? 273 : 40,
      }}
      transition={{ duration: 0.3, ease: 'easeInOut' }}
    >
      {/* IconButton */}
      <motion.div
        initial={{ opacity: 1 }}
        animate={{ opacity: isMenuOpen ? 0 : 1 }}
        transition={{ duration: 0.2 }}
        className="absolute left-0 top-0"
      >
        <IconButton
          disabled={!categoriesData.length}
          onClick={handleMenuToggle}
          className="h-10 w-10 rounded-[12px] border border-bg-light-gray-2 bg-white
            p-[10px] shadow-shadow8"
        >
          <IconFilterDark />
        </IconButton>
      </motion.div>

      {/* Animated Menu Drawer */}
      <AnimatePresence>
        {isMenuOpen && (
          <motion.div
            key="accordion"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.3, ease: 'easeInOut' }}
            className="z-20 max-h-full w-full shrink overflow-hidden rounded-[16px] border
              border-gray-200 shadow-containerS"
          >
            <Accordion
              type="single"
              collapsible
              orientation="vertical"
              className="max-h-full min-h-0 w-full gap-0 bg-white p-0"
            >
              {/* Accordion Header */}
              <div className="flex items-center justify-between px-4 py-3">
                <Typography
                  variant={'subtitle'}
                  className="font-semibold text-base-600"
                >
                  Категории
                </Typography>
                <IconButton onClick={handleMenuToggle}>
                  <IconExitLeft />
                </IconButton>
              </div>

              {/* Accordion Items */}
              {categoriesData.map((filterType) => (
                <AccordionItem
                  value={filterType.categoryType}
                  className="flex min-h-0 flex-col"
                  key={filterType.name}
                >
                  <AccordionTrigger
                    className="flex w-full flex-row items-center justify-between px-4 py-3"
                    iconSvg={
                      <IconChevronRight className="max-h-6 max-w-6" />
                    }
                  >
                    <Typography
                      className="whitespace-nowrap font-medium"
                      variant={'label'}
                    >
                      {filterType.name}
                    </Typography>
                  </AccordionTrigger>
                  <AccordionContent
                    className={cn(
                      'relative flex flex-col items-stretch justify-start pt-0'
                    )}
                  >
                    <FilterMenuDesktop
                      categoryType={filterType.categoryType}
                      searchParam={filterType.searchParam}
                    />
                  </AccordionContent>
                </AccordionItem>
              ))}
            </Accordion>
          </motion.div>
        )}
      </AnimatePresence>
    </motion.div>
  )
}
