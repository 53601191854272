import { Checkbox, CheckboxProps } from '@/shared/ui/Checkbox'
import {
  Control,
  Controller,
  FieldErrors,
  FieldValues,
  Path,
  UseFormResetField,
} from 'react-hook-form'

interface HookFormCheckboxProps<T extends FieldValues>
  extends Omit<CheckboxProps, 'value'> {
  control: Control<T>
  name: Path<T>
  errors?: FieldErrors<T>
  resetField?: UseFormResetField<T>
}

export const HookFormCheckbox = <T extends FieldValues>({
  control,
  name,
  errors,
  resetField,

  ...restProps
}: HookFormCheckboxProps<T>) => {
  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { onChange, value, ref } }) => (
        <Checkbox
          {...restProps}
          onCheckedChange={onChange}
          checked={value}
          ref={ref}
        />
      )}
    />
  )
}
