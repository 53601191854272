import { DocumentData } from '@/entities/document/types'
import { DocumentPanelSkeleton } from '@/entities/panel/ui/DocumentPanelSkeleton'
import { RawHtmlMainRenderer } from '@/entities/panel/ui/RawHtmlMainRenderer'
import useMediaQuery from '@/shared/hooks/useMediaQuery'
import { useEffect, useRef } from 'react'

interface DocumentViewerProps {
  data: DocumentData | undefined
  index: number
}

export const DocumentViewer = ({
  data,
  index,
}: DocumentViewerProps) => {
  const isMobile = useMediaQuery('(max-width: 960px)')

  const varsionDocumentRef = useRef<HTMLDivElement | null>(null)

  useEffect(() => {
    if (varsionDocumentRef.current) {
      varsionDocumentRef.current.scrollTo({
        top: 0,
        behavior: 'smooth',
      })
    }
  }, [data])

  return (
    <div className="flex h-full w-full flex-col items-stretch justify-start bg-white">
      <div
        className={`p relative h-[calc(100svh-170px)] overflow-y-auto rounded-t-[12px]
          border border-base-100 bg-white ${isMobile ? 'px-5' : 'px-16'} py-8
          shadow-shadow-modal-light`}
        ref={varsionDocumentRef}
      >
        <RawHtmlMainRenderer
          rawHtml={data?.content || ''}
          index={index}
          isVersions={true}
        />
      </div>
    </div>
  )
}
