import IconMagnifyDark from '@/shared/assets/icons/icon_magnify_dark.svg?react'
import { IconButton, Typography } from '@/shared/ui'
import { FC } from 'react'

export interface MagnifyProps {
  isMobile?: boolean
}

export const ActionDocumentMagnify: FC<MagnifyProps> = ({
  isMobile,
}) => {
  if (isMobile) {
    return (
      <div
        className="flex h-16 w-full cursor-pointer items-center gap-3 border-b
          border-b-stroke-light-gray-1"
      >
        <IconMagnifyDark className="h-6 w-6" />
        <Typography variant="label">Поиск по документу</Typography>
      </div>
    )
  }
  return (
    <IconButton className="p-1 hover:bg-base-100" disabled>
      <IconMagnifyDark width="100%" height="100%" />
    </IconButton>
  )
}
