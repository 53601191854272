/* eslint-disable react-hooks/exhaustive-deps */
import {
  Input,
  InputProps as RadixInputProps,
} from '@/shared/ui/Input'
import { useEffect } from 'react'
import {
  Control,
  Controller,
  FieldErrors,
  FieldValues,
  Path,
  UseFormResetField,
} from 'react-hook-form'

type InputProps = Omit<
  RadixInputProps,
  'ref' | 'onChange' | 'value' | 'error'
>

export interface HookFormInputProps<T extends FieldValues>
  extends InputProps {
  control: Control<T>
  name: Path<T>
  errors?: FieldErrors<T>
  resetField?: UseFormResetField<T>
}

const HookFormInput = <T extends FieldValues>({
  control,
  name,
  errors,
  resetField,

  ...restProps
}: HookFormInputProps<T>) => {
  useEffect(
    () => () => {
      resetField?.(name)
    },
    []
  )

  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { onChange, value, ref } }) => (
        <Input
          {...restProps}
          // error={}
          ref={ref}
          value={value || ''}
          onChange={onChange}
        />
      )}
    />
  )
}

export default HookFormInput
