import { IconButton } from '@/shared/ui/IconButton'
import { LazySvg } from '@/shared/ui/LazySvg'
import { MenubarContentProps } from '@radix-ui/react-menubar'
import React, { ReactNode, useEffect, useState } from 'react'

import {
  Menubar as StyledMenuBar,
  MenubarContent as StyledMenubarContent,
  MenubarMenu as StyledMenubarMenu,
  MenubarTrigger as StyledMenubarTrigger,
} from '../../ui/Menubar'

interface MenubarProps extends MenubarContentProps {
  iconName: string
  open?: boolean
  children: ReactNode
}

export const Menubar = ({
  open,
  iconName,
  children,
  ...restPoprs
}: MenubarProps) => {
  const [isOpen, setIsOpen] = useState<boolean>(false)

  useEffect(() => {
    setIsOpen(!!open)
  }, [open])

  return (
    <StyledMenuBar>
      <StyledMenubarMenu>
        <StyledMenubarTrigger
          onClick={() => setIsOpen(true)}
          className="data-[state=open]:bg-base-100"
        >
          <IconButton
            className={'h-6 w-6 hover:bg-base-100'}
            onClick={() => setIsOpen(false)}
          >
            <LazySvg name={iconName} />
          </IconButton>
        </StyledMenubarTrigger>
        <StyledMenubarContent {...restPoprs}>
          {children}
        </StyledMenubarContent>
      </StyledMenubarMenu>
    </StyledMenuBar>
  )
}
