import { Actions } from '@/entities/document/types'
import { IconButton, LazySvg, Typography } from '@/shared/ui'
import { CaseCard } from '@/widgets/CourtBase/CaseCard'
import { LeftMenu } from '@/widgets/CourtBase/LeftMenu'
import {
  CourtBaseAssignedDocsMobile,
  CourtBaseByIdItemMobile,
} from '@/widgets/CourtBaseById'
import { ActionsMenu } from '@/widgets/Document/ActionsMenu'
import { useOutletContext } from 'react-router-dom'

import { courtBaseIdData } from './consts'

export const CourtBaseByIdPage = () => {
  const { isMobile } = useOutletContext<{
    isMobile: boolean
  }>()

  if (isMobile) {
    return (
      <div className="w-full px-2 pb-[118px]">
        <CourtBaseByIdItemMobile mainInfo={courtBaseIdData.data} />
        <CourtBaseAssignedDocsMobile
          assignedDocs={courtBaseIdData.assignedDocs}
        />
      </div>
    )
  }

  return (
    <div className="flex w-full justify-between gap-6">
      <div>
        <LeftMenu />
      </div>

      <div className="flex w-full justify-center">
        <div className="flex w-full max-w-[1280px] flex-col gap-8">
          <div className="flex flex-col gap-2.5">
            <div className="flex gap-4 rounded-lg border border-stroke-light-gray-2 p-4">
              <CaseCard item={{ id: 1 }} />
              <div className="flex flex-col gap-4">
                <IconButton>
                  <LazySvg name="icon_folder_plus" />
                </IconButton>
                <IconButton>
                  <LazySvg name="icon_bookmark_black" />
                </IconButton>
              </div>
            </div>

            <div>
              <IconButton className="flex items-center gap-2 border border-stroke-light-gray-2 px-4 py-1">
                <LazySvg
                  name="icon_ai_filled_dark"
                  className="h-5 w-5"
                />
                <Typography>AI-аннотация</Typography>
              </IconButton>
            </div>
          </div>

          <div className="flex flex-col gap-3">
            <div className="flex justify-between">
              <div className="flex items-center gap-2.5">
                <IconButton className="h-6 w-6 border border-stroke-light-gray-2">
                  <LazySvg
                    name="icon_chevron_left"
                    className="h-4 w-4"
                  />
                </IconButton>
                <Typography
                  className="rounded border border-stroke-light-gray-2 bg-bg-light-gray-1 px-1
                    text-sm"
                >
                  1/2
                </Typography>
                <Typography className="text-sm">
                  Просмотр документа за 11.12.2024
                </Typography>
                <IconButton className="h-6 w-6 border border-stroke-light-gray-2">
                  <LazySvg
                    name="icon_chevron_right"
                    className="h-4 w-4"
                  />
                </IconButton>
              </div>

              <ActionsMenu
                actions={[Actions.Magnify, Actions.Download]}
                documentId={1}
                wrapper={(children) => (
                  <div className="border-stroke-light-gray flex gap-3 rounded-lg border p-0.5">
                    {children}
                  </div>
                )}
                itemWrapper={(children) => (
                  <div className="h-7 w-7">{children}</div>
                )}
              />
            </div>

            <div>Документ</div>
          </div>
        </div>
      </div>
    </div>
  )
}
