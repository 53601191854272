import { DocumentItemSkeleton } from '@/entities/document'
import { useDocuments } from '@/entities/document'
import IconNotFound from '@/shared/assets/icons/logo_not_found.svg?react'
import { Typography } from '@/shared/ui'
import { CustomPagination } from '@/widgets/CustomPagination'
import { DocumentInfoDialog } from '@/widgets/Document/DocumentInfoDialog'

import { DocumentBaseItem } from '../DocumentBaseItem'

export const DocumentBaseContainer = () => {
  const { data, error, isLoading, isFetching, currentPage } =
    useDocuments()

  if (error) {
    return (
      <div
        className="flex flex-1 flex-col items-stretch justify-start rounded-b-3xl
          border-x-[1px] border-b-[1px] border-t-0 bg-transparent py-20"
        style={{
          boxShadow: '0px 8px 32px 0px rgba(0, 0, 0, 0.04)',
        }}
      >
        <div className="my-20 flex flex-col items-stretch justify-center gap-8">
          <IconNotFound className="self-center drop-shadow-lg" />
          <div className="flex flex-col items-center justify-center">
            <Typography>
              По запросу не найдено ни одного документа.
            </Typography>
            <Typography>
              Попробуйте написать запрос иначе и повторить поиск.
            </Typography>
          </div>
        </div>
      </div>
    )
  }

  if (isLoading || isFetching) {
    return (
      <div
        className="flex flex-1 flex-col items-stretch justify-start rounded-b-3xl
          border-x-[1px] border-b-[1px] border-t-0 bg-transparent"
        style={{
          boxShadow: '0px 8px 32px 0px rgba(0, 0, 0, 0.04)',
          overflow: 'visible',
        }}
      >
        {Array.from({ length: 12 }).map((_, index) => (
          <DocumentItemSkeleton
            key={index}
            isLastItem={index == 11}
          />
        ))}
      </div>
    )
  }

  return (
    <>
      <DocumentInfoDialog />
      <div
        className="mb-6 flex flex-1 flex-col items-stretch justify-start overflow-visible
          rounded-b-3xl border-x-[1px] border-b-[1px] border-t-0
          shadow-shadow-modal-light"
      >
        {data?.documents && data.documents.length > 0 ? (
          <>
            <div className="flex-1">
              {data.documents.map((item, index) => {
                const isLastItem = index === data.documents.length - 1
                return (
                  <DocumentBaseItem
                    index={index}
                    key={item.id}
                    documentItem={item}
                    isLastDocumentItem={isLastItem}
                  />
                )
              })}
            </div>

            <CustomPagination
              currentPage={currentPage}
              totalPages={data.meta.last_page}
              perPage={data.meta.per_page}
              totalItems={data.meta.total}
            />
          </>
        ) : (
          <div
            className="flex shrink-0 grow flex-col items-center justify-center self-stretch
              py-20"
          >
            <div className="flex flex-col items-stretch justify-center gap-8">
              <IconNotFound className="self-center drop-shadow-lg" />
              <div className="flex flex-col items-center justify-center">
                <Typography>
                  По запросу не найдено ни одного документа.
                </Typography>
                <Typography>
                  Попробуйте написать запрос иначе и повторить поиск.
                </Typography>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  )
}
