export const hasRTKQMeta = (
  action: any
): action is {
  meta: {
    baseQueryMeta: {
      request: Request
      response?: Response
    }
  }
} => {
  return action?.meta?.baseQueryMeta?.request instanceof Request
}
