import {
  FavoriteChatMessages,
  useFavoriteMessages,
} from '@/entities/chat'
import { cn } from '@/shared/utils/common'

import { useSidebar } from '../../Sidebar'
import { ChatContainerSkeleton } from '../ChatContainer/ChatContainerSkeleton'

export const FavoriteChatContainer = () => {
  const { isSidebarOpen } = useSidebar()
  const { messages, isLoading, isFetching, scrollContainerRef } =
    useFavoriteMessages()

  if (!messages) return null
  if (!isFetching && !isLoading) {
    return (
      <div className="relative flex min-h-full w-full flex-col">
        <div
          ref={scrollContainerRef}
          className="flex-grow overflow-y-auto"
        >
          <div
            className={cn(
              isSidebarOpen ? 'lg:min-w-[672px]' : 'lg:min-w-[768px]',
              `relative mx-auto flex w-full min-w-[672px] flex-col items-stretch
              justify-start gap-8 self-center bg-[#FCFCFC] pb-16 pt-24
              lg:max-w-[850px]`
            )}
          >
            <FavoriteChatMessages messages={messages} />
          </div>
        </div>
      </div>
    )
  }
  return <ChatContainerSkeleton isSidebarOpen={isSidebarOpen} />
}
