import { useFilters } from '@/entities/filter/models/useFilters'
import { CategoriesPage } from '@/entities/filter/types'
import { SearchForm } from '@/features/courtBase/ui/SearchForm'
import { MobileDocumentsSearchInput } from '@/features/filters'
import useMediaQuery from '@/shared/hooks/useMediaQuery'
import { cn } from '@/shared/utils/common'
import { CaseList } from '@/widgets/CourtBase/CaseList'
import { CourtBaseBodyContainerMobile } from '@/widgets/CourtBase/CourtBaseBodyContainerMobile'
import { WidgetCourtBaseFiltersMobile } from '@/widgets/CourtBase/WidgetCourtBaseFiltersMobile'

export const CourtBasePage = () => {
  const isMobile = useMediaQuery('(max-width: 960px)')

  const { handleMenuToggle, isMenuOpen, categoriesData } = useFilters(
    {
      categoriesPage: 'court-base' as CategoriesPage,
    }
  )

  const caseList = [
    { id: 1 },
    { id: 2 },
    { id: 3 },
    { id: 4 },
    { id: 5 },
    { id: 6 },
    { id: 7 },
    { id: 8 },
  ]

  if (isMobile) {
    return (
      <div
        className={cn(
          `flex h-full flex-col items-stretch justify-start overflow-y-auto
          overflow-x-hidden px-5 pt-[64px]`
        )}
      >
        <div className="mb-[10px] flex justify-center self-stretch">
          <MobileDocumentsSearchInput />
        </div>
        <WidgetCourtBaseFiltersMobile />
        {/* Divider */}
        <div className="-mx-5 mb-2 h-2 shrink grow-0 bg-[#F0F0F0]" />
        {/* Body container */}
        <CourtBaseBodyContainerMobile />
      </div>
    )
  }

  return (
    <div className="flex w-full justify-center">
      <div className="flex w-[1280px] flex-col gap-6">
        <SearchForm />

        <div className="rounded-3xl border-[1px] border-stroke-light-gray-1 bg-white">
          <div className="flex justify-between p-3">
            <div className="text-sm text-text-light-black-4">
              Всего документов: {caseList.length}
            </div>
            <div>Кнопки</div>
          </div>
          <CaseList caseList={caseList} sortOrder="asc" />
        </div>
      </div>
    </div>
  )
}
