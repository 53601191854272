import { userApi } from '@/shared/api/user'
import store from '@/shared/store'
import Cookies from 'js-cookie'
import { redirect } from 'react-router-dom'

export const userLoader = async () => {
  const token = Cookies.get('access_token')
  if (!token) {
    return null
  }
  try {
    const result = await store
      .dispatch(userApi.endpoints.getUser.initiate())
      .unwrap()

    if (result.success) {
      return { user: result.user }
    }
    return null
  } catch (error: any) {
    if (error?.status === 401) {
      return redirect('/signin')
    }
    return redirect('/signin') // TODO: Логика таких кейсов должна быть обсуждена
  }
}
