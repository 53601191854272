import { IFileChunk } from '@/entities/chat'
import IconChevronLeft from '@/shared/assets/icons/icon_chevron_left.svg?react'
import IconChevronRight from '@/shared/assets/icons/icon_chevron_right.svg?react'
import IconClose from '@/shared/assets/icons/icon_close.svg?react'
import { IconButton, Typography } from '@/shared/ui'
import { AnimatePresence, motion } from 'framer-motion'

import { useDocumentChunks } from '../../models/useDocumentChunks'

type ChunksPanelProps = {
  chunks: IFileChunk[]
}

export const ChunksPanel = ({ chunks }: ChunksPanelProps) => {
  const {
    isOpen,
    handleClose,
    chunksLength,
    selectedChunk,
    handlePrevChunk,
    handleNextChunk,
    currentChunkIndex,
  } = useDocumentChunks(chunks)

  return (
    <AnimatePresence>
      {isOpen && (
        <motion.div
          initial={{ opacity: 0, y: -80 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: -80 }}
          transition={{ duration: 0.7, ease: 'easeOut' }}
          className="flex items-center gap-2 rounded-xl border border-stroke-light-gray-2
            bg-bg-light-white-1 p-2 pr-[9px]"
        >
          <div className="flex items-center gap-4 border-r border-stroke-light-gray-2 pr-2">
            <IconButton
              onClick={handlePrevChunk}
              className="flex h-7 w-7 items-center justify-center gap-2 rounded-lg border
                border-stroke-light-gray-2 bg-bg-light-white-1 p-2"
              style={{
                boxShadow:
                  '0px 6px 2px 0px rgba(0, 0, 0, 0.00), 0px 4px 1px 0px rgba(0, 0, 0, 0.00), 0px 2px 1px 0px rgba(0, 0, 0, 0.02), 0px 1px 1px 0px rgba(0, 0, 0, 0.03)',
              }}
            >
              <IconChevronLeft className="min-h-4 min-w-4" />
            </IconButton>
            <div
              className="flex items-center gap-2 rounded-lg border border-stroke-light-gray-2
                bg-bg-light-white-1 p-1 pr-2"
              style={{
                boxShadow:
                  '0px 6px 2px 0px rgba(0, 0, 0, 0.00), 0px 4px 1px 0px rgba(0, 0, 0, 0.00), 0px 2px 1px 0px rgba(0, 0, 0, 0.02), 0px 1px 1px 0px rgba(0, 0, 0, 0.03)',
              }}
            >
              <div
                className="flex items-center justify-center gap-[10px] rounded-[4px] border
                  border-stroke-light-gray-2 bg-bg-light-gray-2 px-1 text-[14px]
                  font-medium leading-[20px]"
              >
                {currentChunkIndex}/{chunksLength}
              </div>
              <Typography variant={'label'} className="font-medium">
                Совпадение{' '}
                {selectedChunk?.meta.similarity_score
                  ? Number(
                      selectedChunk?.meta.similarity_score.toFixed(2)
                    ) * 100
                  : 0}
                %
              </Typography>
            </div>
            <IconButton
              onClick={handleNextChunk}
              className="flex h-7 w-7 items-center justify-center gap-2 rounded-lg border
                border-stroke-light-gray-2 bg-bg-light-white-1 p-2"
              style={{
                boxShadow:
                  '0px 6px 2px 0px rgba(0, 0, 0, 0.00), 0px 4px 1px 0px rgba(0, 0, 0, 0.00), 0px 2px 1px 0px rgba(0, 0, 0, 0.02), 0px 1px 1px 0px rgba(0, 0, 0, 0.03)',
              }}
            >
              <IconChevronRight className="min-h-4 min-w-4" />
            </IconButton>
          </div>
          <IconButton onClick={handleClose}>
            <IconClose className="h-5 w-5 [&_path]:fill-[#67707E]" />
          </IconButton>
        </motion.div>
      )}
    </AnimatePresence>
  )
}
