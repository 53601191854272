import { useChatMessages } from '@/entities/chat'
import IconArrowScroll from '@/shared/assets/icons/icon_arrow_scroll.svg?react'
import { ButtonScrollToAnchor } from '@/shared/ui/ButtonScrollToAnchor'
import { ErrorNotification } from '@/shared/ui/ErrorNotification'
import { cn } from '@/shared/utils/common'

import { useChatMobile } from '../../../entities/chat/models/useChatMobile'
import { ChatMessages } from '../../../entities/chat/ui/ChatMessages'
import { MobileChatInput } from '../../../entities/chat/ui/MobileChatInput'
import { ChatContainerSkeleton } from '../ChatContainer/ChatContainerSkeleton'

type ChatContainerMobileProps = {
  chatId?: string
  userId: number
  isSidebarOpen: boolean
}

export const ChatContainerMobile = ({
  userId,
  chatId,
  isSidebarOpen,
}: ChatContainerMobileProps) => {
  const {
    data,
    isFetching,
    isLoading,
    error,
    typedText,
    scrollContainerRef,
    isAwaitingResponse,
  } = useChatMessages(chatId!, userId)

  const { bottomRef, isBottomVisible } = useChatMobile({
    messages: data?.messages ?? [],
  })

  if (!data?.messages) return null

  return (
    <>
      {error && (
        <ErrorNotification
          offset={28}
          position="bottom"
          className="shadow-shadow7"
          actionLabel="Перезагрузить"
          message="Не удалось загрузить диалог"
          onActionClick={() => window.location.reload()}
          description="Перезагрузите страницу, чтобы все заработало"
        />
      )}

      <div
        id="chat_messages_container"
        className={cn(
          `relative flex min-h-full flex-1 flex-col items-stretch justify-start
          gap-8`,
          'pb-[152px] md:max-w-[728px]'
        )}
      >
        {isFetching || isLoading ? (
          <ChatContainerSkeleton
            isSidebarOpen={isSidebarOpen}
            isMobile
          />
        ) : (
          <>
            <div
              ref={scrollContainerRef}
              className="chat_scrollable -mb-5 flex-1 overflow-y-auto px-3"
            >
              <ChatMessages
                typedText={typedText}
                messages={data.messages}
                isAwaitingResponse={isAwaitingResponse}
              />
              <div ref={bottomRef} />
            </div>

            {!isBottomVisible && (
              <ButtonScrollToAnchor
                className="fixed bottom-[168px] left-1/2 -translate-x-1/2 rounded-full border
                  border-base-200 bg-white p-1"
                style={{
                  boxShadow:
                    '0px 18px 5px 0px rgba(0, 0, 0, 0.00), 0px 12px 5px 0px rgba(0, 0, 0, 0.00), 0px 7px 4px 0px rgba(0, 0, 0, 0.02), 0px 3px 3px 0px rgba(0, 0, 0, 0.03), 0px 1px 2px 0px rgba(0, 0, 0, 0.03)',
                }}
                targetRef={bottomRef}
              >
                <IconArrowScroll />
              </ButtonScrollToAnchor>
            )}

            <MobileChatInput />
          </>
        )}
      </div>
    </>
  )
}
