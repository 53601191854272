import { IChatAiFile } from '@/entities/chat/types'
import { ChatAiFileDrawerMenu } from '@/entities/chat/ui/ChatAiFileDrawerMenu'
import { useDocumentChunks } from '@/entities/document'
import { ChunksPanel } from '@/entities/document/ui/ChunksPanel'
import IconChevronLeft from '@/shared/assets/icons/icon_chevron_left.svg?react'
import IconChevronRight from '@/shared/assets/icons/icon_chevron_right.svg?react'
import IconClose from '@/shared/assets/icons/icon_close.svg?react'
import IconExport from '@/shared/assets/icons/icon_export.svg?react'
import IconMoreDots from '@/shared/assets/icons/icon_more_dots_dark.svg?react'
import { useTypedSelector } from '@/shared/store'
import { IconButton, Typography } from '@/shared/ui'
import { cn } from '@/shared/utils/common'
import { useEffect } from 'react'

import { useDocumentPanel } from '../../modal/useDocumentPanel'
import { DocumentPanelSkeleton } from '../DocumentPanelSkeleton'
import { RawHtmlRenderer } from '../RawHtmlRenderer'

interface DocumentPanelsProps {
  documentData: IChatAiFile
  isVisibleThreePanel: boolean
  index: number
  isDragging: boolean
}

export const DocumentPanel = ({
  documentData,
  isVisibleThreePanel,
  index,
  isDragging,
}: DocumentPanelsProps) => {
  const {
    data,
    isLoading,
    isFetching,
    handleScroll,
    handleInfoClick,
    scrollContainerRef,
    handleRemoveDocument,
    isInfoDialogOpen,
    handleToggleFavoriteClick,
    handleChangePanelLanguage,
  } = useDocumentPanel(
    documentData.id,
    isVisibleThreePanel,
    isDragging,
    index
  )

  const documents = useTypedSelector(
    (state) => state.documentPreview.documents
  )

  useEffect(() => {
    // Clear all previous outlines
    const allParagraphs =
      document.querySelectorAll<HTMLElement>('[data-docindex]')
    allParagraphs.forEach((el) => {
      el.style.outline = 'none'
    })

    documents.forEach((doc) => {
      doc.chunks.forEach((chunk) => {
        chunk.meta.paragraphs.forEach((paragraph) => {
          // Find all elements whose innerText matches paragraph
          const matchingEls = Array.from(
            document.querySelectorAll<HTMLElement>('[data-docindex]')
          ).filter(
            (el) =>
              el.getAttribute('data-docindex') === paragraph.trim()
          )

          matchingEls.forEach((el) => {
            el.style.backgroundColor = 'rgba(255, 255, 0, 0.5)'
            // el.style.outline = '2px solid #FFD700'
            // need darker yellow for outline
          })
        })
      })
    })
  }, [documents])

  return (
    <div
      className={cn(
        `relative flex h-full shrink-0 flex-col items-center rounded-t-xl
        border-[1px] border-base-100 pb-2`
      )}
    >
      <div
        className="flex min-h-[40px] items-start justify-between gap-8 self-stretch
          border-b-[1px] border-base-100 py-2 pl-3 pr-[6px]"
      >
        <div className="flex items-center justify-between gap-8">
          <ChatAiFileDrawerMenu
            data={data?.document}
            onToggleFavoriteClick={() =>
              handleToggleFavoriteClick(documentData.id)
            }
            onInfoClick={handleInfoClick}
            handleChangePanelLanguages={handleChangePanelLanguage}
          >
            <IconButton>
              <IconMoreDots />
            </IconButton>
          </ChatAiFileDrawerMenu>
          <IconButton>
            <IconExport width={'22px'} height={'22px'} />
          </IconButton>
        </div>

        <Typography
          variant={'label'}
          className={cn(
            'truncate p-[2px] transition-[all] duration-700 ease-in-out',
            isVisibleThreePanel ? 'opacity-100' : 'opacity-0'
          )}
          title={documentData.name}
        >
          {documentData.name}
        </Typography>

        <div className="flex items-center justify-between gap-8">
          <IconButton
            onClick={() =>
              handleRemoveDocument(documentData.id, index)
            }
          >
            <IconClose width="24px" height="24px" />
          </IconButton>
        </div>
      </div>

      <div
        className={cn(
          `custom-scrollbar flex flex-col items-center gap-4 self-stretch
          overflow-y-auto px-[40px] py-6`,
          isDragging && 'blur-sm'
        )}
        onScroll={handleScroll}
        ref={scrollContainerRef}
        id={'document-panel-' + index}
      >
        <ChunksPanel chunks={documentData?.chunks ?? []} />
        {isDragging ? (
          <DocumentPanelSkeleton />
        ) : !isLoading &&
          !isFetching &&
          data &&
          isVisibleThreePanel ? (
          <RawHtmlRenderer rawHtml={data.document.content} />
        ) : (
          <DocumentPanelSkeleton />
        )}
      </div>
    </div>
  )
}
