import IconCommentsDark from '@/shared/assets/icons/icon_comments_dark.svg?react'
import IconEyeDarkSmallClosed from '@/shared/assets/icons/icon_eye_small_closed.svg?react'
import IconEyeDarkSmall from '@/shared/assets/icons/icon_eye_small_dark.svg?react'
import { IconButton } from '@/shared/ui'
import { cn } from '@/shared/utils/common'
import { FC } from 'react'

import { useShowDocumentChunks } from '../../hooks/useShowDocumentChunks'

export interface ChunksProps {}

export const ActionShowDocumentChunks: FC<ChunksProps> = () => {
  const { isShowMainDocumentChunks, toggleShowMainDocumentChunks } =
    useShowDocumentChunks()

  return (
    <IconButton
      className={cn(
        'relative p-1 hover:bg-base-100',
        isShowMainDocumentChunks && 'bg-base-100'
      )}
      onClick={toggleShowMainDocumentChunks}
    >
      <IconCommentsDark width="100%" height="100%" />
      {isShowMainDocumentChunks ? (
        <IconEyeDarkSmallClosed className="absolute left-[18px] top-[18px]" />
      ) : (
        <IconEyeDarkSmall className="absolute left-[18px] top-[18px]" />
      )}
    </IconButton>
  )
}
