import { createSlice, PayloadAction } from '@reduxjs/toolkit'

interface ChatState {
  error: string
  isResponding: boolean
  isAwaitingResponse: boolean
  isBeingRenamed?: boolean
  newTitle?: string
}

interface ChatAwaitingResponseState {
  chatsData: Record<string, ChatState>
  returnUrl: string | null // Add returnUrl to the state
  isDeleteChatDialogOpen: boolean
  chatIdToDelete: string | null
}

const initialState: ChatAwaitingResponseState = {
  chatsData: {},
  returnUrl: null,
  isDeleteChatDialogOpen: false,
  chatIdToDelete: null,
}

const chatSlice = createSlice({
  name: 'chatAwaitingResponse',
  initialState,
  reducers: {
    setChatAwaitingResponse: (
      state,
      action: PayloadAction<{ chatId: string }>
    ) => {
      const { chatId } = action.payload
      if (!state.chatsData[chatId]) {
        state.chatsData[chatId] = {
          error: '',
          isResponding: false,
          isAwaitingResponse: true,
        }
      } else {
        state.chatsData[chatId].isAwaitingResponse = true
      }
    },
    clearChatAwaitingResponse: (
      state,
      action: PayloadAction<{ chatId: string }>
    ) => {
      const { chatId } = action.payload
      if (!state.chatsData[chatId]) {
        state.chatsData[chatId] = {
          error: '',
          isResponding: false,
          isAwaitingResponse: false,
        }
      } else {
        state.chatsData[chatId].isAwaitingResponse = false
      }
    },
    setChatError: (
      state,
      action: PayloadAction<{ chatId: string; error: string }>
    ) => {
      const { chatId, error } = action.payload
      state.chatsData[chatId] = {
        error,
        isResponding: false,
        isAwaitingResponse: false,
      }
    },
    clearChatError: (
      state,
      action: PayloadAction<{ chatId: string }>
    ) => {
      const { chatId } = action.payload
      if (state.chatsData[chatId]) {
        state.chatsData[chatId].error = ''
      }
    },
    setChatResponding: (
      state,
      action: PayloadAction<{ chatId: string }>
    ) => {
      const { chatId } = action.payload
      if (!state.chatsData[chatId]) {
        state.chatsData[chatId] = {
          error: '',
          isResponding: true,
          isAwaitingResponse: false,
        }
      } else {
        state.chatsData[chatId].isResponding = true
        state.chatsData[chatId].isAwaitingResponse = false
      }
    },
    clearChatResponding: (
      state,
      action: PayloadAction<{ chatId: string }>
    ) => {
      const { chatId } = action.payload
      if (state.chatsData[chatId]) {
        state.chatsData[chatId].isResponding = false
      }
    },
    setChatReturnUrl: (
      state,
      action: PayloadAction<{ url: string }>
    ) => {
      state.returnUrl = action.payload.url
    },
    clearChatReturnUrl: (state) => {
      state.returnUrl = null
    },
    openDeleteDialog: (state, action) => {
      state.isDeleteChatDialogOpen = true
      state.chatIdToDelete = action.payload.chatId
    },
    closeDeleteDialog: (state) => {
      state.isDeleteChatDialogOpen = false
      state.chatIdToDelete = null
    },
    openRenameChat: (
      state,
      action: PayloadAction<{ chatId: string; initialTitle: string }>
    ) => {
      const { chatId, initialTitle } = action.payload
      if (!state.chatsData[chatId]) {
        state.chatsData[chatId] = {
          error: '',
          isResponding: false,
          isAwaitingResponse: false,
          isBeingRenamed: true,
          newTitle: initialTitle,
        }
      } else {
        state.chatsData[chatId].isBeingRenamed = true
        state.chatsData[chatId].newTitle = initialTitle
      }
    },
    setRenameTitle: (
      state,
      action: PayloadAction<{ chatId: string; newTitle: string }>
    ) => {
      const { chatId, newTitle } = action.payload
      if (state.chatsData[chatId]) {
        state.chatsData[chatId].newTitle = newTitle
      }
    },
    closeRenameChat: (
      state,
      action: PayloadAction<{ chatId: string }>
    ) => {
      const { chatId } = action.payload
      if (state.chatsData[chatId]) {
        state.chatsData[chatId].isBeingRenamed = false
        state.chatsData[chatId].newTitle = undefined
      }
    },
  },
})

export const {
  setChatResponding, // While AI is responding
  clearChatResponding, // AI has responded

  setChatAwaitingResponse, // User has sent a message
  clearChatAwaitingResponse, // AI has responded

  setChatError, // AI has responded with an error
  clearChatError, // Clear the error

  setChatReturnUrl, // Set the returnUrl
  clearChatReturnUrl, // Clear the returnUrl

  openDeleteDialog, // Open the delete chat dialog
  closeDeleteDialog, // Close the delete chat dialog

  openRenameChat, // Open the rename chat input
  closeRenameChat, // Close the rename chat input
  setRenameTitle, // Set the new title for the chat
} = chatSlice.actions
export default chatSlice.reducer
