// useAiResponse.ts
import {
  chatApi,
  usePatchToggleFavoriteMessageMutation,
} from '@/shared/api/chat'
import { useAppDispatch } from '@/shared/store'
import { useToast } from '@/shared/ui/Toast/useToast'
import { useCallback } from 'react'
import { useSearchParams } from 'react-router-dom'

export const useAiResponse = (chat_id?: string) => {
  const dispatch = useAppDispatch()
  const [
    patchToggleFavoriteMessage,
    { isLoading: isToggleFavoritePending, originalArgs },
  ] = usePatchToggleFavoriteMessageMutation()
  const toggledMessageId = originalArgs?.id

  const [searchParams] = useSearchParams({})
  const page = Number(searchParams.get('page')) || 1
  const { toast } = useToast()

  const handleToggleFavorite = useCallback(
    async (message_id?: string) => {
      if (chat_id && message_id)
        try {
          await patchToggleFavoriteMessage({
            chat_id,
            id: message_id,
          })

          dispatch(
            chatApi.util.updateQueryData(
              'getChatById',
              chat_id,
              (draft) => {
                if (draft && draft.messages) {
                  const exists = draft.messages.find(
                    (msg) => msg.id === Number(message_id)
                  )
                  if (!exists) {
                    draft.messages[Number(message_id)].is_favorite =
                      !draft.messages[Number(message_id)].is_favorite
                  }
                }
              }
            )
          )
          dispatch(
            chatApi.util.updateQueryData(
              'getListOfFavorites',
              { page },
              (draft) => {
                if (draft && draft.messages) {
                  const exists = draft.messages.find(
                    (msg) => msg.id === Number(message_id)
                  )
                  if (exists) {
                    draft.messages = draft.messages.filter(
                      (msg) => msg.id !== Number(message_id)
                    )
                  }
                }
              }
            )
          )
          // chatApi.util.invalidateTags([{ type: 'Chat', id: 'LIST' }])
        } catch (error) {
          toast({
            variant: 'error',
            title: 'Произошла ошибка',
            description: 'Не удалось изменить статус сообщения',
          })
        }
    },
    [chat_id, patchToggleFavoriteMessage, dispatch, page, toast]
  )

  return {
    handleToggleFavorite,
    isToggleFavoritePending,
    toggledMessageId,
  }
}
