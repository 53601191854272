import { IChatHistoryItem } from '@/entities/chat/types'
import useMediaQuery from '@/shared/hooks/useMediaQuery'
import { Skeleton } from '@/shared/ui'
import { Typography } from '@/shared/ui/Typography'
import { cn } from '@/shared/utils/common'

import { ChatHistoryItem } from '../ChatHistoryItem'

type Props = {
  isLoading: boolean
  todayChats: IChatHistoryItem[]
  yesterdayChats: IChatHistoryItem[]
  otherChatsByDate: Record<string, IChatHistoryItem[]>
}

export const ChatHistory = ({
  isLoading,
  todayChats,
  yesterdayChats,
  otherChatsByDate,
}: Props) => {
  const isMobile = useMediaQuery('(max-width: 960px)')

  if (isLoading) {
    return <ChatHistorySkeleton />
  }

  // Check if there are any chats left
  const hasTodayChats = todayChats.length > 0
  const hasYesterdayChats = yesterdayChats.length > 0
  const hasOtherChats = Object.keys(otherChatsByDate).length > 0

  return (
    <div
      className={cn(
        'flex max-h-full shrink grow flex-col items-start gap-2 self-stretch',
        'overflow-y-auto px-3 pt-4',
        isMobile ? '-mx-6 pb-4' : 'pb-20'
      )}
    >
      {hasTodayChats && (
        <>
          <ChatHistoryList label="Сегодня" items={todayChats} />
          {hasYesterdayChats || hasOtherChats ? (
            <div className="mt-2 min-h-[1px] w-full bg-stroke-light-gray-1" />
          ) : null}
        </>
      )}

      {hasYesterdayChats && (
        <>
          <ChatHistoryList label="Вчера" items={yesterdayChats} />
          {hasOtherChats ? (
            <div className="mt-2 min-h-[1px] w-full bg-stroke-light-gray-1" />
          ) : null}
        </>
      )}

      {Object.entries(otherChatsByDate).map(
        ([date, items], index, array) => (
          <div key={date} className="self-stretch">
            <ChatHistoryList
              label={new Date(date).toLocaleDateString('ru-RU')}
              items={items}
            />
            {index !== array.length - 1 ? (
              <div className="mt-2 min-h-[1px] w-full bg-stroke-light-gray-1" />
            ) : null}
          </div>
        )
      )}
    </div>
  )
}

interface ChatHistoryListProps {
  label: string
  items: IChatHistoryItem[]
}

export const ChatHistoryList = ({
  label,
  items,
}: ChatHistoryListProps) => {
  return (
    <div className="flex flex-col items-start gap-[6px] self-stretch">
      <Typography
        variant={'subtitle'}
        className="w-full px-3 py-[6px] font-semibold text-base-700"
      >
        {label}
      </Typography>
      <div className="flex flex-col items-start self-stretch">
        {items.map((item) => (
          <ChatHistoryItem key={item.id} item={item} />
        ))}
      </div>
    </div>
  )
}
const ChatHistorySkeleton = () => {
  const isMobile = useMediaQuery('(max-width: 960px)')

  return (
    <div
      className={cn(
        'flex max-h-full shrink grow flex-col items-start gap-4 self-stretch',
        'overflow-y-auto px-4 pt-2',
        isMobile ? '-mx-6 pb-4' : 'pb-[160px]'
      )}
    >
      {[...Array(4)].map((_, index) => (
        <div
          key={index}
          className="flex flex-col items-start gap-2 self-stretch"
        >
          <Skeleton className="h-4 w-14 bg-base-200"></Skeleton>
          {[...Array(index === 0 ? 3 : 4)].map((_, subIndex) => (
            <Skeleton
              key={subIndex}
              className="relative flex h-6 w-full flex-col items-start gap-2 self-stretch
                bg-base-300"
            />
          ))}
        </div>
      ))}
    </div>
  )
}
