// DocumentsBaseLayout.tsx
import { useFilters } from '@/entities/filter/models/useFilters'
import { CategoriesPage } from '@/entities/filter/types'
import {
  DocumentsSearchInput,
  MobileDocumentsSearchInput,
} from '@/features/filters/DocumentsSearchInput'
import useMediaQuery from '@/shared/hooks/useMediaQuery'
import { cn } from '@/shared/utils/common'
import { DocumentFiltersMobile } from '@/widgets/DocumentsBase/DocumentFiltersMobile'
import { WidgetCategories } from '@/widgets/WidgetCategories'
import { WidgetDocumentFilters } from '@/widgets/WidgetDocumentFilters'
import { useCallback, useEffect, useRef, useState } from 'react'
import { Outlet } from 'react-router-dom'

export const DocumentsBaseLayout = () => {
  const isMobile = useMediaQuery('(max-width: 960px)')

  const { handleMenuToggle, isMenuOpen, categoriesData } = useFilters(
    { categoriesPage: 'document' as CategoriesPage }
  )
  const filtersRef = useRef<HTMLDivElement>(null)
  const [filtersHeight, setFiltersHeight] = useState(0)

  // Track the height of the filters container
  const updateFiltersHeight = useCallback(() => {
    if (filtersRef.current) {
      setFiltersHeight(filtersRef.current.clientHeight)
    }
  }, [])

  // Use ResizeObserver to track changes in filtersRef height
  useEffect(() => {
    const observer = new ResizeObserver(() => {
      updateFiltersHeight()
    })

    if (filtersRef.current) {
      observer.observe(filtersRef.current)
    }

    return () => {
      if (filtersRef.current) {
        observer.unobserve(filtersRef.current)
      }
    }
  }, [updateFiltersHeight])

  if (isMobile)
    return (
      <div
        // id="documents_container"
        className={cn(
          `flex flex-col items-stretch justify-start overflow-y-auto
          overflow-x-hidden px-5 pb-[88px] pt-[46px]`
        )}
      >
        <>
          <div className="mb-5 flex justify-center self-stretch">
            <MobileDocumentsSearchInput />
          </div>
          <DocumentFiltersMobile />
          <div className="-mx-5 mb-2 h-2 shrink grow-0 bg-[#F0F0F0]" />
        </>

        {/* Body container */}
        <Outlet />
      </div>
    )

  return (
    <div className="mt-[72px] h-[calc(100svh-72px)] px-6">
      {/* Documents */}
      <div
        className={cn(
          `relative flex h-full w-full flex-wrap items-stretch justify-start
          gap-3`
        )}
      >
        {/* Categories */}
        <WidgetCategories
          categoriesData={categoriesData}
          handleMenuToggle={handleMenuToggle}
          isMenuOpen={isMenuOpen}
          pageTitle="document"
        />

        {/* Contents */}
        <div
          className={cn(
            'relative h-full max-h-full w-full min-w-0 flex-1 rounded-t-3xl'
          )}
        >
          {/* Sticky Filters */}
          <div
            ref={filtersRef}
            className={cn(
              `sticky -top-3 z-[50] -mt-3 w-full bg-[#FAFAFA] pt-3 transition-all
              duration-300 ease-in-out`
            )}
          >
            <div className="rounded-t-3xl border-x-[1px] border-t-[1px] bg-white pt-1">
              <DocumentsSearchInput />
              <WidgetDocumentFilters />
            </div>
          </div>
          <div
            id="documents_container"
            className="overflow-y-auto"
            style={{
              height: `calc(100% - ${filtersHeight}px + 12px)`,
            }}
          >
            <Outlet />
          </div>
        </div>
      </div>
    </div>
  )
}
