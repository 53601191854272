import { Accordion, Skeleton } from '@/shared/ui'

import { useDocumentChapters } from '../../hooks/useDocumentChapters'
import { ChaptersItem } from '../ChaptersItem'

export const Chapters = () => {
  const {
    isLoadingChapters,
    isFetchingChapters,
    chaptersData,
    selectedAnchorId,
    handleDocumentChapterClick,
  } = useDocumentChapters()

  if (isLoadingChapters || isFetchingChapters) {
    return (
      <>
        {Array.from({ length: 6 }).map((_, index) => (
          <Skeleton
            key={index}
            className="w-[calc(100% - 32px)] mx-4 my-3 h-[24px] bg-base-200"
          />
        ))}
      </>
    )
  }

  return (
    <Accordion
      collapsible
      type="single"
      orientation="vertical"
      className="max-h-full min-h-0 w-full overflow-y-auto overflow-x-hidden"
    >
      {chaptersData?.map((chapter) => (
        <ChaptersItem
          key={chapter.paragraph_id}
          chapter={chapter}
          selectedAnchorId={selectedAnchorId}
          handleDocumentChapterClick={handleDocumentChapterClick}
        />
      ))}
    </Accordion>
  )
}
