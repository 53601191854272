import { useChatRename } from '@/features/chat/models/useChatRename'
import { useAppDispatch } from '@/shared/store'
import { closeRenameChat } from '@/shared/store/slices/chat/chatSlice'
import { cn } from '@/shared/utils/common'

interface ChatRenameInputProps {
  chatId: string
  initialText: string
}

export const ChatRenameInput = ({
  chatId,
  initialText,
}: ChatRenameInputProps) => {
  const dispatch = useAppDispatch()

  const { newTitle, updateNewTitle, handleRenameChat, inputRef } =
    useChatRename(chatId, initialText)

  return (
    <input
      ref={inputRef}
      onKeyDown={(e) => {
        if (e.key === 'Enter') {
          handleRenameChat()
        } else if (e.key === 'Escape') {
          dispatch(closeRenameChat({ chatId }))
        }
      }}
      value={newTitle}
      tabIndex={0}
      autoFocus
      onFocus={(e) => {
        e.stopPropagation()
      }}
      onBlur={(e) => {
        dispatch(closeRenameChat({ chatId }))
      }}
      onChange={(e) => updateNewTitle(e.target.value)}
      className={cn(
        `w-full bg-inherit py-[2px] text-[14px] font-normal leading-[20px]
        text-base-950`,
        `outline-none focus:border focus:border-additional-light-blue-2
        focus:ring-0`
      )}
    />
  )
}
