import { Typography } from '@/shared/ui/Typography'
import { cn } from '@/shared/utils/common'
import { DocumentBaseItemMenuActions } from '@/widgets/DocumentsBase/DocumentBaseItemMenuActions'
import { forwardRef, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'

import { useDocumentActions } from '../../../entities/document/models/useDocumentActions'
import {
  DocumentBaseMenuItem,
  EDocumentStatus,
  IDocument,
} from '../../../entities/document/types'
import { DocumentItemStatus } from '../../../entities/document/ui/DocumentItemStatus'

interface DocumentItemProps {
  index?: number
  documentItem: IDocument
  isIndexed?: boolean
  isLastDocumentItem: boolean
}

// Main DocumentBaseItem component
export const DocumentBaseItem = forwardRef<
  HTMLDivElement,
  DocumentItemProps
>(
  (
    { index, documentItem, isLastDocumentItem, isIndexed = true },
    ref
  ) => {
    const [searchParams] = useSearchParams()
    const navigate = useNavigate()
    const language = searchParams.get('language')

    return (
      <>
        {/* Body Document item */}
        <div
          className={cn(
            isLastDocumentItem ? '' : 'border-b-[1px]',
            'relative bg-white px-4 py-3'
          )}
          style={{
            boxShadow:
              '0px 6px 2px 0px rgba(0, 0, 0, 0.00), 0px 4px 1px 0px rgba(0, 0, 0, 0.00), 0px 2px 1px 0px rgba(0, 0, 0, 0.02), 0px 1px 1px 0px rgba(0, 0, 0, 0.03)',
          }}
        >
          <div
            ref={ref}
            className="flex items-stretch justify-start gap-4"
          >
            {isIndexed && (
              <Typography variant={'body'} className="font-medium">
                {typeof index === 'number'
                  ? index + 1
                  : documentItem.id}
                .
              </Typography>
            )}
            <div
              className="mr-[44px] flex flex-1 cursor-pointer flex-col items-stretch
                justify-start gap-2"
              onClick={() =>
                navigate(
                  `/documents/${documentItem.id}?language=${language ? language : 'RU'}`
                )
              }
            >
              {documentItem.status ? (
                documentItem.status.code ===
                EDocumentStatus.ACTIVE_WITH_CHANGES ? (
                  <div className="flex items-center justify-start gap-2">
                    <DocumentItemStatus
                      label={'Действующий'}
                      status={EDocumentStatus.ACTIVE_WITH_CHANGES}
                    />
                    <DocumentItemStatus
                      label={documentItem.status.name}
                      status={documentItem.status.code}
                    />
                  </div>
                ) : (
                  <div className="flex items-center justify-start gap-2">
                    <DocumentItemStatus
                      label={documentItem.status.name}
                      status={documentItem.status.code}
                    />
                  </div>
                )
              ) : null}
              <Typography variant={'body'} className="font-medium">
                {documentItem.name}
              </Typography>
              <div className="-mt-1 flex flex-col items-stretch justify-start">
                {documentItem.requisites && (
                  <div className="flex items-center justify-start gap-3">
                    <Typography
                      variant={'label'}
                      className="font-medium text-text-light-black-5"
                    >
                      {documentItem.requisites}
                    </Typography>
                  </div>
                )}
              </div>
            </div>
            <div className="flex shrink grow-0 justify-start self-start">
              <DocumentBaseItemMenuActions
                documentItem={documentItem}
              />
            </div>
          </div>
        </div>
      </>
    )
  }
)

DocumentBaseItem.displayName = 'DocumentBaseItem'
