import { Skeleton } from '@/shared/ui/Skeleton'
import { cn } from '@/shared/utils/common'

const DocumentPanelSkeleton = () => (
  <div
    className={cn(
      `flex min-h-full w-full items-stretch justify-start gap-4
      rounded-[16px] border border-stroke-light-gray-1 bg-bg-light-white-1
      py-2 pb-[20px]`
    )}
  >
    <div
      className="flex shrink-0 grow basis-full flex-col items-stretch justify-start
        gap-4 self-stretch bg-white p-4"
    >
      <Skeleton className="mx-auto mb-4 h-[20px] w-[80%] bg-base-200" />

      <Skeleton className="mx-auto mb-6 h-[32px] w-[60%] bg-base-200" />

      <Skeleton className="mb-4 h-[24px] w-[40%] bg-base-200" />

      <Skeleton className="mb-2 h-[18px] w-full bg-base-200" />
      <Skeleton className="mb-2 h-[18px] w-[90%] bg-base-200" />
      <Skeleton className="mb-4 h-[18px] w-[85%] bg-base-200" />

      <Skeleton className="mx-auto mb-4 h-[24px] w-[40%] bg-base-200" />

      <Skeleton className="mb-2 h-[18px] w-full bg-base-200" />
      <Skeleton className="mb-2 h-[18px] w-[92%] bg-base-200" />
      <Skeleton className="mb-4 h-[18px] w-[88%] bg-base-200" />

      <Skeleton className="mb-4 h-[24px] w-[45%] bg-base-200" />
      <Skeleton className="mb-2 h-[18px] w-[70%] bg-base-200" />
      <Skeleton className="mb-2 h-[18px] w-[88%] bg-base-200" />
      <Skeleton className="mb-4 h-[18px] w-[92%] bg-base-200" />

      <Skeleton className="ml-auto mt-8 h-[26px] w-[30%] bg-base-200" />

      <Skeleton className="mb-2 mt-10 h-[18px] w-[92%] bg-base-200" />
      <Skeleton className="mb-2 h-[18px] w-[90%] bg-base-200" />
      <Skeleton className="mb-4 h-[18px] w-[91%] bg-base-200" />
      <Skeleton className="mb-4 h-[18px] w-[70%] bg-base-200" />
    </div>
  </div>
)

export { DocumentPanelSkeleton }
