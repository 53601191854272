import { useChangeVersions } from '@/entities/document'
import { RadioGroup, RadioGroupItem, Typography } from '@/shared/ui'
import { useEffect } from 'react'
import { useSearchParams } from 'react-router-dom'

export const VersionRadioGroup = () => {
  const [searchParams] = useSearchParams()
  const { updateSelectedVersion } = useChangeVersions()

  const selectedVersionId = searchParams.get('selected_version_id')
  const ids = searchParams.get('ids')?.split(',') || []

  return (
    <RadioGroup
      value={selectedVersionId || ''}
      onValueChange={(value) => updateSelectedVersion(+value)}
      className="flex gap-6"
    >
      {Object.entries({
        'Редакция 1': ids[0],
        'Редакция 2': ids[1],
      }).map(([value, label], index) => (
        <label
          key={value}
          className="flex h-6 w-1/2 items-center justify-start gap-3"
        >
          <RadioGroupItem
            disabled={ids.length < 2 && index === 1}
            value={String(label)}
            className="h-5 w-5"
          />
          <Typography variant="body" className="text-xs font-medium">
            {value}
          </Typography>
        </label>
      ))}
    </RadioGroup>
  )
}
