import { IChatMessage } from '@/entities/chat'
import { usePostNewChatMessageMutation } from '@/shared/api/chat'
import { useAppDispatch } from '@/shared/store'
import {
  clearChatError,
  setChatAwaitingResponse,
  setChatError,
} from '@/shared/store/slices/chat/chatSlice'

type UseResendChatMessageProps = {
  chatId: string
  messages: IChatMessage[] | undefined
}

export const useResendChatMessage = ({
  chatId,
  messages,
}: UseResendChatMessageProps) => {
  const dispatch = useAppDispatch()
  const [postNewChatMessage] = usePostNewChatMessageMutation()

  const handleRetry = async () => {
    if (!chatId || !messages) return

    // Find the last user message
    const lastUserMessage = [...messages]
      .reverse()
      .find((msg) => msg.from === 'USER')

    if (!lastUserMessage) return

    try {
      // Clear the chat error
      dispatch(clearChatError({ chatId }))
      dispatch(setChatAwaitingResponse({ chatId }))
      // Resend the last user message
      await postNewChatMessage({
        chatId: chatId,
        data: { message: lastUserMessage.message },
      }).unwrap()
    } catch (err: any) {
      const errorMessage =
        err.data?.message || 'Не удалось отправить сообщение'
      dispatch(setChatError({ chatId, error: errorMessage }))
    }
  }
  return { handleRetry }
}
