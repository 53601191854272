import { useState } from 'react'
import {
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom'

export const useDocumentOpenVersionsSelectAction = () => {
  const navigate = useNavigate()
  const [isOpenVersionSelect, setIsOpenVersionSelect] =
    useState(false)
  const [searchParams] = useSearchParams()
  const { id } = useParams()

  const versionIds = searchParams.get('ids')
  const toggleOpenVersionsSelect = () => setIsOpenVersionSelect(true)
  const toggleCloseVersionsSelect = () =>
    setIsOpenVersionSelect(false)

  const handleRedirect = () => {
    navigate(`/documents/${id}/versions`)
  }

  return {
    isOpenVersionSelect,
    toggleOpenVersionsSelect,
    toggleCloseVersionsSelect,
    handleRedirect,
  }
}
