import { useChatHistory } from '@/entities/chatHistory'
import IconBurgerGray from '@/shared/assets/icons/icon_burger_gray.svg?react'
import IconNewChatGray from '@/shared/assets/icons/icon_new_chat_gray.svg?react'
import { IconButton } from '@/shared/ui/IconButton'
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from '@/shared/ui/Tooltip'
import { cn } from '@/shared/utils/common'
import { ChatDeleteDialogue } from '@/widgets/Chat/ChatDeleteDialogue'
import { ChatHistory } from '@/widgets/Chat/ChatHistory'
import { AnimatePresence, motion } from 'framer-motion'
import { useNavigate } from 'react-router-dom'

import { useSidebar } from '../../model/useSidebar'
import { SidebarFooter } from '../SidebarFooter'
import { SidebarHeader } from '../SidebarHeader'

// Define animation variants for the sidebar
const sidebarVariants = {
  open: {
    x: 0,
    transition: {
      type: 'spring',
      stiffness: 300,
      damping: 30,
    },
  },
  closed: {
    x: -304,
    transition: {
      type: 'spring',
      stiffness: 300,
      damping: 30,
    },
  },
}

export const Sidebar = () => {
  const { isSidebarOpen, toggleSidebar } = useSidebar()
  const navigate = useNavigate()

  const {
    isLoading,
    todayChats,
    yesterdayChats,
    otherChatsByDate,
    isFavoritesPage,
    handleFavoritesToggled,
  } = useChatHistory()

  return (
    <>
      <AnimatePresence>
        {!isSidebarOpen && (
          <motion.div
            initial={{ opacity: 0, scale: 0 }}
            animate={{ opacity: 1, scale: 1 }}
            exit={{ opacity: 0, scale: 0 }}
            transition={{ duration: 0.2, type: 'just' }}
            className="absolute left-[-8px] top-[84px] z-50 flex flex-col items-center
              justify-start gap-4"
          >
            <Tooltip>
              <TooltipTrigger asChild>
                <IconButton
                  className="p-1 transition duration-100 hover:border hover:border-gray-200
                    hover:bg-white hover:p-[3px] hover:shadow-shadow6"
                  onClick={toggleSidebar}
                >
                  <IconBurgerGray width={24} height={24} />
                </IconButton>
              </TooltipTrigger>
              <TooltipContent
                side="left"
                align="center"
                className="h-7 px-2 py-1"
              >
                Открыть меню
              </TooltipContent>
            </Tooltip>
            <Tooltip>
              <TooltipTrigger asChild>
                <IconButton
                  className="p-1 transition duration-100 hover:border hover:border-gray-200
                    hover:bg-white hover:p-[3px] hover:shadow-shadow6"
                  onClick={() => navigate('/')}
                >
                  <IconNewChatGray width={24} height={24} />
                </IconButton>
              </TooltipTrigger>
              <TooltipContent
                side="left"
                align="center"
                className="h-7 px-2 py-1"
              >
                Новый диалог
              </TooltipContent>
            </Tooltip>
          </motion.div>
        )}
      </AnimatePresence>
      {/* Sidebar */}
      <motion.div
        className={cn(
          `absolute left-[-8px] top-[72px] z-[80] flex h-[calc(100svh-104px)]
          flex-col items-stretch justify-start rounded-xl border-[1px]
          border-base-200 bg-white`,
          'w-[272px]'
        )}
        variants={sidebarVariants}
        initial="closed"
        animate={isSidebarOpen ? 'open' : 'closed'}
      >
        {/* Header */}
        <SidebarHeader
          isFavoritesPage={isFavoritesPage}
          handleFavoritesToggled={handleFavoritesToggled}
        />
        {/* Chat history */}
        <div className="flex-1 overflow-auto pb-2">
          <ChatHistory
            isLoading={isLoading}
            todayChats={todayChats}
            yesterdayChats={yesterdayChats}
            otherChatsByDate={otherChatsByDate}
          />
        </div>
        {/* Footer */}
        <SidebarFooter />
      </motion.div>
      <ChatDeleteDialogue />
    </>
  )
}
