import { useChatMessages } from '@/entities/chat'
import useMediaQuery from '@/shared/hooks/useMediaQuery'
import { useTypedSelector } from '@/shared/store'
import { ErrorNotification } from '@/shared/ui/ErrorNotification'
import { cn } from '@/shared/utils/common'
import { motion } from 'framer-motion'

import { ChatInput } from '../../../entities/chat/ui/ChatInput'
import { ChatMessages } from '../../../entities/chat/ui/ChatMessages'
import { ChatContainerSkeleton } from './ChatContainerSkeleton'

interface ChatContainerProps {
  chatId?: string
  userId: number
  isSidebarOpen: boolean
}

export const ChatContainer = ({
  chatId,
  userId,
  isSidebarOpen,
}: ChatContainerProps) => {
  const {
    data,
    isFetching,
    isLoading,
    error,
    typedText,
    isAwaitingResponse,
    scrollContainerRef,
  } = useChatMessages(chatId!, userId)

  const { documents } = useTypedSelector(
    (state) => state.documentPreview
  )

  if (!data?.messages) return null
  return (
    <>
      {error && (
        <ErrorNotification
          offset={28}
          position="bottom"
          className="shadow-shadow7"
          actionLabel="Перезагрузить"
          message={error ? error : 'Не удалось загрузить диалог'}
          onActionClick={() => window.location.reload()}
          description="Перезагрузите страницу или попробуйте позже"
        />
      )}
      <motion.div
        className={cn(
          'relative z-30 flex min-h-full w-full flex-col pb-8'
        )}
        initial={{ x: 0 }}
        animate={{ x: documents.length ? '100%' : '0%' }}
        transition={{ duration: 0.5, ease: 'easeInOut' }}
      >
        {!isFetching && !isLoading ? (
          <>
            <div
              ref={scrollContainerRef}
              className="chat_scrollable flex-1 overflow-y-auto"
            >
              <div
                className={cn(
                  isSidebarOpen
                    ? 'lg:min-w-[672px]'
                    : 'lg:min-w-[768px]',
                  `mx-auto h-full w-full min-w-[672px] bg-[#FCFCFC] pt-24
                    lg:max-w-[850px]`
                )}
              >
                <ChatMessages
                  messages={data?.messages}
                  typedText={typedText}
                  isAwaitingResponse={isAwaitingResponse}
                />
              </div>
            </div>
            <ChatInput />
          </>
        ) : (
          <ChatContainerSkeleton
            isSidebarOpen={isSidebarOpen}
            isMobile={false}
          />
        )}
      </motion.div>
    </>
  )
}
