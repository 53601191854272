import { IChatAiFile } from '@/entities/chat/types'
import { ChatAiFileDrawer } from '@/entities/chat/ui/ChatAiFileDrawer'
import { useDocumentOpenInNewTab } from '@/features/document/hooks/useDocumentOpenInNewTab'
import { MobileButtonToggleFavoriteDocument } from '@/features/document/ui/MobileButtonToggleFavoriteDocument'
import IconClose from '@/shared/assets/icons/icon_close.svg?react'
import IconFolderPlusMobile from '@/shared/assets/icons/icon_folder_plus_mobile.svg?react'
import IconFullscreen from '@/shared/assets/icons/icon_fullscreen.svg?react'
import IconMoreDots from '@/shared/assets/icons/icon_more_dots_dark.svg?react'
import IconOpenMobile from '@/shared/assets/icons/icon_open_mobile.svg?react'
import {
  Drawer,
  DrawerClose,
  DrawerContent,
  DrawerDescription,
  DrawerHeader,
  DrawerTitle,
  DrawerTrigger,
  Typography,
} from '@/shared/ui'

type MobileFileActionsDrawerProps = {
  file: IChatAiFile
  document: {
    id: number
    name: string
    body: string
  }
}

export const MobileFileActionsDrawer = ({
  file,
  document,
}: MobileFileActionsDrawerProps) => {
  const handleOpenDocumentInNewTab = useDocumentOpenInNewTab()

  return (
    <Drawer nested direction="bottom">
      <DrawerTrigger>
        <IconMoreDots />
      </DrawerTrigger>
      <DrawerContent className="h-[62svh] overflow-y-hidden">
        <DrawerClose className="absolute right-3 top-3">
          <IconClose className="h-6 w-6" />
        </DrawerClose>
        <DrawerHeader className="justify-start p-0">
          <DrawerTitle>
            <Typography variant={'heading4'}>Действия</Typography>
          </DrawerTitle>
        </DrawerHeader>
        <DrawerDescription className="mt-8 flex flex-col items-stretch justify-start gap-8">
          <ChatAiFileDrawer document={document}>
            <div
              className="flex items-center justify-start gap-3 border-b
                border-b-stroke-light-gray-2 py-3"
            >
              <IconFullscreen />
              <Typography variant={'body'}>Открыть здесь</Typography>
            </div>
          </ChatAiFileDrawer>
          <div
            onClick={() => handleOpenDocumentInNewTab(file.id)}
            className="flex items-center justify-start gap-3 border-b
              border-b-stroke-light-gray-2 py-3"
          >
            <IconOpenMobile />
            <Typography variant={'body'}>В новом окне</Typography>
          </div>

          <MobileButtonToggleFavoriteDocument docId={file.id} />
          <div
            className="flex items-center justify-start gap-3 border-b
              border-b-stroke-light-gray-2 py-3"
          >
            <IconFolderPlusMobile />
            <Typography variant={'body'}>В папку</Typography>
          </div>
        </DrawerDescription>
      </DrawerContent>
    </Drawer>
  )
}
