import { DocumentContentsProvider } from '@/features/document'
import useMediaQuery from '@/shared/hooks/useMediaQuery'
import { DocumentActionsMenu } from '@/shared/ui2/DocumentActionsMenu'
import { DocumentInfoDialog } from '@/widgets/Document/DocumentInfoDialog'
import { ChaptersMobile } from '@/widgets/DocumentByIdPage/ChaptersMobile'
import { DocumentContents } from '@/widgets/DocumentByIdPage/DocumentContents'
import { LeftMenu } from '@/widgets/DocumentByIdPage/LeftMenu'
import { DocumentContentsMobile } from '@/widgets/DocumentByIdPageMobile/DocumentContentsMobile'
import { useParams } from 'react-router-dom'

export const DocumentByIdPage = () => {
  const isMobile = useMediaQuery('(max-width: 960px)')
  const { id: documentId } = useParams()

  if (isMobile) {
    return (
      <DocumentContentsProvider>
        <DocumentInfoDialog />
        <ChaptersMobile />
        <DocumentContentsMobile />
      </DocumentContentsProvider>
    )
  }

  return (
    <DocumentContentsProvider>
      <DocumentInfoDialog />

      <div
        className="relative mx-auto flex w-full items-stretch justify-between gap-6 pt-3
          xs:px-0 md:px-0"
      >
        <div className="h-full">
          <LeftMenu />
        </div>
        <DocumentContents />
        {documentId && (
          <DocumentActionsMenu documentId={documentId} />
        )}
      </div>
    </DocumentContentsProvider>
  )
}
