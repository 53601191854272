import {
  DialogDocumentVersionSelect,
  DocumentVersionsMenuSkeleton,
} from '@/entities/document'
import { VersionRadioGroup, VersionSelect } from '@/features/document'
import { DocumentVersionSelectMobile } from '@/features/document/ui/DocumentVersionSelectMobile'
import {
  IconButton,
  LazySvg,
  RadioGroup,
  RadioGroupItem,
  Typography,
} from '@/shared/ui'

export const VersionMenuMobile = () => {
  return (
    <div>
      <IconButton
        className="m-2 h-7 w-7"
        // onClick={handleCloseVersion}
      >
        <LazySvg
          name="icon_chevron_left_dark"
          width={'56px'}
          height={'56px'}
        />
      </IconButton>

      <div className="p-2">
        <div
          className="flex w-full flex-col items-center justify-between gap-[6px] rounded-xl
            border-[1px] border-base-100 p-2 shadow-shadow-modal-light"
        >
          <div className="w-full">
            <VersionRadioGroup />
          </div>
          <div className="flex w-full items-center gap-4">
            <div className="w-1/2">
              <VersionSelect position={0} isMobile />
            </div>
            <div className="w-1/2">
              <VersionSelect position={1} isMobile />
            </div>
          </div>
          <div className="flex w-full items-center gap-4">
            {/* <DocumentVersionSwitchDiffMobile
              current={1}
              total={1000}
              type="added"
              onClick={() => {}}
            /> */}
          </div>
        </div>
      </div>
    </div>
  )
}
