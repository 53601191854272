import { useChatRename } from '@/features/chat/models/useChatRename'
import IconPenBlack from '@/shared/assets/icons/icon_pen_black.svg?react'
import { DropdownMenuItem, Typography } from '@/shared/ui'

interface DropdownMenuRenameChatProps {
  chatId: string
  initialTitle: string
}

export const DropdownMenuRenameChat = ({
  chatId,
  initialTitle,
}: DropdownMenuRenameChatProps) => {
  const { openRenaming } = useChatRename(chatId, initialTitle)
  return (
    <DropdownMenuItem
      className="flex items-center justify-start gap-2 rounded-lg p-2
        hover:cursor-pointer hover:bg-slate-100"
      inset={true}
      onClick={(e) => {
        e.stopPropagation()
        openRenaming()
      }}
    >
      <IconPenBlack className="h-5 w-5" />
      <Typography
        className="whitespace-nowrap font-normal"
        variant="label"
      >
        Переименовать
      </Typography>
    </DropdownMenuItem>
  )
}
