import { ISearchMode } from '@/entities/document'
import {
  searchModeConfig,
  SearchModeFilterMenuDesktop,
} from '@/features/filters'
import {
  Button,
  IconButton,
  Input,
  LazySvg,
  RadioGroupItem,
  Typography,
} from '@/shared/ui'
import { HookFormCheckbox } from '@/shared/ui2/HookForm/HookFormCheckbox'
import HookFormInput from '@/shared/ui2/HookForm/HookFormInput'
import { HookFormRadioGroup } from '@/shared/ui2/HookForm/HookFormRadioGroup'
import HookFormInputSelect from '@/shared/ui2/HookForm/HookFormSelect'
import { motion } from 'framer-motion'

import { useSearchForm } from '../../model/useSearchForm'

export const SearchForm = () => {
  const {
    control,
    handleSubmit,
    isValid,

    onSubmit,
    onReset,

    searchMode,

    isAdvanced,
    setIsAdvanced,
  } = useSearchForm()

  return (
    <form
      className="flex flex-col gap-3"
      onSubmit={handleSubmit(onSubmit)}
      onReset={onReset}
    >
      <div className="flex w-full gap-3">
        <IconButton
          className="h-10 w-10 rounded-xl border border-stroke-light-gray-2 bg-white"
          onClick={() => setIsAdvanced((old) => !old)}
        >
          <LazySvg name="icon_filter_dark" />
        </IconButton>

        <Input
          placeholder={
            searchModeConfig[searchMode as ISearchMode].placeholder ||
            'Поиск по названию документа...'
          }
          className={`h-10 min-h-10 w-full rounded-full bg-[#F1F2F4] p-4 text-sm
            placeholder:text-[#596170]`}
          style={{
            paddingLeft: `${232}px`,
          }}
          leftIcon={<SearchModeFilterMenuDesktop />}
          rightIcon={
            <IconButton className="absolute -right-1.5 h-8 w-8 -translate-y-1/2 rounded-[16px] bg-white">
              <LazySvg name="icon_search_grey" className="h-5 w-5" />
            </IconButton>
          }
        />
      </div>
      {
        <motion.div
          className="overflow-hidden"
          initial={{ height: '0px', opacity: 0 }}
          animate={{
            height: isAdvanced ? '458px' : '0px',
            opacity: isAdvanced ? 1 : 0,
          }}
          transition={{ duration: 0.3, ease: 'easeInOut' }}
        >
          <div
            className="flex flex-col gap-8 overflow-hidden rounded-2xl border
              border-stroke-light-gray-2 bg-white p-4"
          >
            <div className="flex gap-4">
              <div className="flex w-full flex-col gap-4">
                <div className="flex flex-col gap-2">
                  <Typography className="w-full text-sm font-medium">
                    Судопроизводство
                  </Typography>
                  <HookFormRadioGroup
                    control={control}
                    className="w-[420px] columns-2 gap-5 text-sm"
                    name="radio"
                    options={[
                      { label: 'Гражданские дела', value: '1' },
                      { label: 'Уголовные дела', value: '3' },
                      { label: 'Административные дела', value: '2' },
                      { label: 'АППК', value: '4' },
                    ]}
                    renderOptions={(option) => (
                      <div className="flex items-center gap-2 text-nowrap px-2 py-[6px]">
                        <RadioGroupItem
                          value={String(option.value)}
                        />
                        <span>{option.label}</span>
                      </div>
                    )}
                  />
                </div>
                <div className="flex flex-col gap-2">
                  <Typography className="w-full text-sm font-medium">
                    Инстанция
                  </Typography>
                  <div className="flex gap-2">
                    <div className="flex items-center gap-2 px-2 py-[6px]">
                      <HookFormCheckbox
                        size="small"
                        control={control}
                        name="check1"
                      />
                      <Typography className="w-full text-sm font-medium">
                        Первая
                      </Typography>
                    </div>
                    <div className="flex items-center gap-2 px-2 py-[6px]">
                      <HookFormCheckbox
                        size="small"
                        control={control}
                        name="check2"
                      />
                      <Typography className="w-full text-sm font-medium">
                        Апелляционная
                      </Typography>
                    </div>
                    <div className="flex items-center gap-2 px-2 py-[6px]">
                      <HookFormCheckbox
                        size="small"
                        control={control}
                        name="check3"
                      />
                      <Typography className="w-full text-sm font-medium">
                        Кассационная
                      </Typography>
                    </div>
                  </div>
                </div>

                <div className="flex flex-col gap-2">
                  <Typography className="w-full text-sm font-medium">
                    Территория дела
                  </Typography>
                  <HookFormInputSelect
                    control={control}
                    name="select3"
                    options={[{ value: 1, label: '1' }]}
                    placeholder="Выберите исковые требования"
                  />
                </div>

                <div className="flex flex-col gap-2">
                  <Typography className="w-full text-sm font-medium">
                    Суд
                  </Typography>
                  <HookFormInputSelect
                    control={control}
                    name="select4"
                    options={[{ value: 1, label: '1' }]}
                    placeholder="Выберите исковые требования"
                  />
                </div>
              </div>

              <div className="w-0 flex-1 border-r border-stroke-light-gray-2" />

              <div className="flex w-full flex-col gap-4">
                <div className="flex flex-col gap-2">
                  <Typography className="w-full text-sm font-medium">
                    Стороны процесса
                  </Typography>
                  <div className="flex gap-4 text-xs">
                    <HookFormInput
                      className="h-9 pl-3 text-sm"
                      control={control}
                      label="Истец"
                      placeholder="ФИО истца"
                      name="test1"
                    />
                    <HookFormInput
                      className="h-9 pl-3 text-sm"
                      label="Ответчик"
                      placeholder="ФИО ответчика"
                      control={control}
                      name="test2"
                    />
                  </div>
                </div>

                <div className="flex flex-col gap-2">
                  <Typography className="w-full text-sm font-medium">
                    Номер дела
                  </Typography>
                  <HookFormInput
                    className="h-9 pl-3 text-sm"
                    control={control}
                    placeholder="Введите номер дела"
                    name="test3"
                  />
                </div>

                <div className="flex flex-col gap-2">
                  <Typography className="w-full text-sm font-medium">
                    Исковые требования
                  </Typography>
                  <HookFormInputSelect
                    control={control}
                    name="select"
                    options={[{ value: 1, label: '1' }]}
                    placeholder="Выберите исковые требования"
                  />
                </div>

                <div className="flex flex-col gap-2">
                  <Typography className="w-full text-sm font-medium">
                    Результат дела
                  </Typography>
                  <HookFormInputSelect
                    control={control}
                    name="caseResult"
                    options={[{ value: 1, label: '1' }]}
                    placeholder="Выберите результат дела"
                  />
                </div>
              </div>
            </div>

            <div className="flex flex-col gap-2">
              <Typography className="text-xs text-stroke-light-gray-2">
                Настройте параметры для поиска
              </Typography>

              <div className="flex justify-between gap-8">
                <Button
                  className="h-10 w-full border-stroke-light-gray-2"
                  variant="outlined"
                  type="reset"
                >
                  Очистить
                </Button>
                <Button
                  className="border-stroke-light-gray-4 h-10 w-full gap-2"
                  variant="primary"
                  disabled={!isValid}
                >
                  <LazySvg
                    name={`${isValid ? 'icon_arrow_right_white' : 'icon_arrow_right_dark'}`}
                  />
                  Найти
                </Button>
              </div>
            </div>
          </div>
        </motion.div>
      }
    </form>
  )
}
