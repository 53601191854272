import IconPlanetDark from '@/shared/assets/icons/icon_planet_dark.svg?react'
import { IconButton, LazySvg, Typography } from '@/shared/ui'
import { FC } from 'react'
import { useSearchParams } from 'react-router-dom'

export interface LanguageMenuProps {
  isMobile?: boolean
}

export const LanguageMenu: FC<LanguageMenuProps> = ({ isMobile }) => {
  const [searchParams, setSearchParams] = useSearchParams()
  const language = searchParams.get('language')

  const newParams = new URLSearchParams(searchParams)

  const handleClick = (value: string) => {
    newParams.set('language', value)

    setSearchParams(newParams, { replace: true })
  }

  if (isMobile) {
    return (
      <div
        onClick={() => handleClick(language === 'KK' ? 'RU' : 'KZ')}
        className="flex h-16 w-full cursor-pointer items-center justify-between gap-3
          border-b border-b-stroke-light-gray-1"
      >
        <div className="flex items-center gap-3">
          <LazySvg name="icon_planet_dark" className="h-6 w-6" />
          <Typography variant="label">Сменить язык</Typography>
        </div>
        <div className="flex items-center gap-3 rounded-lg bg-gray-200 p-[6px]">
          <LazySvg name={language === 'KK' ? 'flag_KZ' : 'flag_RU'} />
          <Typography variant="label">
            {language === 'KK' ? 'Қазақ тілі' : 'Русский язык'}
          </Typography>
        </div>
      </div>
    )
  }

  return (
    <IconButton className="h-full w-full p-1 hover:bg-base-100">
      <Typography
        variant={'label'}
        onClick={() => handleClick(language === 'KK' ? 'RU' : 'KK')}
      >
        {language === 'KK' ? 'KZ' : 'RU'}
      </Typography>
    </IconButton>
  )
}
