import { useDocumentChapters } from '@/features/document/hooks/useDocumentChapters'
import { Chapters } from '@/features/document/ui/Chapters'
import IconContentDark from '@/shared/assets/icons/icon_content_dark.svg?react'
import { useAppDispatch } from '@/shared/store'
import { setDocumentsReturnUrl } from '@/shared/store/slices/documents/documentsSlice'
// import IconExitLeft from '@/shared/assets/icons/icon_exit_left.svg?react'
import { Button, IconButton, LazySvg } from '@/shared/ui'
import { motion } from 'framer-motion'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { ChaptersHeader } from '../Chapters/ChaptersHeader'

export const LeftMenu = () => {
  const navigate = useNavigate()
  const [isOpenMenu, setIsOpenMenu] = useState<boolean>(true)
  const dispatch = useAppDispatch()
  const toggleOpen = () => {
    setIsOpenMenu((old) => !old)
  }

  const { chaptersData, isFetchingChapters, isLoadingChapters } =
    useDocumentChapters()

  const handleBack = () => {
    dispatch(setDocumentsReturnUrl({ url: '/documents' }))
    navigate('/documents')
  }
  useEffect(() => {
    if (chaptersData?.length == 0) {
      setIsOpenMenu(false)
    }
  }, [chaptersData])
  return (
    <motion.div
      className="relative flex flex-col items-center gap-4"
      initial={{ width: 328 }}
      animate={{ width: isOpenMenu ? 328 : 50 }}
      transition={{ duration: 0.3, ease: 'easeInOut' }}
    >
      {/* Icons when menu is collapsed */}
      <motion.div
        className="absolute left-0 top-0 flex w-full flex-col items-center gap-3"
        animate={{ opacity: isOpenMenu ? 0 : 1 }}
        transition={{ duration: 0.2 }}
        style={{ pointerEvents: isOpenMenu ? 'none' : 'auto' }}
      >
        <IconButton
          className="h-10 w-10 rounded-full border border-gray-200 p-[10px]"
          onClick={handleBack}
        >
          <LazySvg name={'icon_exit_left'} className="h-6 w-6" />
        </IconButton>

        <IconButton
          disabled={
            isLoadingChapters ||
            isFetchingChapters ||
            chaptersData?.length === 0
          }
          className="h-10 w-10 rounded-full border border-gray-200 p-[10px]"
          onClick={toggleOpen}
        >
          <IconContentDark className="h-6 w-6" />
        </IconButton>
      </motion.div>

      {/* Menu content (hidden when collapsed, but still in DOM) */}
      <motion.div
        className="flex w-full flex-col items-stretch justify-start gap-3"
        animate={{
          opacity: isOpenMenu ? 1 : 0,
          scale: isOpenMenu ? 1 : 0.95,
        }}
        transition={{ duration: 0.2 }}
        style={{ pointerEvents: isOpenMenu ? 'auto' : 'none' }}
      >
        <Button
          variant="outlined"
          className="flex h-[34px] w-full gap-2 whitespace-nowrap rounded-lg border
            border-gray-200 bg-white p-[10px] text-sm"
          onClick={handleBack}
        >
          <LazySvg
            name={'icon_exit_left'}
            className="h-[18px] w-[18px]"
          />
          К списку документов
        </Button>

        <div className="w-full rounded-lg border border-gray-200 bg-white">
          <ChaptersHeader onClose={toggleOpen} />

          <div className="max-h-[calc(100svh-200px)] w-full overflow-y-auto">
            <Chapters />
          </div>
        </div>
      </motion.div>
    </motion.div>
  )
}
