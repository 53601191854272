// ChatByIdPage.tsx
import { DocumentPreviewContainer } from '@/features/document'
import useMediaQuery from '@/shared/hooks/useMediaQuery'
import { FavoriteChatContainer } from '@/widgets/Chat/FavoriteChatContainer'
import { FavoriteChatContainerMobile } from '@/widgets/Chat/FavoriteChatContainerMobile'

export const ChatFavoritesPage = () => {
  const isMobile = useMediaQuery('(max-width: 960px)')

  if (isMobile) {
    return (
      <div
        className={`relative flex min-h-0 flex-1 flex-col items-stretch justify-start
          overflow-y-auto pt-16`}
      >
        <FavoriteChatContainerMobile />
      </div>
    )
  }

  return (
    <>
      {isMobile ? (
        <FavoriteChatContainerMobile />
      ) : (
        <>
          <FavoriteChatContainer />
          <DocumentPreviewContainer />
        </>
      )}
    </>
  )
}
