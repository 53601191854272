import { useToast } from '@/shared/ui/Toast/useToast'

export const useDocumentOpenInNewTab = () => {
  const { toast } = useToast()

  return (fileId: number) => {
    const url = `/documents/${fileId}`
    const fullUrl = window.location.origin + url

    const newTab = window.open(fullUrl, '_blank')
    if (!newTab) {
      toast({
        title: 'Ошибка',
        description:
          'Произошла ошибка при открытии документа в новом окне',
        variant: 'error',
      })
    }
  }
}
