import { cn } from '@/shared/utils/common'
import { cva, VariantProps } from 'class-variance-authority'
import React from 'react'

const spinnerVariants = cva('flex-col items-center justify-center', {
  variants: {
    show: {
      true: 'flex',
      false: 'hidden',
    },
  },
  defaultVariants: {
    show: true,
  },
})

const loaderVariants = cva('animate-spin text-primary', {
  variants: {
    size: {
      small: 'size-6',
      medium: 'size-8',
      large: 'size-12',
    },
  },
  defaultVariants: {
    size: 'medium',
  },
})

const variantOptions = ['primary', 'secondary'] as const
type SpinnerVariant = (typeof variantOptions)[number]

interface SpinnerProps
  extends VariantProps<typeof spinnerVariants>,
    VariantProps<typeof loaderVariants> {
  className?: string
  children?: React.ReactNode
  variant?: SpinnerVariant
}

export function Spinner({
  size,
  show,
  variant = 'primary',
  children,
  className,
}: SpinnerProps) {
  return (
    <span className={cn(spinnerVariants({ show }), className)}>
      {variant === 'primary' ? (
        <svg
          className={cn(loaderVariants({ size }))}
          xmlns="http://www.w3.org/2000/svg"
          width="1em"
          height="1em"
          viewBox="0 0 24 24"
        >
          <g
            fill="none"
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="0.9"
          >
            <path d="M2 12c0-2.8 2.2-5 5-5s5 2.2 5 5s2.2 5 5 5s5-2.2 5-5" />
            <path d="M7 20.7a1 1 0 1 1 5-8.7a1 1 0 1 0 5-8.6" />
            <path d="M7 3.3a1 1 0 1 1 5 8.6a1 1 0 1 0 5 8.6" />
            <circle cx="12" cy="12" r="10" />
          </g>
        </svg>
      ) : (
        <svg
          className="h-[18px] w-[18px] animate-spin"
          viewBox="0 0 100 100"
        >
          <circle
            cx="50"
            cy="50"
            r="35"
            fill="none"
            stroke="#4d8eff"
            strokeWidth="6"
            strokeDasharray="141.37166941154067 49.12388980384689"
          >
            <animateTransform
              attributeName="transform"
              type="rotate"
              repeatCount="indefinite"
              dur="0.6896551724137931s"
              keyTimes="0;1"
              values="0 50 50;360 50 50"
            />
          </circle>
        </svg>
      )}

      {children}
    </span>
  )
}
