import IconCoins from '@/shared/assets/icons/icon_coins.svg?react'
import IconExitLeft from '@/shared/assets/icons/icon_exit_left.svg?react'
import IconGearDark from '@/shared/assets/icons/icon_gear_dark.svg?react'
import IconQuestionOutlined from '@/shared/assets/icons/icon_question_outlined.svg?react'
import { Button } from '@/shared/ui/Button'
import {
  Menubar,
  MenubarContent,
  MenubarItem,
  MenubarMenu,
  MenubarTrigger,
} from '@/shared/ui/Menubar'
import { Typography } from '@/shared/ui/Typography'
import { Separator } from '@radix-ui/react-menubar'
import Cookies from 'js-cookie'
import { ReactNode } from 'react'
import { Link } from 'react-router-dom'

interface ProfileDTO {
  id: number
  name: string
  email: string
}
// TODO: This part will most likely change when we have the real data
const mockProfile: ProfileDTO = {
  id: 10,
  name: 'Талгат',
  email: 'talgat@gmail.com',
}

interface Props {
  children: ReactNode
}

export const ProfileNavigationMenu = ({ children }: Props) => {
  return (
    <Menubar className="self-stretch border-none hover:cursor-pointer">
      <MenubarMenu>
        <MenubarTrigger>{children}</MenubarTrigger>
        <MenubarContent
          className="flex w-[250px] flex-col items-stretch gap-8 rounded-[20px] bg-white
            p-2 shadow-modal"
          collisionPadding={32}
          align="start"
          alignOffset={-180}
          sideOffset={10}
        >
          <div className="flex flex-col items-start gap-2 self-stretch">
            <div className="flex flex-col items-stretch justify-center gap-4 self-stretch p-2">
              <ProfileInfo {...mockProfile} />
              <SubscriptionInfo />
            </div>
            <div className="flex flex-col items-stretch justify-start self-stretch">
              <ProfileMenuItems />
              <Separator className="mb-2 h-[1px] self-stretch bg-base-100" />
              <LogoutButton />
            </div>
            <ProfileFooterLinks />
          </div>
        </MenubarContent>
      </MenubarMenu>
    </Menubar>
  )
}

const ProfileInfo = ({ name, email }: ProfileDTO) => (
  <div className="flex flex-col items-start justify-start">
    <Typography
      variant="label"
      className="font-semibold text-base-950"
    >
      {name}
    </Typography>
    <Typography variant="label" className="text-base-400">
      {email}
    </Typography>
  </div>
)

const SubscriptionInfo = () => (
  <div
    className="flex flex-col items-start justify-start gap-3 rounded-2xl border
      border-base-100 bg-base-50 p-3"
  >
    <div className="flex flex-nowrap items-center justify-between gap-2">
      <Typography
        variant="label"
        className="whitespace-nowrap font-semibold"
      >
        Текущий план
      </Typography>
      <Typography variant="label" className="font-semibold">
        –
      </Typography>
      <Typography
        variant="subtitle"
        className="whitespace-nowrap rounded-lg border border-base-100 bg-base-20 p-2
          font-semibold text-base-950"
      >
        Business
      </Typography>
    </div>
    <Typography variant="label">
      Дней до следующего платежа: 365
    </Typography>
    <Button
      variant="primary"
      className="w-full rounded-lg text-[14px] font-semibold leading-[20px]"
    >
      Улучшить план
    </Button>
  </div>
)

const ProfileMenuItems = () => (
  <div className="flex flex-col items-stretch justify-start gap-2 pb-4">
    <Button
      variant="ghost"
      leftIcon={
        <IconGearDark className="h-5 w-5 [&_path]:stroke-base-950" />
      }
      className="justify-start rounded-lg hover:bg-[#F0F0F0] hover:text-base-950
        active:text-base-950"
    >
      Настройки
    </Button>
    <Button
      leftIcon={
        <IconQuestionOutlined className="h-5 w-5 [&_path]:stroke-base-950" />
      }
      variant="ghost"
      className="justify-start rounded-lg hover:bg-[#F0F0F0] hover:text-base-950
        active:text-base-950"
    >
      Поддержка
    </Button>
    <Button
      leftIcon={
        <IconCoins className="h-5 w-5 [&_path]:stroke-base-950" />
      }
      variant="ghost"
      className="justify-start rounded-lg hover:bg-[#F0F0F0] hover:text-base-950
        active:text-base-950"
    >
      Планы
    </Button>
  </div>
)

const LogoutButton = () => {
  const handleLogout = () => {
    Cookies.remove('access_token')
    window.location.reload()
  }

  return (
    <Button
      leftIcon={
        <IconExitLeft className="h-5 w-5 [&_path]:stroke-base-950 [&_path]:stroke-[1px]" />
      }
      onClick={handleLogout}
      variant="ghost"
      className="justify-start rounded-lg hover:bg-[#F0F0F0] hover:text-base-950
        active:text-base-950"
    >
      Выйти из аккаунта
    </Button>
  )
}

const ProfileFooterLinks = () => (
  <MenubarItem
    className="flex items-center justify-between gap-4 self-stretch border-t-[1px]
      border-t-slate-200 p-2"
  >
    <Link to="#">
      <Typography
        variant="subtitle"
        className="font-normal text-base-400"
      >
        Политика
      </Typography>
    </Link>
    <Link to="#">
      <Typography
        variant="subtitle"
        className="font-normal text-base-400"
      >
        Права
      </Typography>
    </Link>
    <Link to="#">
      <Typography
        variant="subtitle"
        className="font-normal text-base-400"
      >
        Условия
      </Typography>
    </Link>
  </MenubarItem>
)
