import {
  DialogDocumentVersionSelect,
  DocumentVersionsMenuSkeleton,
} from '@/entities/document'
import { Actions, DocumentData } from '@/entities/document/types'
import {
  DocumentVersionSelect,
  VersionSelect,
} from '@/features/document'
import { useDocumentVersionSelect } from '@/features/document/hooks/useDocumentVersionSelect'
import { DocumentVersionMenu } from '@/features/document/ui/DocumentVersionMenu'
import IconClose from '@/shared/assets/icons/icon_close.svg?react'
import {
  IconButton,
  Input,
  LazySvg,
  MenubarItem,
  Typography,
} from '@/shared/ui'
import { Menubar } from '@/shared/ui2/Menubar'
import { Arrow } from '@radix-ui/react-menubar'
import {
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom'

import { ActionsMenu } from '../ActionsMenu'

interface DocumentVersionsMenuProps {
  label?: string
}

export const VersionsMenu = ({
  label,
}: DocumentVersionsMenuProps) => {
  const navigate = useNavigate()
  const { id } = useParams()
  const [searchParams] = useSearchParams()
  const ids = searchParams.get('ids')?.split(',') || []

  if (!label) {
    return null
  }

  return (
    <div
      className="relative rounded-t-lg border border-base-100 bg-white
        shadow-shadow-modal-light"
    >
      <div
        className="flex h-[36px] items-center justify-between border-b-[1px]
          border-base-100 px-4"
      >
        <Typography
          variant={'label'}
          className="truncate text-base-700"
        >
          {label}
        </Typography>

        <IconButton
          className="h-6 w-6"
          onClick={() => {
            navigate(`/documents/${id}`)
          }}
        >
          <IconClose width={'20px'} height={'20px'} />
        </IconButton>
      </div>

      <div className="flex h-[40px] items-center justify-between px-4">
        <div className="flex items-center gap-4">
          <ActionsMenu
            actions={[
              Actions.Chapters,
              Actions.Chapters,
              Actions.Chunks,
              Actions.LanguageMenu,
              Actions.ToLowerCase,
              Actions.Versions,
              Actions.Info,
              Actions.Bookmark,
              Actions.Download,
            ]}
            documentId={Number(ids[0])}
            wrapper={(children) => (
              <Menubar
                iconName="icon_burger"
                collisionPadding={{
                  right: -20,
                  top: 10,
                  bottom: 15,
                }}
                className="z-50 max-w-[440px]"
                align="start"
                alignOffset={-10}
                sideOffset={-5}
              >
                <Arrow fill="#fff" className="h-2 w-4" />
                <div
                  className="flex flex-col gap-3 rounded-[16px] border-[1px] border-base-100
                    bg-white p-3"
                >
                  <div>
                    <Input
                      placeholder="Поиск по  документу..."
                      className="h-10 min-h-10 w-full rounded-full bg-[#F1F2F4] p-4 text-sm
                        placeholder:text-[#596170]"
                      rightIcon={
                        <IconButton className="absolute -right-1.5 h-8 w-8 -translate-y-1/2 rounded-[16px] bg-white">
                          <LazySvg
                            name="icon_search_grey"
                            className="h-5 w-5"
                          />
                        </IconButton>
                      }
                    />
                  </div>

                  <div className="flex gap-4">{children}</div>
                </div>
              </Menubar>
            )}
            itemWrapper={(children) => (
              <MenubarItem className="h-8 w-8 p-0">
                {children}
              </MenubarItem>
            )}
          />

          <div className="w-56">
            <VersionSelect position={0} />
          </div>
        </div>

        <div className="flex items-center gap-4">
          <div className="w-56">
            <VersionSelect position={1} />
          </div>

          <ActionsMenu
            actions={[
              Actions.Chapters,
              Actions.Chunks,
              Actions.LanguageMenu,
              Actions.ToLowerCase,
              Actions.Versions,
              Actions.Info,
              Actions.Bookmark,
              Actions.Download,
            ]}
            documentId={Number(ids[1])}
            wrapper={(children) => (
              <Menubar
                iconName="icon_burger"
                collisionPadding={{
                  right: -20,
                  top: 10,
                  bottom: 15,
                }}
                className="z-50 max-w-[440px]"
                align="end"
                alignOffset={-10}
                sideOffset={-5}
              >
                <Arrow fill="#fff" className="h-2 w-4" />
                <div
                  className="flex flex-col gap-3 rounded-[16px] border-[1px] border-base-100
                    bg-white p-3"
                >
                  <div>
                    <Input
                      placeholder="Поиск по  документу..."
                      className="h-10 min-h-10 w-full rounded-full bg-[#F1F2F4] p-4 text-sm
                        placeholder:text-[#596170]"
                      rightIcon={
                        <IconButton className="absolute -right-1.5 h-8 w-8 -translate-y-1/2 rounded-[16px] bg-white">
                          <LazySvg
                            name="icon_search_grey"
                            className="h-5 w-5"
                          />
                        </IconButton>
                      }
                    />
                  </div>

                  <div className="flex gap-4">{children}</div>
                </div>
              </Menubar>
            )}
            itemWrapper={(children) => (
              <MenubarItem className="h-8 w-8 p-0">
                {children}
              </MenubarItem>
            )}
          />
        </div>
      </div>
    </div>
  )
}
