import IconCheck from '@/shared/assets/icons/icon_tick_white.svg?react'
import { cn } from '@/shared/utils/common'
import * as CheckboxPrimitive from '@radix-ui/react-checkbox'
import { cva, VariantProps } from 'class-variance-authority'
import * as React from 'react'

// Define the styles for the Checkbox using cva
const checkboxStyles = cva(
  `peer flex shrink-0 items-center justify-center
    disabled:cursor-not-allowed
   disabled:opacity-50 `,
  {
    variants: {
      variant: {
        primary: 'data-[state=checked]:bg-additional-blue-2',
        secondary:
          'data-[state=checked]:bg-additional-blue-2 data-[state=checked]:border-additional-blue-2 border-[1px] data-[state=checked]:bg-additional-blue-2 border-[#8D95A3]',
      },
      size: {
        small: 'h-4 w-4 max-w-4 min-w-4 rounded-sm',
        large: 'h-6 w-6 max-w-6 min-w-6',
      },
    },
    defaultVariants: {
      size: 'large',
      variant: 'secondary',
    },
  }
)

const indicatorStyles = cva('flex items-center justify-center', {
  variants: {
    size: {
      small: 'h-3 w-3',
      large: 'h-4 w-4',
    },
  },
  defaultVariants: {
    size: 'large',
  },
})

interface CheckboxProps
  extends React.ComponentPropsWithoutRef<
      typeof CheckboxPrimitive.Root
    >,
    VariantProps<typeof checkboxStyles> {}

const Checkbox = React.forwardRef<
  React.ElementRef<typeof CheckboxPrimitive.Root>,
  CheckboxProps
>(({ variant, className, size = 'large', ...props }, ref) => (
  <CheckboxPrimitive.Root
    ref={ref}
    className={cn(
      checkboxStyles({ variant, size }),
      className,
      'rounded-[4px]'
    )}
    {...props}
  >
    <CheckboxPrimitive.Indicator
      className={cn(indicatorStyles({ size }))}
    >
      <IconCheck
        className={cn(size === 'small' ? 'h-3 w-3' : 'h-4 w-4')}
      />
    </CheckboxPrimitive.Indicator>
  </CheckboxPrimitive.Root>
))

Checkbox.displayName = CheckboxPrimitive.Root.displayName

export { Checkbox, type CheckboxProps }
