import IconBookmarkBlack from '@/shared/assets/icons/icon_bookmark_black.svg?react'
import IconUnmark from '@/shared/assets/icons/icon_unmark.svg?react'
import { IconButton, MenubarItem, Typography } from '@/shared/ui'
import { cn } from '@/shared/utils/common'
import { FC } from 'react'

import { useToogleFavouriteDocument } from '../../hooks/useToogleFavouriteDocument'

export interface BookmarkProps {
  documentId?: number

  withLabel?: boolean
  isMobile?: boolean
}

export const ActionDocumentBookmark: FC<BookmarkProps> = ({
  documentId,

  withLabel = false,
  isMobile,
}) => {
  const {
    isFavorite,
    handleClick,
    handleMouseEnter,
    handleMouseLeave,
    isBookmarkHovered,
    handleToggleFavorite,
  } = useToogleFavouriteDocument(Number(documentId))

  if (isMobile) {
    return (
      <div
        className={cn(
          `flex h-16 w-full cursor-pointer items-center gap-3 border-b
          border-b-stroke-light-gray-1`,
          isFavorite
            ? '[&_path]:fill-additional-blue-2 [&_path]:stroke-additional-blue-2'
            : ''
        )}
        onClick={handleToggleFavorite}
      >
        <IconBookmarkBlack className="h-6 w-6" />
        <Typography variant="label">Добавить в избранное</Typography>
      </div>
    )
  }

  if (!withLabel) {
    return (
      <IconButton
        className="p-1 hover:bg-base-100"
        onClick={handleToggleFavorite}
      >
        <IconBookmarkBlack
          width="100%"
          height="100%"
          className={cn(
            'transition-transform duration-300 ease-in-out',
            isFavorite
              ? '[&_path]:fill-additional-blue-2 [&_path]:stroke-additional-blue-2'
              : ''
          )}
        />
      </IconButton>
    )
  }
  return (
    <MenubarItem
      key={`${documentId} favorite`}
      className="flex items-center justify-start gap-2 rounded-lg p-2
        hover:cursor-pointer hover:bg-slate-100"
      inset={true}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onClick={(e) => {
        handleClick()
        e.stopPropagation()
        handleToggleFavorite()
      }}
    >
      {isFavorite && isBookmarkHovered ? (
        <IconUnmark className="h-5 w-5" />
      ) : (
        <IconBookmarkBlack
          className={cn(
            'h-5 w-5',
            isFavorite
              ? '[&_path]:fill-additional-blue-2 [&_path]:stroke-additional-blue-2'
              : ''
          )}
        />
      )}
      <Typography
        className="whitespace-nowrap font-normal"
        variant={'label'}
      >
        {isFavorite
          ? isBookmarkHovered
            ? 'Из избранных'
            : 'В избранных'
          : 'В избранное'}
      </Typography>
    </MenubarItem>
  )
}
