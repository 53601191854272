import { Actions } from '@/entities/document/types'
import {
  ActionDocumentBookmark,
  ActionDocumentChapters,
  ActionDocumentDownload,
  ActionDocumentInfo,
  ActionDocumentMagnify,
  ActionDocumentOpenInNewTab,
  ActionDocumentOpenVersionsSelect,
  ActionDocumentToLowerCase,
  ActionShowDocumentChunks,
  BookmarkProps,
  ChaptersProps,
  ChunksProps,
  DownloadProps,
  InfoProps,
  LanguageMenu,
  LanguageMenuProps,
  MagnifyProps,
  OpenInNewTabProps,
  ToLowerCaseProps,
  VersionsProps,
} from '@/features/document'
import { UnionToIntersection } from '@/shared/types/generics'
import React from 'react'

type ComponentPropsMap = {
  [Actions.Magnify]: MagnifyProps
  [Actions.Chapters]: ChaptersProps
  [Actions.Chunks]: ChunksProps
  [Actions.ToLowerCase]: ToLowerCaseProps
  [Actions.Versions]: VersionsProps
  [Actions.Info]: InfoProps
  [Actions.OpenInNewTab]: OpenInNewTabProps
  [Actions.Bookmark]: BookmarkProps
  [Actions.Download]: DownloadProps
  [Actions.LanguageMenu]: LanguageMenuProps
}

const test: Actions[number] = Actions.Chunks

type ActionsMenuProps<T extends Actions[]> = {
  actions: T
  reversed?: boolean
  orientation?: 'vertical' | 'horizontal'

  wrapper?: (children: React.ReactNode) => React.ReactNode
  itemWrapper?: (children: React.ReactNode) => React.ReactNode
} & UnionToIntersection<ComponentPropsMap[T[number]]>

/**
 * Желательно, чтобы у пропсов из разных компонентов с одинаковыми названями был один тип
 * @param
 * @returns
 */
export const ActionsMenu = <T extends Actions[]>({
  actions,
  reversed = false,
  orientation,

  wrapper = (children) => <>{children}</>,
  itemWrapper = (children) => <>{children}</>,

  ...restProps
}: ActionsMenuProps<T>) => {
  const ComponentsMap: {
    [K in Actions]: React.FC<ComponentPropsMap[K]>
  } = {
    [Actions.Magnify]: ActionDocumentMagnify,
    [Actions.Chapters]: ActionDocumentChapters,
    [Actions.Chunks]: ActionShowDocumentChunks,
    [Actions.ToLowerCase]: ActionDocumentToLowerCase,
    [Actions.Versions]: ActionDocumentOpenVersionsSelect,
    [Actions.Info]: ActionDocumentInfo,
    [Actions.OpenInNewTab]: ActionDocumentOpenInNewTab,
    [Actions.Bookmark]: ActionDocumentBookmark,
    [Actions.Download]: ActionDocumentDownload,
    [Actions.LanguageMenu]: LanguageMenu,
  }
  return wrapper(
    <>
      {actions.map((action, index) => {
        const Component = ComponentsMap[action]

        // Главное, что работает. На самом деле в restProps всегда будут нужные пропсы, но тут я пока не смог указать
        return itemWrapper(<Component key={index} {...restProps} />)
      })}
    </>
  )
}
