import { useGetListOfFavoritesQuery } from '@/shared/api/chat'
import { smoothScrollTo } from '@/shared/utils/animations'
import { scrollToBottom } from '@/shared/utils/scrollToBottom'
import { useEffect, useRef, useState } from 'react'

export const useFavoriteMessages = () => {
  const {
    data: favoriteData,
    isLoading: favoriteLoading,
    error: favoriteError,
    isFetching: favouriteFetching,
  } = useGetListOfFavoritesQuery(
    { page: 1 },
    { refetchOnMountOrArgChange: true }
  )
  const bottomRef = useRef<HTMLDivElement | null>(null)
  const [isBottomVisible, setIsBottomVisible] = useState(true)

  const scrollContainerRef = useRef<HTMLDivElement | null>(null)

  useEffect(() => {
    const container = scrollContainerRef.current
    if (!container) return

    const timeoutId = setTimeout(() => {
      scrollToBottom(container)
    }, 1000)

    return () => clearTimeout(timeoutId)
  }, [favoriteData?.messages, favouriteFetching])
  // Intersection Observer to detect if bottomRef is visible
  useEffect(() => {
    const observerCallback = (
      entries: IntersectionObserverEntry[]
    ) => {
      const entry = entries[0]
      setIsBottomVisible(entry.isIntersecting)
    }

    const observerOptions = {
      root: null, // Use the viewport
      threshold: 0.5, // Trigger when at least 10% is visible
    }

    const observer = new IntersectionObserver(
      observerCallback,
      observerOptions
    )

    if (bottomRef.current) {
      observer.observe(bottomRef.current)
    }

    return () => {
      if (bottomRef.current) {
        observer.unobserve(bottomRef.current)
      }
      observer.disconnect()
    }
  }, [bottomRef.current])

  return {
    bottomRef,
    isBottomVisible,
    scrollContainerRef,
    messages: favoriteData?.messages,
    isFetching: favouriteFetching,
    isLoading: favoriteLoading,
    error: favoriteError ? 'Failed to load favorite messages' : null,
  }
}
