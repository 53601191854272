import { useDeleteChatByIdMutation } from '@/shared/api/chat'
import IconCheck from '@/shared/assets/icons/icon_check_dark.svg?react'
import { useAppDispatch } from '@/shared/store'
import { closeDeleteDialog } from '@/shared/store/slices/chat/chatSlice'
import { useToast } from '@/shared/ui/Toast/useToast'
import { useNavigate } from 'react-router-dom'

export const useDeleteChat = () => {
  const [deleteChat, { isLoading: isDeleting }] =
    useDeleteChatByIdMutation()
  const navigate = useNavigate()
  const { toast } = useToast()
  const dispatch = useAppDispatch()
  const handleDelete = async (itemId: string) => {
    try {
      await deleteChat(itemId).unwrap()
      toast({
        variant: 'success',
        title: 'Чат успешно удален',
      })
      navigate('/')
    } catch (error: any) {
      if (error?.data?.message) {
        toast({
          variant: 'error',
          title: 'Ошибка при удалении чата',
          description: error.data.message,
        })
      } else
        toast({
          variant: 'error',
          title: 'Что-то пошло не так',
          description: error.data.message,
        })
    } finally {
      dispatch(closeDeleteDialog())
    }
  }

  return {
    handleDelete,
    isDeleting,
  }
}
