import { ChatAiFileDrawer, IChatAiFile } from '@/entities/chat'
import {
  DocumentItemStatus,
  isDocumentStatus,
} from '@/entities/document'
import { Typography } from '@/shared/ui/Typography'
import { MobileFileActionsDrawer } from '@/widgets/Chat/MobileFileActionsDrawer'

type ChatAiFileProps = {
  file: IChatAiFile
}

export const MobileChatAiFile = ({ file }: ChatAiFileProps) => {
  const document = {
    id: file.id,
    name: file.name,
    body: file.name,
  }

  return (
    <div
      className="flex grow flex-col items-stretch justify-start gap-2 self-stretch
        rounded-[16px] border border-base-200 p-4"
      style={{
        boxShadow:
          '0px 6px 2px 0px rgba(0, 0, 0, 0.00), 0px 4px 1px 0px rgba(0, 0, 0, 0.00), 0px 2px 1px 0px rgba(0, 0, 0, 0.02), 0px 1px 1px 0px rgba(0, 0, 0, 0.03)',
      }}
    >
      <ChatAiFileDrawer document={document}>
        <div
          className="flex flex-col items-center justify-center gap-3 pb-2
            hover:cursor-pointer"
        >
          {/* File name */}
          <Typography
            variant={'label'}
            className="whitespace-normal break-words text-start font-medium text-black"
          >
            {file.name}
          </Typography>
          {/* File additional info */}
          <div className="flex flex-col items-stretch justify-start gap-1">
            {file.requisites && (
              <div className="flex items-start justify-start gap-3">
                <Typography
                  variant={'subtitle'}
                  className="text-start font-medium text-base-500"
                >
                  • {file.requisites}
                </Typography>
              </div>
            )}
          </div>
        </div>
      </ChatAiFileDrawer>

      <div className="flex items-center justify-between">
        {file.status && isDocumentStatus(file.status) ? (
          <>
            <DocumentItemStatus
              status={file.status.code}
              label={file.status.name}
            />
            <MobileFileActionsDrawer
              file={file}
              document={document}
            />
          </>
        ) : (
          <>
            <div />
            <MobileFileActionsDrawer
              file={file}
              document={document}
            />
          </>
        )}
      </div>
    </div>
  )
}
