import IconDownloadDark from '@/shared/assets/icons/icon_download_dark.svg?react'
import { useDownloadDocument } from '@/shared/hooks/useDownloadDocument'
import { IconButton, MenubarItem, Typography } from '@/shared/ui'
import { cn } from '@/shared/utils/common'
import { FC } from 'react'
import { useParams } from 'react-router-dom'

export interface DownloadProps {
  documentId?: number
  withLabel?: boolean
  isMobile?: boolean
}

export const ActionDocumentDownload: FC<DownloadProps> = ({
  documentId,
  withLabel = false,
  isMobile,
}) => {
  const { id: documentIdParam } = useParams()
  const { handleDownload } = useDownloadDocument(
    documentId ? documentId : documentIdParam
  )
  if (isMobile) {
    return (
      <div
        onClick={handleDownload}
        className="flex h-16 w-full cursor-pointer items-center gap-3 border-b
          border-b-stroke-light-gray-1"
      >
        <IconDownloadDark className="h-6 w-6" />
        <Typography variant="label">Скачать документ</Typography>
      </div>
    )
  }

  if (withLabel) {
    return (
      <MenubarItem
        onClick={handleDownload}
        inset={true}
        className="p-0"
      >
        <div
          className={cn(
            `flex w-full items-center gap-3 rounded-lg p-2 hover:cursor-pointer
            hover:bg-slate-100`
          )}
        >
          <IconDownloadDark className="h-5 w-5" />
          <Typography
            className="text-nowrap font-normal"
            variant="label"
          >
            Скачать
          </Typography>
        </div>
      </MenubarItem>
    )
  }
  return (
    <IconButton
      className="p-1 hover:bg-base-100"
      onClick={handleDownload}
    >
      <IconDownloadDark width="100%" height="100%" />
    </IconButton>
  )
}
