/* eslint-disable react-hooks/exhaustive-deps */
import {
  DependencyList,
  EffectCallback,
  useEffect,
  useRef,
} from 'react'

export const useDidUpdateEffect = (
  effect: EffectCallback,
  deps: DependencyList
) => {
  const didMount = useRef(false)

  useEffect(() => {
    if (didMount.current) {
      effect()
    } else {
      didMount.current = true
    }
  }, deps)
}
