import { hasRTKQMeta } from '@/shared/utils/api'
import {
  isFulfilled,
  isRejectedWithValue,
  Middleware,
  MiddlewareAPI,
  PayloadAction,
} from '@reduxjs/toolkit'

export const apiMiddleware: Middleware =
  (api: MiddlewareAPI) => (next) => (action) => {
    if (
      isRejectedWithValue(action) &&
      hasRTKQMeta(action) &&
      action.meta.baseQueryMeta.response?.status === 401
    ) {
      api.dispatch({ type: 'user/logout' })
    }
    return next(action)
  }
