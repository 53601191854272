import { IChatMessage } from '@/entities/chat'
import {
  createApi,
  fetchBaseQuery,
} from '@reduxjs/toolkit/query/react'
import Cookies from 'js-cookie'

import { config } from '..'

interface IChatsMeta {
  total: number
  per_page: number
  current_page: number
  next_page: number
  last_page: number
}

interface IChat {
  id: string
  title: string
  is_favorite: boolean
  last_used_at: string
}

interface IGetChatsResponse {
  success: boolean
  meta: IChatsMeta
  chats: IChat[]
}

interface IGetListOfFavoriesResponse {
  success: boolean
  meta: IChatsMeta
  messages: IChatMessage[]
}

interface IGetChatByIdResponse {
  success: boolean
  chat: {
    id: string
    title: string
    last_used_at: string
  }
  messages: IChatMessage[]
}

interface INewChatResponse {
  success: boolean
  chat: {
    id: string
    title: string
  }
  message: IChatMessage
}

export const chatApi = createApi({
  reducerPath: 'chatApi',
  baseQuery: fetchBaseQuery({
    baseUrl: config.API_BASE,
    prepareHeaders: (headers) => {
      const token = Cookies.get('access_token')
      if (token) {
        headers.set('Authorization', `Bearer ${token}`)
      }
      return headers
    },
  }),
  tagTypes: ['Chat'],
  endpoints: (builder) => ({
    getChats: builder.query<
      IGetChatsResponse,
      { page?: number; onlyFavorite?: boolean }
    >({
      query: ({ page, onlyFavorite }) => ({
        url: '/chats',
        params: { page, only_favorite: Number(onlyFavorite) },
        cache: 'no-cache',
      }),
      providesTags: (result) =>
        result
          ? [
              ...result.chats.map(({ id }) => ({
                type: 'Chat' as const,
                id,
              })),
              { type: 'Chat', id: 'LIST' },
            ]
          : [{ type: 'Chat', id: 'LIST' }],
    }),
    getChatById: builder.query<IGetChatByIdResponse, string>({
      query: (id) => `/chats/${id}`,
      keepUnusedDataFor: 86700,
      providesTags: (result, error, id) => [{ type: 'Chat', id }],
    }),
    getListOfFavorites: builder.query<
      IGetListOfFavoriesResponse,
      { page: number }
    >({
      query: (params) => {
        const typedParams: { page: number } = {
          page: Number(params.page) || 1,
        }
        return {
          url: `chats/messages/favorite`,
          params: typedParams,
        }
      },
      providesTags: [{ type: 'Chat', id: 'Favorites' }],
    }),

    patchToggleFavoriteMessage: builder.mutation<
      { status: boolean },
      { chat_id: string; id: string }
    >({
      query: ({ chat_id, id }: { chat_id: string; id: string }) => ({
        url: `chats/${chat_id}/messages/${id}/favorite`,
        method: 'PATCH',
      }),

      async onQueryStarted(
        { chat_id, id },
        { dispatch, queryFulfilled }
      ) {
        const patchResult = dispatch(
          chatApi.util.updateQueryData(
            'getChatById',
            chat_id,
            (draft) => {
              const message = draft.messages.find(
                (msg) => msg.id === Number(id)
              )
              if (message) {
                message.is_favorite = !message.is_favorite
              }
            }
          )
        )
        try {
          await queryFulfilled
        } catch {
          patchResult.undo()
        }
      },
    }),
    patchRenameChatById: builder.mutation<
      { status: string },
      { id: string; title: string }
    >({
      query: ({ id, title }: { id: string; title: string }) => ({
        url: `/chats/${id}`,
        method: 'PATCH',
        body: {
          title: title,
        },
        headers: {
          'Content-Type': 'application/json',
        },
      }),
      invalidatesTags: (result, error, { id }) => [
        { type: 'Chat', id },
      ],
    }),
    patchFavoriteChatById: builder.mutation<
      { status: string },
      string
    >({
      query: (id) => ({
        url: `/chats/${id}/favorite`,
        method: 'PATCH',
      }),
      invalidatesTags: (result, error, id) => [
        { type: 'Chat', id },
        { type: 'Chat', id: 'LIST' },
        { type: 'Chat', id: 'Favorites' },
      ],
    }),
    deleteChatById: builder.mutation<{ status: string }, string>({
      query: (id) => ({
        url: `/chats/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: (result, error, id) => [{ type: 'Chat', id }],
    }),
    postNewChat: builder.mutation<
      INewChatResponse,
      {
        message: string
      }
    >({
      query: (data) => ({
        url: '/chats',
        method: 'POST',
        body: data,
        headers: {
          'Content-Type': 'application/json',
        },
      }),
      invalidatesTags: [{ type: 'Chat', id: 'LIST' }],
    }),
    postNewChatMessage: builder.mutation<
      {
        success: boolean
        chat: {
          id: string
        }
        message: IChatMessage
      },
      {
        chatId: string
        data: {
          message: string
        }
      }
    >({
      query: ({ chatId, data }) => ({
        url: `/chats/${chatId}/messages`,
        method: 'POST',
        body: data,
        headers: {
          'Content-Type': 'application/json',
        },
      }),

      async onQueryStarted(
        { chatId, data },
        { dispatch, queryFulfilled }
      ) {
        const tempId = -Date.now()

        const patchResult = dispatch(
          chatApi.util.updateQueryData(
            'getChatById',
            chatId,
            (draft) => {
              draft.messages.push({
                id: tempId,
                from: 'USER',
                is_favorite: false,
                message: data.message,
                created_at: new Date().toISOString(),
              })
            }
          )
        )

        try {
          const { data: responseData } = await queryFulfilled

          dispatch(
            chatApi.util.updateQueryData(
              'getChats',
              { page: 1, onlyFavorite: false },
              (draft) => {
                const chat = draft.chats.find(
                  (chat) => chat.id === responseData.chat.id
                )
                if (chat) {
                  chat.last_used_at = responseData.message.created_at
                }
              }
            )
          )

          dispatch(
            chatApi.util.updateQueryData(
              'getChatById',
              chatId,
              (draft) => {
                const index = draft.messages.findIndex(
                  (msg) => msg.id === tempId
                )
                if (index !== -1) {
                  draft.messages.splice(
                    index,
                    1,
                    responseData.message
                  )
                } else {
                  draft.messages.push(responseData.message)
                }
              }
            )
          )
        } catch (err: any) {
          patchResult.undo()
          throw new Error(err.message)
        }
      },
    }),
  }),
})

export const {
  useGetChatsQuery,
  useGetChatByIdQuery,
  usePostNewChatMutation,
  useDeleteChatByIdMutation,
  useGetListOfFavoritesQuery,
  usePostNewChatMessageMutation,
  usePatchRenameChatByIdMutation,
  usePatchFavoriteChatByIdMutation,
  usePatchToggleFavoriteMessageMutation,
} = chatApi
