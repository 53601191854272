import { IChatHistoryItem } from '@/entities/chat/types'
import { useChatHistoryItem } from '@/entities/chatHistory'
import {
  ChatRenameInput,
  DropdownMenuDeleteChat,
  DropdownMenuRenameChat,
  DropdownMenuToggleFavoriteChat,
  useChatDropDown,
} from '@/features/chat'
import IconMoreGray from '@/shared/assets/icons/icon_more_gray.svg?react'
import useMediaQuery from '@/shared/hooks/useMediaQuery'
import { useTypedSelector } from '@/shared/store'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuTrigger,
} from '@/shared/ui'
import { Typography } from '@/shared/ui/Typography'
import { cn } from '@/shared/utils/common'
import { useBoundary } from '@/shared/utils/useBoundary'
import { useSidebar } from '@/widgets/Sidebar'
import { DropdownMenuArrow } from '@radix-ui/react-dropdown-menu'
import { useNavigate, useParams } from 'react-router-dom'

interface ChatHistoryItemProps {
  item: IChatHistoryItem
}

export const ChatHistoryItem = ({ item }: ChatHistoryItemProps) => {
  const navigate = useNavigate()
  const { isHovered, triggerRef } = useChatHistoryItem()
  const { toggleSidebar } = useSidebar()
  const { chat_id } = useParams<{ chat_id: string }>()

  const isMobile = useMediaQuery('(max-width: 960px)')
  const boundaryElements = useBoundary('menu_boundary')
  const chatData = useTypedSelector(
    (state) => state.chats.chatsData[item.id] || {}
  )
  const isBeingRenamed = chatData.isBeingRenamed || false

  const {
    handleDropdownOpenChange,
    isDropdownOpen,
    setIsDropdownOpen,
  } = useChatDropDown()

  return (
    <>
      <DropdownMenu
        open={isDropdownOpen}
        onOpenChange={handleDropdownOpenChange}
      >
        <div
          className={cn(
            'flex h-[36px] items-center justify-stretch self-stretch border-none',
            'rounded-lg shadow-none hover:cursor-pointer hover:bg-base-100',
            item.id === chat_id || isDropdownOpen ? 'bg-base-100' : ''
          )}
        >
          <div
            className="relative flex w-full items-center justify-between self-stretch
              overflow-hidden p-2"
            ref={triggerRef}
            onClick={() => {
              isMobile && toggleSidebar()
              navigate(`/chats/${item.id}`)
            }}
          >
            {isBeingRenamed ? (
              <ChatRenameInput
                chatId={item.id}
                initialText={item.title ?? ''}
              />
            ) : (
              <Typography
                variant={'label'}
                className="relative shrink grow-0 basis-full overflow-hidden whitespace-nowrap"
              >
                {item.title}
              </Typography>
            )}
            {!isBeingRenamed && (
              <DropdownMenuTrigger
                onClick={(e) => {
                  setIsDropdownOpen(!isDropdownOpen)
                  e.stopPropagation()
                }}
              >
                {isHovered ||
                item.id === chat_id ||
                isDropdownOpen ? (
                  <div
                    className={cn(
                      'absolute right-2 top-2 z-50 flex items-center justify-end gap-1',
                      'bg-fade-gradient py-[1px] pl-[22px] hover:cursor-pointer'
                    )}
                  >
                    <IconMoreGray
                      height={18}
                      width={18}
                      className="[&_path]:fill-base-950"
                    />
                  </div>
                ) : (
                  <div
                    className="absolute right-2 top-2 flex h-[20px] w-[18px] items-center justify-end
                      gap-1 bg-fade-gradient-white py-[1px] pl-[22px]"
                  />
                )}
              </DropdownMenuTrigger>
            )}
          </div>
          <DropdownMenuContent
            collisionBoundary={boundaryElements || undefined}
            className="z-[120] flex min-w-[169px] max-w-[169px] flex-col items-stretch
              justify-start rounded-[20px] border-none bg-base-20 p-2"
            align="center"
            sideOffset={12}
            style={{
              boxShadow:
                '0px 6px 16px 0px rgba(0, 0, 0, 0.08), 0px 3px 6px -4px rgba(0, 0, 0, 0.12), 0px 9px 28px 8px rgba(0, 0, 0, 0.05)',
            }}
            side="right"
          >
            <DropdownMenuArrow fill="#FCFDFD" className="h-2 w-5" />
            <DropdownMenuRenameChat
              chatId={item.id}
              initialTitle={item.title}
            />
            <DropdownMenuToggleFavoriteChat item={item} />
            <DropdownMenuDeleteChat chatId={item.id} />
          </DropdownMenuContent>
        </div>
      </DropdownMenu>
    </>
  )
}
