import { Actions } from '@/entities/document/types'
import { MobileRawHtmlRenderer } from '@/entities/panel/ui/MobileRawHtmlRendere'
import IconClose from '@/shared/assets/icons/icon_close.svg?react'
import IconMoreDotsDark from '@/shared/assets/icons/icon_more_dots_dark.svg?react'
import IconOpenDark from '@/shared/assets/icons/icon_open_dark.svg?react'
import useMediaQuery from '@/shared/hooks/useMediaQuery'
import { LazySvg, Typography } from '@/shared/ui'
import {
  Drawer,
  DrawerClose,
  DrawerContent,
  DrawerDescription,
  DrawerHeader,
  DrawerTitle,
  DrawerTrigger,
} from '@/shared/ui/Drawer'
import { IconButton } from '@/shared/ui/IconButton'
import { ActionsMenu } from '@/widgets/Document/ActionsMenu'
import { ReactNode } from 'react'

import { useChatAiFileDrawer } from '../../models/useChatAiFileDrawer'

type Props = {
  children: ReactNode
  document: {
    id: number
    name: string
    body: string
  }
}

export const ChatAiFileDrawer = ({ children, document }: Props) => {
  const {
    isInfoDialogOpen,
    handleToggleFavoriteClick,
    handleInfoClick,
    handleInfoClose,
    handleOpenInNewTab,
    isMobile,
    handleChangeMobileDocLanguage,
  } = useChatAiFileDrawer({ document })

  return (
    <>
      <Drawer direction="bottom" nested>
        <DrawerTrigger>{children}</DrawerTrigger>
        <DrawerContent
          className="h-[92svh] overflow-y-hidden bg-white px-0 pb-0 pt-[6px] sm:h-[90vh]
            md:h-[88vh]"
        >
          <DrawerHeader
            className="-mt-4 flex items-center justify-between border-b-[1px] border-base-100
              p-3"
          >
            <div className="flex items-center justify-start gap-8">
              {/* did not decide yet what will do with mobile language so I just commented */}
              {/* <ChatAiFileDrawerMenu
                onToggleFavoriteClick={handleToggleFavoriteClick}
                onInfoClick={handleInfoClick}
              > */}
              {document && document.body && (
                <ActionsMenu
                  isMobile
                  actions={[
                    Actions.Info,
                    Actions.OpenInNewTab,
                    Actions.Bookmark,
                    Actions.Versions,
                    Actions.LanguageMenu,
                    Actions.Download,
                  ]}
                  documentId={Number(document.id)}
                  wrapper={(children) => (
                    <Drawer direction="bottom">
                      <DrawerTrigger>
                        <LazySvg name="icon_more_dots_dark" />
                      </DrawerTrigger>
                      <DrawerContent className="z-[90] h-[75%]">
                        <DrawerClose className="absolute right-3 top-3">
                          <LazySvg
                            name="icon_close"
                            className="h-6 w-6"
                          />
                        </DrawerClose>
                        <DrawerHeader className="justify-start p-0">
                          <DrawerTitle>
                            <Typography variant={'heading4'}>
                              Действия
                            </Typography>
                          </DrawerTitle>
                        </DrawerHeader>
                        <DrawerDescription className="mt-8 flex flex-col items-stretch justify-start gap-8">
                          <div className="flex flex-col items-start justify-start gap-3">
                            {children}
                          </div>
                        </DrawerDescription>
                      </DrawerContent>
                    </Drawer>
                  )}
                />
              )}

              {/* </ChatAiFileDrawerMenu> */}
              {!isMobile && (
                <IconButton onClick={handleOpenInNewTab}>
                  <IconOpenDark />
                </IconButton>
              )}
            </div>
            <DrawerTitle></DrawerTitle>
            <div className="flex items-center justify-start gap-8">
              {!isMobile && <div className="h-6 w-6" />}
              <DrawerClose>
                <IconClose className="h-6 w-6" />
              </DrawerClose>
            </div>
          </DrawerHeader>
          <DrawerDescription
            className="w-full overflow-y-auto px-5 pb-0 pt-4 text-base font-normal
              leading-normal"
          >
            <MobileRawHtmlRenderer documentId={document.id} />
          </DrawerDescription>
        </DrawerContent>
      </Drawer>
    </>
  )
}
