import { RadioGroupItem } from '@/shared/ui/RadioButton'
import { cn } from '@/shared/utils/common'
import { CheckCircledIcon, CircleIcon } from '@radix-ui/react-icons'
import * as RadioGroup from '@radix-ui/react-radio-group'
import React, { ReactNode, useEffect } from 'react'
import {
  Control,
  Controller,
  FieldErrors,
  FieldValues,
  Path,
  UseFormResetField,
} from 'react-hook-form'

interface HookFormRadioGroupProps<
  T extends FieldValues,
  E extends Record<string, any>,
> extends Partial<HTMLDivElement> {
  control: Control<T>
  name: Path<T>
  errors?: FieldErrors<T>
  resetField?: UseFormResetField<T>

  options: E[]
  renderOptions: (option: E) => ReactNode
}

export const HookFormRadioGroup = <
  T extends FieldValues,
  E extends Record<string, any>,
>({
  control,
  name,
  errors,
  resetField,

  options,
  renderOptions,

  className,
}: HookFormRadioGroupProps<T, E>) => {
  useEffect(() => {
    return () => {
      resetField?.(name)
    }
  }, [name, resetField])

  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <RadioGroup.Root
          value={field.value}
          onValueChange={field.onChange}
          className={className}
        >
          {options.map((option) => (
            <React.Fragment
              key={JSON.stringify(option)}
              // className="flex items-center gap-2 text-nowrap"
            >
              {renderOptions(option)}
              {/* <RadioGroupItem value={option.value} />
              <span>{option.label}</span> */}
            </React.Fragment>
          ))}
        </RadioGroup.Root>
      )}
    />
  )
}
