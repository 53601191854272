import { useChatMessages } from '@/entities/chat/models/useChatMessages'
import { IUser } from '@/entities/user/models'
import IconLogo from '@/shared/assets/icons/icon_ai_filled_blue.svg?react'
import IconClose from '@/shared/assets/icons/icon_close.svg?react'
import { useAppDispatch, useTypedSelector } from '@/shared/store'
import {
  closeChatPanel,
  toggleFirstDocumentPanel,
} from '@/shared/store/slices/documents/documentPreviewSlice'
import { IconButton } from '@/shared/ui'
import { Typography } from '@/shared/ui/Typography'
import { cn } from '@/shared/utils/common'
import { useEffect, useState } from 'react'
import { useLoaderData, useParams } from 'react-router-dom'

import { ChatInput } from '../ChatInput'
import { ChatMessages } from '../ChatMessages'

interface ChatInputProps {
  isPanelInput?: boolean
  isVisibleSecondPanel?: boolean
  isDragging: boolean
}

export const ChatPanel = ({
  isPanelInput,
  isDragging,
  isVisibleSecondPanel,
}: ChatInputProps) => {
  const dispatch = useAppDispatch()
  const { user } = useLoaderData() as {
    user: IUser
  }
  const { chat_id } = useParams<{ chat_id: string }>()
  const { data } = useChatMessages(chat_id!, Number(user.id))
  const [isVisibleFirst, setIsVisibleFirst] = useState(true)
  const { isChangedFirstPanel } = useTypedSelector(
    (state) => state.documentPreview
  )
  const isAwaitingResponse = useTypedSelector(
    (state) =>
      state.chats.chatsData[chat_id!]?.isAwaitingResponse ?? false
  )
  useEffect(() => {
    let timeoutId: ReturnType<typeof setTimeout> | null = null

    if (isChangedFirstPanel) {
      setIsVisibleFirst(false)
      timeoutId = setTimeout(() => {
        setIsVisibleFirst(true)
        dispatch(toggleFirstDocumentPanel(false))
      }, 1200)
    }

    return () => {
      if (timeoutId) clearTimeout(timeoutId)
    }
  }, [isChangedFirstPanel, dispatch])

  return (
    <div
      className={cn(
        'relative flex shrink-0 flex-col items-center bg-white',
        `border-[1px] border-base-100 transition-transform duration-[600ms]
        ease-in-out`,
        isVisibleFirst
          ? 'relative translate-x-0'
          : 'fixed translate-x-full'
      )}
    >
      {/* Panel header */}
      <div
        className="flex min-h-[40px] items-center justify-between self-stretch
          border-b-[1px] border-base-100 py-2 pl-4 pr-2"
      >
        <div className="flex cursor-pointer items-center justify-between gap-2">
          <IconLogo width="18px" height="18px" />
          <Typography className="font-[600]">AI-ассистент</Typography>
        </div>
        <IconButton
          onClick={() => dispatch(closeChatPanel())}
          className="cursor-pointer"
        >
          <IconClose width="24px" height="24px" />
        </IconButton>
      </div>
      {/* Panel chat container */}
      <div
        className="menu_boundary relative flex h-[calc(100svh-186px)] w-full grow
          flex-col items-center gap-4 self-stretch overflow-y-auto px-5 pb-6
          pt-8"
      >
        <ChatMessages
          messages={data?.messages}
          isAwaitingResponse={isAwaitingResponse}
        />
      </div>
      <div className="w-full">
        <ChatInput isPanel={true} />
      </div>
    </div>
  )
}
