import {
  ComponentProps,
  FC,
  useEffect,
  useRef,
  useState,
} from 'react'

interface LazySvgProps extends ComponentProps<'svg'> {
  name: string
}
const icons = import.meta.glob('@/shared/assets/icons/*.svg', {
  eager: true,
  query: 'react',
}) as Record<string, { default: FC<ComponentProps<'svg'>> }>

const useLazySvgImport = (name: string) => {
  const filePath = `/src/shared/assets/icons/${name}.svg`
  const Svg = icons[filePath]?.default || null

  return { Svg }
}

export const LazySvg = ({ name, ...props }: LazySvgProps) => {
  const { Svg } = useLazySvgImport(name)

  if (!Svg) {
    return null
  }

  return <Svg {...props} />
}
