import IconContentDark from '@/shared/assets/icons/icon_content_dark.svg?react'
import IconExitLeft from '@/shared/assets/icons/icon_exit_left.svg?react'
import {
  Button,
  IconButton,
  LazySvg,
  RadioGroup,
  RadioGroupItem,
  Typography,
} from '@/shared/ui'
import { motion } from 'framer-motion'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'

export const LeftMenu = () => {
  const navigate = useNavigate()
  const [isOpenMenu, setIsOpenMenu] = useState<boolean>(false)

  const toggleOpen = () => {
    setIsOpenMenu((old) => !old)
  }

  const handleBack = () => {
    navigate('/court-base')
  }

  return (
    <motion.div
      className="flex flex-col items-start gap-4 overflow-hidden"
      initial={{ width: '50px' }}
      animate={{
        width: isOpenMenu ? '328px' : '50px',
      }}
      transition={{ duration: 0.3, ease: 'easeInOut' }}
    >
      {!isOpenMenu ? (
        <>
          <IconButton
            className="h-10 w-10 rounded-full border border-gray-200 p-2.5"
            onClick={handleBack}
          >
            <IconExitLeft className="h-6 w-6" />
          </IconButton>

          <IconButton
            className="h-10 w-10 rounded-full border border-gray-200 p-2.5"
            onClick={toggleOpen}
          >
            <IconContentDark className="h-6 w-6" />
          </IconButton>
        </>
      ) : (
        <>
          <Button
            variant="outlined"
            className="flex h-[34px] w-full gap-2 whitespace-nowrap rounded-lg border
              border-gray-200 p-[10px] text-sm"
            onClick={handleBack}
          >
            <IconExitLeft className="h-[18px] w-[18px]" />К списку
            документов
          </Button>

          <div className="w-full rounded-lg border border-gray-200 bg-white">
            <div className="flex flex-col gap-2 bg-bg-light-white-2 p-2">
              <div className="flex justify-between">
                <Typography className="text-xs">
                  История дела
                </Typography>
                <IconButton onClick={toggleOpen}>
                  <LazySvg
                    name="icon_grid"
                    className="h-[18px] w-[18px]"
                  />
                </IconButton>
              </div>
              <RadioGroup className="gap-0">
                <div className="flex items-center gap-2 p-1.5">
                  <RadioGroupItem value="1" />
                  <Typography className="text-sm">
                    Показывать все даты
                  </Typography>
                </div>
                <div className="flex items-center gap-2 p-1.5">
                  <RadioGroupItem value="2" />
                  <Typography className="text-sm">
                    Только с документами
                  </Typography>
                </div>
              </RadioGroup>
            </div>

            <div
              className="flex max-h-[calc(100svh-200px)] w-full flex-col gap-4 overflow-y-auto
                px-4 py-2"
            >
              {[
                { date: '01.01.2222' },
                { date: '01.01.2222' },
                { date: '01.01.2222' },
                { date: '01.01.2222' },
              ].map((item) => (
                <div className="flex flex-col border-stroke-light-gray-1 [&:not(:last-child)]:border-b">
                  <Typography className="text-xs">
                    {item.date}
                  </Typography>
                  <div className="flex flex-col gap-2 p-2">
                    <Typography className="text-sm">
                      Text asdasdasd
                    </Typography>
                    <Typography className="text-sm">
                      Text asdasdasd
                    </Typography>
                    <Typography className="text-sm">
                      Text asdasdasd
                    </Typography>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </>
      )}
    </motion.div>
  )
}
