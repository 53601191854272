import { IUser } from '@/entities/user/models'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import Cookies from 'js-cookie'

interface IUserState {
  isAuthenticated: boolean
  user: IUser | null
}

const initialState: IUserState = {
  isAuthenticated: false,
  user: null,
}

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    updateUser: (state, action: PayloadAction<IUser>) => {
      state.user = action.payload
      state.isAuthenticated = true
    },
    logout: (state) => {
      state.isAuthenticated = false
      state.user = null
      Cookies.remove('access_token')
    },
  },
})

export const { updateUser, logout } = userSlice.actions
export default userSlice.reducer
