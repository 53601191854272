import { useAppDispatch, useTypedSelector } from '@/shared/store'
import { setDocumentsVersions } from '@/shared/store/slices/documents/documentsSlice'
import { useSearchParams } from 'react-router-dom'

import { Version } from '../types'

export const useChangeVersions = () => {
  const dispatch = useAppDispatch()
  const [_, setSearchParams] = useSearchParams()
  const { versions, allVersions } = useTypedSelector(
    (state) => state.documents
  )

  const updateVersionsSearchParams = (
    position: 0 | 1,
    newId: number
  ) => {
    setSearchParams((params) => {
      const ids = params.get('ids')?.split(',') || []

      ids[position] = String(newId)

      params.set('ids', ids.join(','))

      return params
    })
  }

  const updateVersionsState = (position: 0 | 1, newId: number) => {
    const updatedVersions: [Version, Version] = versions.map(
      (v, index) =>
        index === position
          ? allVersions.find((ver) => ver.id === newId) || v
          : v
    ) as [Version, Version]

    dispatch(setDocumentsVersions(updatedVersions))
  }

  const updateSelectedVersion = (selectedId: number) => {
    setSearchParams((params) => {
      params.set('selected_version_id', String(selectedId))

      return params
    })
  }

  const updateVersion = (position: 0 | 1, newId: number) => {
    updateVersionsSearchParams(position, newId)
    updateVersionsState(position, newId)
    updateSelectedVersion(newId)
  }

  return {
    updateVersion,
    updateSelectedVersion,
  }
}
