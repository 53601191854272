import { useChatHistory } from '@/entities/chatHistory'
import IconBookmarkDark from '@/shared/assets/icons/icon_bookmark_dark.svg?react'
import IconBurger from '@/shared/assets/icons/icon_burger_gray.svg?react'
import IconNewChat from '@/shared/assets/icons/icon_new_chat.svg?react'
import {
  Drawer,
  DrawerClose,
  DrawerContent,
  DrawerDescription,
  DrawerHeader,
  DrawerTitle,
  DrawerTrigger,
} from '@/shared/ui/Drawer'
import { IconButton } from '@/shared/ui/IconButton'
import { ChatHistory } from '@/widgets/Chat/ChatHistory'
import { ReactNode, useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import { useSidebar } from '../../model/useSidebar'

export const MobileSidebar = ({
  children,
}: {
  children: ReactNode
}) => {
  const location = useLocation()

  const { isSidebarOpen, toggleSidebar } = useSidebar()

  useEffect(() => {
    if (isSidebarOpen) {
      toggleSidebar()
    }
  }, [])

  const {
    isLoading,
    todayChats,
    yesterdayChats,
    otherChatsByDate,
    isFavoritesPage,
    handleFavoritesToggled,
  } = useChatHistory()
  const navigate = useNavigate()

  return (
    <Drawer
      direction="left"
      open={isSidebarOpen}
      onOpenChange={() => toggleSidebar()}
    >
      <DrawerTrigger className="px-5 py-5">{children}</DrawerTrigger>
      <DrawerContent
        swipePanelVisible={false}
        className="menu_boundary absolute mb-[88px] h-[calc(100svh-88px)]
          w-[calc(100vw-59px)] overflow-y-auto overflow-x-hidden rounded-none
          bg-white pb-0 pt-5 sm:w-[240px] md:w-[384px]"
      >
        <DrawerHeader
          className="sticky top-0 -mx-6 flex items-center justify-between bg-white-blured
            pb-8 pt-0 shadow-sm backdrop-blur-4px"
        >
          <DrawerTitle className="hidden" />
          <DrawerClose asChild>
            <IconButton>
              <IconBurger className="h-6 w-6 [&_path]:stroke-base-950" />
            </IconButton>
          </DrawerClose>
          <DrawerClose asChild onClick={() => navigate('/')}>
            <IconButton>
              <IconNewChat className="h-6 w-6" />
            </IconButton>
          </DrawerClose>
          <DrawerClose asChild>
            <IconButton onClick={handleFavoritesToggled}>
              <IconBookmarkDark
                fill={isFavoritesPage ? '#4D8EFF' : 'none'}
                className={
                  isFavoritesPage
                    ? '[&>path]:stroke-[#4D8EFF]'
                    : '[&>path]:stroke-base-950'
                }
              />
            </IconButton>
          </DrawerClose>
        </DrawerHeader>
        <DrawerDescription className="hidden" />
        <ChatHistory
          isLoading={isLoading}
          todayChats={todayChats}
          yesterdayChats={yesterdayChats}
          otherChatsByDate={otherChatsByDate}
        />
      </DrawerContent>
    </Drawer>
  )
}
