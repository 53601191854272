import { Actions } from '@/entities/document/types'
import { IconButton, LazySvg, Typography } from '@/shared/ui'
import { ActionsMenu } from '@/widgets/Document/ActionsMenu'

import { CaseCard } from '../CaseCard'

interface CaseListProps {
  caseList: any[]
  sortOrder: 'asc' | 'desc'
}

export const CaseList = ({ caseList }: CaseListProps) => {
  const a = 1

  return (
    <>
      {caseList.map((item, index) => (
        <div
          key={JSON.stringify(item)}
          className={`relative flex gap-5 border-stroke-light-gray-1 py-4 pl-4 pr-3
          [&:not(:last-child)]:border-y`}
        >
          <Typography variant={'body'} className="font-medium">
            {index + 1}.
          </Typography>

          <CaseCard item={item} />

          <div className="flex flex-col items-center gap-4">
            <IconButton className="h-7 w-7 hover:bg-base-100">
              <LazySvg name="icon_more_dots_dark" />
            </IconButton>
            <ActionsMenu
              actions={[Actions.Bookmark]}
              documentId={1}
              itemWrapper={(children) => (
                <div className="h-7 w-7">{children}</div>
              )}
            />
          </div>
        </div>
      ))}
    </>
  )
}
