import IconBookmarkGray from '@/shared/assets/icons/icon_bookmark_gray.svg?react'
import IconBurgerGray from '@/shared/assets/icons/icon_burger_gray.svg?react'
import IconNewChatGray from '@/shared/assets/icons/icon_new_chat_gray.svg?react'
import { IconButton, Typography } from '@/shared/ui'
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from '@/shared/ui/Tooltip'
import { useNavigate } from 'react-router-dom'

import { useSidebar } from '../../model/useSidebar'

interface SidebarHeaderProps {
  isFavoritesPage: boolean
  handleFavoritesToggled: () => void
}

export const SidebarHeader = ({
  isFavoritesPage,
  handleFavoritesToggled,
}: SidebarHeaderProps) => {
  const { toggleSidebar } = useSidebar()
  const navigate = useNavigate()

  return (
    <div className="flex flex-col">
      <div
        className="flex flex-none items-center justify-between rounded-t-3xl bg-[#FCFCFC]
          px-4 py-3 backdrop-blur-4px"
      >
        <Tooltip>
          <TooltipTrigger asChild>
            <IconButton
              className="rounded-full"
              onClick={toggleSidebar}
            >
              <IconBurgerGray width={24} height={24} />
            </IconButton>
          </TooltipTrigger>
          <TooltipContent side="top" className="h-7 px-2 py-1">
            Скрыть меню
          </TooltipContent>
        </Tooltip>
        <Tooltip>
          <TooltipTrigger asChild>
            <IconButton
              onClick={() => navigate('/')}
              className="rounded-full"
            >
              <IconNewChatGray width={24} height={24} />
            </IconButton>
          </TooltipTrigger>
          <TooltipContent side="top" className="h-7 px-2 py-1">
            Новый диалог
          </TooltipContent>
        </Tooltip>
        <Tooltip>
          <TooltipTrigger asChild>
            <IconButton
              onClick={() => {
                handleFavoritesToggled()
              }}
            >
              <IconBookmarkGray
                width={24}
                height={24}
                fill={isFavoritesPage ? '#4D8EFF' : 'none'}
                className={
                  isFavoritesPage
                    ? '[&>path]:stroke-[#4D8EFF]'
                    : '[&>path]:stroke-[#67707E]'
                }
              />
            </IconButton>
          </TooltipTrigger>
          <TooltipContent side="top" className="h-7 px-2 py-1">
            Избранное
          </TooltipContent>
        </Tooltip>
      </div>
      <div className="border-b border-base-100 bg-[#FCFCFC] px-6 py-[6px] backdrop-blur-4px">
        <Typography
          variant={'label'}
          className="font-semibold text-text-light-black-5"
        >
          Все диалоги
        </Typography>
      </div>
    </div>
  )
}
