import { DocumentInfoContent } from '@/entities/document/ui/DocumentInfoContent'
import { useGetDocumentInfoQuery } from '@/shared/api/documents'
import IconClose from '@/shared/assets/icons/icon_close.svg?react'
import useMediaQuery from '@/shared/hooks/useMediaQuery'
import { useAppDispatch, useTypedSelector } from '@/shared/store'
import { clearShowDocumentInfo } from '@/shared/store/slices/documents/documentsSlice'
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
} from '@/shared/ui/Dialog'
import {
  Drawer,
  DrawerClose,
  DrawerContent,
  DrawerDescription,
  DrawerHeader,
  DrawerTitle,
  DrawerTrigger,
} from '@/shared/ui/Drawer'
import { Skeleton } from '@/shared/ui/Skeleton'
import { Typography } from '@/shared/ui/Typography'

const DocumentInfoSkeleton = () => {
  return (
    <DialogDescription className="flex flex-col gap-4 p-6">
      <Skeleton className="h-4 w-3/4" />
      <Skeleton className="h-4 w-1/2" />
      <Skeleton className="h-4 w-full" />
      <Skeleton className="h-4 w-full" />
      <Skeleton className="h-4 w-full" />
    </DialogDescription>
  )
}

export const DocumentInfoDialog = () => {
  const isMobile = useMediaQuery('(max-width: 960px)')
  const dispatch = useAppDispatch()
  const showDocumentInfo = useTypedSelector(
    (state) => state.documents.showDocumentInfo
  )

  const handleClose = () => {
    dispatch(clearShowDocumentInfo())
  }

  // Run the query if showDocument is truthy (non-empty string)
  const { data, isLoading, isFetching, isError } =
    useGetDocumentInfoQuery(
      { id: Number(showDocumentInfo) },
      { skip: !showDocumentInfo }
    )

  const documentInfo = data?.info

  if (isMobile) {
    return (
      <Drawer
        open={!!showDocumentInfo}
        onClose={handleClose}
        direction="bottom"
      >
        <DrawerContent className="z-[70] h-[100svh] overflow-y-hidden">
          <DrawerClose className="absolute right-3 top-3">
            <IconClose className="h-6 w-6" />
          </DrawerClose>
          <DrawerHeader className="justify-start border-b border-b-stroke-light-gray-1 p-0">
            <DrawerTitle className="h-[50px]">
              <Typography variant={'heading4'}>
                {isLoading || isFetching
                  ? 'Загрузка информации о документе...'
                  : isError
                    ? 'Ошибка при загрузке информации о документе'
                    : 'О документе'}
              </Typography>
            </DrawerTitle>
          </DrawerHeader>
          {isLoading || isFetching ? (
            <DocumentInfoSkeleton />
          ) : isError ? (
            <DrawerDescription className="p-6">
              <Typography className="p-6">
                Попробуйте обновить страницу или попробуйте позже.
              </Typography>
            </DrawerDescription>
          ) : documentInfo ? (
            <DrawerDescription>
              <DocumentInfoContent documentInfo={documentInfo} />
            </DrawerDescription>
          ) : null}
        </DrawerContent>
      </Drawer>
    )
  }

  return (
    <Dialog open={!!showDocumentInfo}>
      <DialogContent
        onClose={handleClose}
        className="z-[1000] w-full max-w-[calc(100svw-24px)] gap-8 overflow-hidden
          rounded-3xl bg-white p-4 sm:p-6 lg:max-w-[934px]"
      >
        <DialogHeader>
          <DialogTitle className="text-start md:text-center">
            <Typography
              variant="heading5"
              className="text-lg md:text-[24px]"
            >
              {isLoading || isFetching
                ? 'Загрузка информации о документе...'
                : isError
                  ? 'Ошибка при загрузке информации о документе'
                  : 'Информация о документе'}
            </Typography>
          </DialogTitle>
        </DialogHeader>

        {isLoading || isFetching ? (
          <DocumentInfoSkeleton />
        ) : isError ? (
          <DialogDescription className="p-6">
            <Typography className="p-6">
              Попробуйте обновить страницу или попробуйте позже.
            </Typography>
          </DialogDescription>
        ) : documentInfo ? (
          <DialogDescription>
            <DocumentInfoContent documentInfo={documentInfo} />
          </DialogDescription>
        ) : null}
      </DialogContent>
    </Dialog>
  )
}
