import { combineReducers, configureStore } from '@reduxjs/toolkit'
import {
  TypedUseSelectorHook,
  useDispatch,
  useSelector,
} from 'react-redux'

import { broadcastAuthApi } from '../api/broadcast'
import { chatApi } from '../api/chat'
import { documentsApi } from '../api/documents'
import { signinPersonalApi } from '../api/signinPersonal'
import { signupCompanyApi } from '../api/signupCompany'
import { signupPersonalApi } from '../api/signupPersonal'
import { userApi } from '../api/user'
import { apiMiddleware } from './middleware/apiMiddleware.tsx'
import { errorLogger } from './middleware/errorLogger.tsx'
import signinCompanyReducer from './slices/auth/signinCompanySlice'
import signinPersonalReducer from './slices/auth/signinPersonalSlice'
import signupCompanyReducer from './slices/auth/signupCompanySlice'
import signupPersonalReducer from './slices/auth/signupPersonalSlice'
import userReducer from './slices/auth/userSlice'
import chatReducer from './slices/chat/chatSlice'
import documentPreviewReducer from './slices/documents/documentPreviewSlice.ts'
import documentsReducer from './slices/documents/documentsSlice'
import DocumentMetaReducer from './slices/meta/documentMeta'

const rootReducer = combineReducers({
  signupCompany: signupCompanyReducer,
  signupPersonal: signupPersonalReducer,
  signinPersonal: signinPersonalReducer,
  signinCompany: signinCompanyReducer,
  user: userReducer,
  chats: chatReducer,
  documents: documentsReducer,
  documentMeta: DocumentMetaReducer,
  documentPreview: documentPreviewReducer,
  [signinPersonalApi.reducerPath]: signinPersonalApi.reducer,
  [signupPersonalApi.reducerPath]: signupPersonalApi.reducer,
  [signupCompanyApi.reducerPath]: signupCompanyApi.reducer,
  [broadcastAuthApi.reducerPath]: broadcastAuthApi.reducer,
  [chatApi.reducerPath]: chatApi.reducer,
  [userApi.reducerPath]: userApi.reducer,
  [documentsApi.reducerPath]: documentsApi.reducer,
})

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(
      documentsApi.middleware,
      signinPersonalApi.middleware,
      signupPersonalApi.middleware,
      signupCompanyApi.middleware,
      broadcastAuthApi.middleware,
      chatApi.middleware,
      userApi.middleware,
      errorLogger,
      apiMiddleware
    ),
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch

export const useAppDispatch = () => useDispatch<AppDispatch>()
export const useTypedSelector: TypedUseSelectorHook<RootState> =
  useSelector

export default store
