import { Typography } from '@/shared/ui/Typography'
import { FC, ReactNode } from 'react'

interface LoadCoverProps {
  isLoad?: boolean
  skeleton?: ReactNode

  isError?: boolean
  errorComponent?: ReactNode

  children?: ReactNode
}

export const LoadCover: FC<LoadCoverProps> = ({
  isLoad,
  skeleton,

  isError,
  errorComponent,

  children,
}) => {
  if (isLoad === undefined) {
    return null
  }

  if (isLoad) {
    return skeleton
  }

  if (isError) {
    return errorComponent ? (
      errorComponent
    ) : (
      <Typography>Error...</Typography>
    )
  }

  return children
}
